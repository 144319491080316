import React, { useState } from 'react';
import CardItem from '../components/cards/CardItem';
import HomeLayout from '../components/Layout/Home_layout';

const cardData = [
    {
      image: 'assets/images/box-item/card-item-35.jpg',
      title: 'Dayco serpentine belt',
      authorName: 'Kristin Watson',
      avatar: 'assets/images/avatar/avatar-box-01.jpg',
      currentBid: '0.34',
      category: 'Art',
    },
    {
      image: 'assets/images/box-item/card-item-36.jpg',
      title: 'Another Item',
      authorName: 'Cody Fisher',
      avatar: 'assets/images/avatar/avatar-box-02.jpg',
      currentBid: '0.5',
      category: 'Photography',
    },
    {
      image: 'assets/images/box-item/card-item-36.jpg',
      title: 'Another Item',
      authorName: 'Cody Fisher',
      avatar: 'assets/images/avatar/avatar-box-02.jpg',
      currentBid: '0.5',
      category: 'Photography',
    },
    {
      image: 'assets/images/box-item/card-item-36.jpg',
      title: 'Another Item',
      authorName: 'Cody Fisher',
      avatar: 'assets/images/avatar/avatar-box-02.jpg',
      currentBid: '0.5',
      category: 'Photography',
    },
    {
      image: 'assets/images/box-item/card-item-36.jpg',
      title: 'Another Item',
      authorName: 'Cody Fisher',
      avatar: 'assets/images/avatar/avatar-box-02.jpg',
      currentBid: '0.5',
      category: 'Photography',
    },
    {
      image: 'assets/images/box-item/card-item-35.jpg',
      title: 'Dayco serpentine belt',
      authorName: 'Kristin Watson',
      avatar: 'assets/images/avatar/avatar-box-01.jpg',
      currentBid: '0.34',
      category: 'Art',
    },
    {
      image: 'assets/images/box-item/card-item-36.jpg',
      title: 'Another Item',
      authorName: 'Cody Fisher',
      avatar: 'assets/images/avatar/avatar-box-02.jpg',
      currentBid: '0.5',
      category: 'Photography',
    },
    {
      image: 'assets/images/box-item/card-item-36.jpg',
      title: 'Another Item',
      authorName: 'Cody Fisher',
      avatar: 'assets/images/avatar/avatar-box-02.jpg',
      currentBid: '0.5',
      category: 'Photography',
    },
    {
      image: 'assets/images/box-item/card-item-36.jpg',
      title: 'Another Item',
      authorName: 'Cody Fisher',
      avatar: 'assets/images/avatar/avatar-box-02.jpg',
      currentBid: '0.5',
      category: 'Photography',
    },
    {
      image: 'assets/images/box-item/card-item-36.jpg',
      title: 'Another Item',
      authorName: 'Cody Fisher',
      avatar: 'assets/images/avatar/avatar-box-02.jpg',
      currentBid: '0.5',
      category: 'Photography',
    },
    {
      image: 'assets/images/box-item/card-item-35.jpg',
      title: 'Dayco serpentine belt',
      authorName: 'Kristin Watson',
      avatar: 'assets/images/avatar/avatar-box-01.jpg',
      currentBid: '0.34',
      category: 'Art',
    },
    {
      image: 'assets/images/box-item/card-item-36.jpg',
      title: 'Another Item',
      authorName: 'Cody Fisher',
      avatar: 'assets/images/avatar/avatar-box-02.jpg',
      currentBid: '0.5',
      category: 'Photography',
    },
    {
      image: 'assets/images/box-item/card-item-36.jpg',
      title: 'Another Item',
      authorName: 'Cody Fisher',
      avatar: 'assets/images/avatar/avatar-box-02.jpg',
      currentBid: '0.5',
      category: 'Photography',
    },
    {
      image: 'assets/images/box-item/card-item-36.jpg',
      title: 'Another Item',
      authorName: 'Cody Fisher',
      avatar: 'assets/images/avatar/avatar-box-02.jpg',
      currentBid: '0.5',
      category: 'Photography',
    },
    {
      image: 'assets/images/box-item/card-item-36.jpg',
      title: 'Another Item',
      authorName: 'Cody Fisher',
      avatar: 'assets/images/avatar/avatar-box-02.jpg',
      currentBid: '0.5',
      category: 'Photography',
    },
    {
      image: 'assets/images/box-item/card-item-35.jpg',
      title: 'Dayco serpentine belt',
      authorName: 'Kristin Watson',
      avatar: 'assets/images/avatar/avatar-box-01.jpg',
      currentBid: '0.34',
      category: 'Art',
    },
    {
      image: 'assets/images/box-item/card-item-36.jpg',
      title: 'Another Item',
      authorName: 'Cody Fisher',
      avatar: 'assets/images/avatar/avatar-box-02.jpg',
      currentBid: '0.5',
      category: 'Photography',
    },
    {
      image: 'assets/images/box-item/card-item-36.jpg',
      title: 'Another Item',
      authorName: 'Cody Fisher',
      avatar: 'assets/images/avatar/avatar-box-02.jpg',
      currentBid: '0.5',
      category: 'Photography',
    },
    {
      image: 'assets/images/box-item/card-item-36.jpg',
      title: 'Another Item',
      authorName: 'Cody Fisher',
      avatar: 'assets/images/avatar/avatar-box-02.jpg',
      currentBid: '0.5',
      category: 'Photography',
    },
    {
      image: 'assets/images/box-item/card-item-36.jpg',
      title: 'Another Item',
      authorName: 'Cody Fisher',
      avatar: 'assets/images/avatar/avatar-box-02.jpg',
      currentBid: '0.5',
      category: 'Photography',
    },
    
    // More card data here...
  ];

const ExplorePage = () => {
  const [visibleCards, setVisibleCards] = useState(12); // Initially show 12 cards
  const [selectedCategory, setSelectedCategory] = useState('All');
  const [isDropdownOpen, setIsDropdownOpen] = useState(false); // Dropdown state

  // Filter cards by selected category
  const filteredCards = cardData.filter((card) =>
    selectedCategory === 'All' ? true : card.category === selectedCategory
  );

  // Handle category change and close dropdown
  const handleCategoryChange = (category) => {
    setSelectedCategory(category);
    setVisibleCards(12); // Reset visible cards when category changes
    setIsDropdownOpen(false); // Close the dropdown
  };

  // Load more cards when "Load More" is clicked
  const loadMoreCards = () => {
    setVisibleCards((prevVisibleCards) => prevVisibleCards + 12);
  };

  return (
    <HomeLayout>
      <div className="explore-page">
        {/* Title Section */}
        <div className="flat-title-page">
          <div className="themesflat-container">
            <div className="row">
              <div className="col-12">
                <h1 className="heading text-center">Explore Product</h1>
                <ul className="breadcrumbs flex justify-center">
                  <li className='icon-keyboard_arrow_right'>
                    <a href="/user/Home">Home</a>
                  </li>
                  <li>
                    <a href="#">Explore</a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>

        {/* Filter Section */}
        <div className="tf-section-2 discover-item">
          <div className="themesflat-container">
            <div className="row">
              <div className="col-md-12 pb-30">
                <div className="tf-soft flex items-center justify-between">
                  <div className="soft-left">
                    {/* Category Filter */}
                    <div className={`dropdown ${isDropdownOpen ? 'show' : ''}`}>
                      <button
                        className="btn btn-secondary dropdown-toggle"
                        onClick={() => setIsDropdownOpen(!isDropdownOpen)}
                      >
                        <span className="inner">Category</span>
                      </button>
                      <div className={`dropdown-menu ${isDropdownOpen ? 'show' : ''}`}>
                        <a className="dropdown-item" onClick={() => handleCategoryChange('All')}>
                          All
                        </a>
                        <a className="dropdown-item" onClick={() => handleCategoryChange('Art')}>
                          Art
                        </a>
                        <a className="dropdown-item" onClick={() => handleCategoryChange('Photography')}>
                          Photography
                        </a>
                        <a className="dropdown-item" onClick={() => handleCategoryChange('Music')}>
                          Music
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/* Cards Section */}
              <div className="row">
                {filteredCards.slice(0, visibleCards).map((card, index) => (
                  <div key={index} className="col-xl-3 col-lg-4 col-md-6 col-sm-6">
                    <CardItem
                      image={card.image}
                      title={card.title}
                      authorName={card.authorName}
                      avatar={card.avatar}
                      currentBid={card.currentBid}
                    />
                  </div>
                ))}
              </div>

              {/* Load More Button */}
              {visibleCards < filteredCards.length && (
                <div className="col-md-12 load-more">
                  <button onClick={loadMoreCards} className="tf-button-loadmore">
                    Load More
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </HomeLayout>
  );
};

export default ExplorePage;

import React, { useEffect, useState } from 'react';
import DataTable from 'react-data-table-component';
import axios from 'axios'; // Ensure axios is installed
import { useUser } from '../../../context/UserContext'; // Assuming you have a UserContext to get the user info
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'; // Import FontAwesomeIcon component
import { faEdit } from '@fortawesome/free-solid-svg-icons'; // Import the edit icon
import Swal from 'sweetalert2'; // Import Swal for alerts

const NFTList = () => {
  const [nfts, setNfts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [selectedNFT, setSelectedNFT] = useState(null);
  const { user } = useUser(); // Assuming the user object has the userId

  useEffect(() => {
    const fetchNFTs = async () => {
      try {
        // if (!user || !user.id) {
        //   console.error('User ID is missing');
        //   setLoading(false);
        //   return;
        // }

        const token = localStorage.getItem('token'); // Retrieve token for authorization
        const response = await axios.get('https://www.blueblocksol.com:3000/api/user-nfts', {
          headers: { Authorization: `Bearer ${token}` },
        });
        setNfts(response.data); // Store the fetched NFTs in the state
      } catch (error) {
        console.error('Error fetching NFTs:', error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchNFTs();
  }, [user]); // Re-fetch when user changes

  const handleEditClick = (nft) => {
    setSelectedNFT(nft); // Set the selected NFT to be edited
    setShowModal(true); // Show the modal
  };

  const handleModalClose = () => {
    setShowModal(false); // Close the modal
    setSelectedNFT(null); // Clear the selected NFT
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setSelectedNFT((prev) => ({
      ...prev,
      [name]: value, // Update the specific field in the selected NFT
    }));
  };

  const handleSaveChanges = async () => {
    try {
      const token = localStorage.getItem('token'); // Retrieve token for authorization
      await axios.put(`https://www.blueblocksol.com:3000/api/nfts-update/${selectedNFT.nft_id}`, selectedNFT, {
        headers: { Authorization: `Bearer ${token}` },
      });

      // Update the state to reflect changes
      setNfts((prevNFTs) =>
        prevNFTs.map((nft) => (nft.nft_id === selectedNFT.nft_id ? selectedNFT : nft))
      );

      setShowModal(false); // Close the modal after saving changes

      // Show success message
      Swal.fire({
        title: 'Success!',
        text: 'NFT details updated successfully.',
        icon: 'success',
        timer: 2000,
        showConfirmButton: false,
      });
    } catch (error) {
      console.error('Error updating NFT:', error.message);

      // Show error message
      Swal.fire({
        title: 'Error!',
        text: 'Failed to update NFT details. Please try again.',
        icon: 'error',
        timer: 2000,
        showConfirmButton: false,
      });
    }
  };

  // DataTable columns configuration
  const columns = [
    {
      name: 'NFT ID',
      selector: (row) => row.nft_id,
      sortable: true,
    },
    {
      name: 'Owner ID',
      selector: (row) => row.owner_id,
      sortable: true,
    },
    {
      name: 'Name',
      selector: (row) => row.name,
      sortable: true,
    },
    {
      name: 'Description',
      selector: (row) => row.description,
      sortable: true,
    },
    {
      name: 'Price',
      selector: (row) => `$${parseFloat(row.price).toFixed(2) || '0.00'}`,
      sortable: true,
    },
    {
      name: 'Created At',
      selector: (row) => new Date(row.created_at).toLocaleString(),
      sortable: true,
    },
    {
      name: 'Sale Type',
      selector: (row) => row.sale_type,
      sortable: true,
    },
    {
      name: 'Views',
      selector: (row) => row.views,
      sortable: true,
    },
    {
      name: 'Actions',
      cell: (row) => (
        <FontAwesomeIcon
          icon={faEdit}
          onClick={() => handleEditClick(row)}
          style={{ cursor: 'pointer', color: '#007bff' }} // Styling for the icon
        />
      ),
    },
  ];

  return (
    <div className="table--responsive--md mt-2">
      <DataTable
        columns={columns}
        data={nfts}
        customStyles={{
          header: {
            style: {
              backgroundColor: '#000', // Black background for header
              color: 'white', // White text color for header
            },
          },
          rows: {
            style: {
              backgroundColor: '#fff', // White background for rows
              color: '#000', // Black text color for rows
              '&:not(:last-of-type)': {
                borderBottomStyle: 'solid',
                borderBottomWidth: '1px',
                borderBottomColor: '#ddd', // Light gray for row borders
              },
            },
            highlightOnHoverStyle: {
              backgroundColor: '#f2f2f2', // Light gray background on hover
              color: '#000', // Black text on hover
            },
            hoverStyle: {
              backgroundColor: '#f2f2f2', // Light gray background on hover
              color: '#000', // Black text on hover
            },
          },
          headCells: {
            style: {
              backgroundColor: '#000', // Black background for header cells
              color: 'white', // White text color for header cells
              fontWeight: 'bold',
            },
          },
          cells: {
            style: {
              color: '#000', // Black text color for cells
            },
          },
        }}
        pagination
        progressPending={loading}
        noHeader
      />

      {/* Modal for editing NFT */}
      {showModal && selectedNFT && (
        <div className="modal" style={{ 
          display: 'flex', 
          justifyContent: 'center', 
          alignItems: 'center', 
          position: 'fixed', 
          top: 0, 
          left: 0, 
          width: '100%', 
          height: '100%', 
          backgroundColor: 'rgba(0, 0, 0, 0.8)' 
        }}>
          <div className="modal-content" style={{ 
            padding: '20px', 
            backgroundColor: 'rgba(255, 255, 255, 0.9)', // Semi-transparent white background
            borderRadius: '8px', 
            width: '500px', // Increased width
            boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
            maxHeight: '80vh', 
            overflowY: 'auto' 
          }}>
            <h3 style={{ marginBottom: '10px' }}>Edit NFT</h3>
            <div style={{ marginBottom: '10px' }}>
              <label style={{ display: 'block', marginBottom: '5px' }}>Name</label>
              <input
                type="text"
                name="name"
                value={selectedNFT.name}
                onChange={handleInputChange}
                style={{ width: '100%', padding: '8px' }}
              />
            </div>
            <div style={{ marginBottom: '10px' }}>
              <label style={{ display: 'block', marginBottom: '5px' }}>Description</label>
              <textarea
                name="description"
                value={selectedNFT.description}
                onChange={handleInputChange}
                style={{ width: '100%', height: '100px', padding: '8px' }} // Reduced height for textarea
              />
            </div>
            <div style={{ marginBottom: '10px' }}>
              <label style={{ display: 'block', marginBottom: '5px' }}>Price</label>
              <input
                type="number"
                name="price"
                value={selectedNFT.price}
                onChange={handleInputChange}
                style={{ width: '100%', padding: '8px' }}
              />
            </div>
            <div style={{ marginBottom: '10px' }}>
              <label style={{ display: 'block', marginBottom: '5px' }}>Sale Type</label>
              <select
                name="sale_type"
                value={selectedNFT.sale_type}
                onChange={handleInputChange}
                style={{ width: '100%', padding: '8px' }}
              >
                <option value="sale">Sale</option>
                <option value="auction">Auction</option>
              </select>
            </div>
            {/* Buttons Container */}
            <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '10px' }}>
              <button
                onClick={handleSaveChanges}
                style={{
                  width: '100px', // Reduced width
                  padding: '8px',
                  backgroundColor: '#28a745',
                  color: 'white',
                  border: 'none',
                  borderRadius: '4px',
                  cursor: 'pointer',
                  marginRight: '10px',
                }}
              >
                Save
              </button>
              <button
                onClick={handleModalClose}
                style={{
                  width: '100px', // Reduced width
                  padding: '8px',
                  backgroundColor: '#6c757d',
                  color: 'white',
                  border: 'none',
                  borderRadius: '4px',
                  cursor: 'pointer',
                }}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default NFTList;

import React from 'react';
import Home_header from '../header/Home_header'; // Assuming you have a Header component
import Footer from '../Footer/Footer'; // Assuming you have a Footer component
import CustomCursor from '../CustomCursor';

const HomeLayout = ({ children }) => { // Accept children as a prop
  return (
    <div id="wrapper">
      {/* Header */}


      {/* Main Page Content */}
      <div id="page" className="pt-40">
      <Home_header />
          {children} {/* Render the children here */}
                {/* Footer */}
      <Footer />
      <CustomCursor />
      </div>


    </div>
  );
};

export default HomeLayout;

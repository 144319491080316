import React, { useState } from "react";
import Cards from "./cards";

const CardList = ({ cards }) => {
  // State for category and visible cards
  const [visibleCards, setVisibleCards] = useState(20);
  const [selectedCategory, setSelectedCategory] = useState("All");
  const [sortOption, setSortOption] = useState("recentlyAdded");
  const [isDropdownOpen, setIsDropdownOpen] = useState(false); // State to manage dropdown visibility

  // Function to load more cards
  const loadMoreCards = () => {
    setVisibleCards((prevVisibleCards) => prevVisibleCards + 20);
  };

  // Function to handle category selection
  const handleCategoryChange = (category) => {
    setSelectedCategory(category);
    setVisibleCards(20); // Reset visible cards when category changes
  };

  // Function to handle sorting
  const handleSortChange = (option) => {
    setSortOption(option);
    setIsDropdownOpen(false); // Close dropdown after sorting option is selected
  };

  // Toggle dropdown open/close
  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  // Filter cards based on selected category
  const filteredCards = cards.filter((card) =>
    selectedCategory === "All" ? true : card.category === selectedCategory
  );

  // Sort cards based on the selected sort option
  const sortedCards = [...filteredCards].sort((a, b) => {
    if (sortOption === "priceLowHigh") {
      return parseFloat(a.bid) - parseFloat(b.bid);
    } else if (sortOption === "priceHighLow") {
      return parseFloat(b.bid) - parseFloat(a.bid);
    } else if (sortOption === "recentlyAdded") {
      return 0; // Keep default ordering for recently added (assuming no explicit sorting logic)
    }
    return 0;
  });

  return (
      <div className="row">
        <div className="col-md-12">
       
            <div className="wrap-box flex">
              <div className="inner-content">
                {/* Category Filter */}
                <div className="category-filter">
                  <ul className="widget-menu-tab">
                    <li
                      className={`item-title ${
                        selectedCategory === "All" ? "active" : ""
                      }`}
                      onClick={() => handleCategoryChange("All")}
                    >
                      <span className="inner">All</span>
                    </li>
                    <li
                      className={`item-title ${
                        selectedCategory === "Art" ? "active" : ""
                      }`}
                      onClick={() => handleCategoryChange("Art")}
                    >
                      <span className="inner">Art</span>
                    </li>
                    <li
                      className={`item-title ${
                        selectedCategory === "Gaming" ? "active" : ""
                      }`}
                      onClick={() => handleCategoryChange("Gaming")}
                    >
                      <span className="inner">Gaming</span>
                    </li>
                    <div className="tf-soft">
                  <div className="soft-right">
                    <div className={`dropdown ${isDropdownOpen ? "show" : ""}`}>
                      <button
                        className="btn btn-secondary dropdown-toggle"
                        type="button"
                        id="dropdownMenuButton4"
                        aria-haspopup="true"
                        aria-expanded={isDropdownOpen}
                        onClick={toggleDropdown} // Toggle dropdown visibility
                      >
                        <svg
                          width="20"
                          height="20"
                          viewBox="0 0 20 20"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M3.125 5.625H16.875M3.125 10H16.875M3.125 14.375H10"
                            stroke="white"
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          ></path>
                        </svg>
                        <span>
                          Sort by:{" "}
                          {sortOption === "recentlyAdded"
                            ? "Recently added"
                            : sortOption === "priceLowHigh"
                            ? "Price: Low to High"
                            : "Price: High to Low"}
                        </span>
                      </button>
                      <div
                        className={`dropdown-menu ${isDropdownOpen ? "show" : ""}`}
                        aria-labelledby="dropdownMenuButton4"
                      >
                        <h6>Sort by</h6>
                        <a
                          href="#"
                          className="dropdown-item"
                          onClick={() => handleSortChange("recentlyAdded")}
                        >
                          <div className="sort-filter">
                            <span>Recently added</span>
                          </div>
                        </a>
                        <a
                          href="#"
                          className="dropdown-item"
                          onClick={() => handleSortChange("priceLowHigh")}
                        >
                          <div className="sort-filter">
                            <span>Price: Low to High</span>
                          </div>
                        </a>
                        <a
                          href="#"
                          className="dropdown-item"
                          onClick={() => handleSortChange("priceHighLow")}
                        >
                          <div className="sort-filter">
                            <span>Price: High to Low</span>
                          </div>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                  </ul>
                </div>

                {/* Sorting Dropdown */}
             

                {/* Card Display */}
                <div className="widget-content-inner">
                  <div className="wrap-box-card">
                    {sortedCards.slice(0, visibleCards).map((card, index) => (
                      <Cards
                        key={index}
                        image={card.image}
                        title={card.title}
                        authorName={card.authorName}
                        avatar={card.avatar}
                        bid={card.bid}
                      />
                    ))}
                  </div>
                  {visibleCards < sortedCards.length && (
                    <div className="load-more">
                      <button onClick={loadMoreCards} className="tf-button">
                        Load More
                      </button>
                    </div>
                  )}
                </div>
              </div>
        
          </div>
        </div>
      </div>
  );
};

export default CardList;

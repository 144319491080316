import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate, useLocation } from 'react-router-dom';
import AdminHome from './admin/pages/HomePage';
import Deposit from './admin/pages/Deposit';
import Games from './admin/pages/Games';
import Users from './admin/pages/Users';
import Transactions from './admin/pages/Transactions';
import GameZone from './admin/pages/GameZone';
import Login from './user/pages/Login';
import GameActivities from './admin/pages/GameActivities';
import NFT from './admin/pages/NFT';
import CryptoRequests from './admin/pages/CryptoRequests';
import Registration from './user/pages/Registration';
import UserDashboard from './user/pages/Dashboard';
import { DarkModeProvider } from './admin/DarkModeContext';
import User_Home from './user/pages/User_Home';
import ExplorePage from './user/pages/ExplorePage';
import ExploreGaming from './user/pages/ExploreGaming';
import PlayGame from './user/pages/PlayGame';
import PrivateRoute from './context/PrivateRoute';
import NFTDetails from './user/components/cards/NftDetails';
import { UserProvider } from './context/UserContext';
import Content from './user/pages/Content';
import { WOW } from 'wowjs';
import 'animate.css'; // Import animate.css for animations
import AudioWaveform from './user/pages/AudioWaveform';
import MobileMenuBar from './user/components/Menubar/MobileMenuBar'; // Corrected import for MobileMenuBar

// Function to load CSS dynamically
function loadCSS(href, id) {
  return new Promise((resolve) => {
    const link = document.createElement('link');
    link.rel = 'stylesheet';
    link.href = href;
    link.id = id; // Set the id for the link
    link.onload = () => resolve(true);
    document.head.appendChild(link);
  });
}

// Function to remove dynamic CSS based on the id prefix
function removeDynamicCSS(prefix) {
  const dynamicLinks = document.querySelectorAll(`link[id^="${prefix}"]`);
  dynamicLinks.forEach((link) => link.remove());
}

// Admin CSS files
const adminCSS = [
  { href: '../admin/assets/vendor/css/rtl/core.css', id: 'dynamic-css-admin-core' },
  { href: '../admin/assets/vendor/css/rtl/theme-default.css', id: 'dynamic-css-admin-theme' },
  { href: '../admin/assets/css/demo.css', id: 'dynamic-css-admin-demo' },
  { href: '../admin/assets/vendor/libs/node-waves/node-waves.css', id: 'dynamic-css-admin-waves' },
  { href: '../admin/assets/vendor/libs/perfect-scrollbar/perfect-scrollbar.css', id: 'dynamic-css-admin-scrollbar' },
  { href: '../admin/assets/vendor/libs/typeahead-js/typeahead.css', id: 'dynamic-css-admin-typeahead' },
  { href: '../admin/assets/vendor/libs/apex-charts/apex-charts.css', id: 'dynamic-css-admin-charts' },
  { href: '../admin/assets/vendor/libs/swiper/swiper.css', id: 'dynamic-css-admin-swiper' },
  { href: '../admin/assets/vendor/libs/datatables-bs5/datatables.bootstrap5.css', id: 'dynamic-css-admin-datatables' },
  { href: '../admin/assets/vendor/libs/datatables-responsive-bs5/responsive.bootstrap5.css', id: 'dynamic-css-admin-responsive' },
  { href: '../admin/assets/vendor/libs/datatables-checkboxes-jquery/datatables.checkboxes.css', id: 'dynamic-css-admin-checkboxes' },
];

// User CSS files
const userCSS = [
  { href: '/user/assets/css/animate.min.css', id: 'dynamic-css-user-animate' },
  { href: '/user/assets/css/animation.css', id: 'dynamic-css-user-animation' },
  { href: '/user/assets/css/bootstrap.css', id: 'dynamic-css-user-bootstrap' },
  { href: '/user/assets/css/font.css', id: 'dynamic-css-user-font' },
  { href: '/user/assets/css/icon.css', id: 'dynamic-css-user-icon' },
  { href: '/user/assets/css/responsive.css', id: 'dynamic-css-user-responsive' },
  { href: '/user/assets/css/shortcodes.css', id: 'dynamic-css-user-shortcodes' },
  { href: '/user/assets/css/style.css', id: 'dynamic-css-user-style' },
  { href: '/user/assets/css/swiper-bundle.min.css', id: 'dynamic-css-user-swiper' },
];

// Function to load CSS files
const loadCSSFiles = (files) => {
  return Promise.all(files.map((file) => loadCSS(file.href, file.id)));
};

// Loader component
const Loader = () => <div className="loader"></div>;

// Admin route component
function AdminRoutes() {
  return (
    <Routes>
      <Route path="/Home" element={<PrivateRoute element={<AdminHome />} />} />
      <Route path="/Deposit" element={<PrivateRoute element={<Deposit />} />} />
      <Route path="/Games" element={<PrivateRoute element={<Games />} />} />
      <Route path="/Users" element={<PrivateRoute element={<Users />} />} />
      <Route path="/Transactions" element={<PrivateRoute element={<Transactions />} />} />
      <Route path="/GameZone" element={<PrivateRoute element={<GameZone />} />} />
      <Route path="/CryptoRequests" element={<PrivateRoute element={<CryptoRequests />} />} />
      <Route path="/GameActivities" element={<PrivateRoute element={<GameActivities />} />} />
      <Route path="/NFT" element={<PrivateRoute element={<NFT />} />} />
    </Routes>
  );
}

// User route component
function UserRoutes() {
  return (
    <Routes>
      <Route path="/Home" element={<User_Home />} />
      <Route path="/login" element={<Login />} />
      <Route path="/Content" element={<Content />} />

      <Route path="/ExploreNFT" element={<PrivateRoute element={<ExplorePage />} />} />
      <Route path="/ExploreGames" element={<PrivateRoute element={<ExploreGaming />} />} />
      <Route path="/PlayGame" element={<PrivateRoute element={<PlayGame />} />} />
      <Route path="/nft/:id" element={<PrivateRoute element={<NFTDetails />} />} />
      <Route path="/UserDashboard" element={<PrivateRoute element={<UserDashboard />} />} />
    </Routes>
  );
}

// Main App component
function App() {
  useEffect(() => {
    // Initialize WOW.js inside the useEffect hook
    const wowInstance = new WOW({
      boxClass: 'wow',       // Class for animated elements
      animateClass: 'animated', // Animation class to use
      offset: 0,             // Distance to start the animation (default is 0)
      mobile: true,          // Trigger animations on mobile devices (default is true)
      live: true,            // Act on asynchronously loaded content (default is true)
    });
    wowInstance.init(); // Initialize the instance
  }, []); // Empty dependency array ensures this runs only once after the component mounts

  const location = useLocation();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const loadStyles = async () => {
      setLoading(true);
      if (location.pathname.startsWith('/admin')) {
        removeDynamicCSS('dynamic-css-user'); // Remove user CSS
        await loadCSSFiles(adminCSS); // Load admin CSS
      } else {
        removeDynamicCSS('dynamic-css-admin'); // Remove admin CSS
        await loadCSSFiles(userCSS); // Load user CSS
      }
      setLoading(false);
    };

    loadStyles();
  }, [location]);

  // Determine if we are on a mobile device
  const isMobile = window.innerWidth <= 768;

  // Check if the current route is for the user pages
  const isUserRoute = location.pathname.startsWith('/user');

  const handleLogout = () => {
    console.log('User logged out');
    // Your logout logic here
  };

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <>
          <Routes>
            <Route path="/" element={<Navigate to="/user/Home" />} />
            <Route path="/admin/*" element={<AdminRoutes />} />
            <Route path="/register" element={<Registration />} />
            <Route path="/user/*" element={<UserRoutes />} />
          </Routes>

          {/* Mobile Menu Bar for mobile view, only on /user/* routes */}
          {isMobile && isUserRoute && <MobileMenuBar onLogout={handleLogout} />}
        </>
      )}
    </>
  );
}

// Wrapper to include DarkModeProvider and Router
function AppWrapper() {
  return (
    <DarkModeProvider>
      <Router>
        <App />
      </Router>
    </DarkModeProvider>
  );
}

export default AppWrapper;

import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, EffectCoverflow } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/effect-coverflow';

const ExploreGame = () => {
  return (
    <div className="tf-section seller">
      <div className="themesflat-container">
        <div className="row">
          <div className="col-md-12">
            <div className="heading-section pb-30">
              <h2 className="tf-title" style={{ perspective: '400px' }}>
                <div
                  style={{
                    display: 'block',
                    textAlign: 'start',
                    position: 'relative',
                    transformOrigin: '178.792px 22px',
                    transform: 'translate3d(0px, 0px, 0px)',
                    opacity: 1,
                  }}
                >
                  Browse by category
                </div>
              </h2>
              <a href="explore-3.html" className="">
                View all bids <i className="icon-arrow-right2"></i>
              </a>
            </div>
          </div>
          <div className="col-md-12">
            <Swiper
              modules={[Navigation]}
              slidesPerView={2}
              spaceBetween={30}
              navigation={{
                nextEl: '.seller-next',
                prevEl: '.seller-prev',
              }}
              breakpoints={{
                500: {
                  slidesPerView: 3,
                },
                640: {
                  slidesPerView: 4,
                },
                768: {
                  slidesPerView: 5,
                },
                1070: {
                  slidesPerView: 6,
                },
              }}
              className="seller seller-slider2"
            >
              {/* Each category item wrapped in SwiperSlide */}
              {['imgslider1category', 'imgslider2category', 'imgslider3category', 'imgslider4category', 'imgslider5category', 'imgslider6category','imgslider1category', 'imgslider2category', 'imgslider3category', 'imgslider4category', 'imgslider5category', 'imgslider6category'].map((image, index) => (
                <SwiperSlide key={index}>
                  <div className="tf-category text-center">
                    <div className="card-media">
                      <img src={`assets/images/box-item/${image}.jpg`} alt="" />
                      <a href="#">
                        <i className="ti ti-device-gamepad-2"></i>
                        </a>
                    </div>
                    <h6>Typo</h6>
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ExploreGame;

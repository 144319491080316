import React, { useState, useEffect } from 'react';
import Layout from '../components/Layout'; // Import the Layout component
import Loader from '../components/Loader'; // Import the Loader component
import DataTable from 'react-data-table-component'; // Import DataTable directly
import Swal from 'sweetalert2'; // Import Swal for alerts
import axios from 'axios'; // Import axios for API calls

const NFT = () => {
  const [loading, setLoading] = useState(true);
  const [nfts, setNfts] = useState([]); // State to store NFT data
  const [error, setError] = useState(null); // State to store any errors

  useEffect(() => {
    const fetchNFTs = async () => {
      try {
        const token = localStorage.getItem('token'); // Retrieve the token from local storage
        if (!token) {
          setError('Authorization token is missing');
          Swal.fire('Error', 'Authorization token is missing', 'error');
          setLoading(false);
          return;
        }

        // API call to fetch NFTs by range
        const response = await axios.get('https://www.blueblocksol.com:3000/api/nfts-range?start=1&end=10', {
          headers: {
            Authorization: `Bearer ${token}`, // Include the token in the Authorization header
          },
        });

        setNfts(response.data.nfts); // Store the fetched NFTs in state
      } catch (error) {
        setError(error.message);
        Swal.fire('Error', 'Failed to fetch NFTs from the server', 'error');
      } finally {
        setLoading(false); // Set loading to false whether the call is successful or an error occurs
      }
    };

    fetchNFTs(); // Fetch NFTs on component mount
  }, []);

  const columns = [
    { name: 'NFT ID', selector: (row) => row.nft_id, sortable: true },
    { name: 'Name', selector: (row) => row.name, sortable: true },
    { name: 'Price', selector: (row) => `$${parseFloat(row.price).toFixed(2)}`, sortable: true },
    { name: 'Owner', selector: (row) => row.owner, sortable: true },
    { name: 'Creation Date', selector: (row) => new Date(row.creation_date).toLocaleString('en-US', {
        dateStyle: 'medium',
        timeStyle: 'short',
      }), 
      sortable: true 
    },
  ];

  return (
    <Layout loading={loading}> {/* Pass the loading state to the Layout component */}
      {loading ? (
        <Loader /> // Display loader when loading is true
      ) : error ? (
        <div className="error-message">{error}</div> // Display error message if any
      ) : (
        <div className="card">
          <h5 className="card-header">NFT List</h5>
          <div className="card-datatable text-nowrap">
            <DataTable
              columns={columns}
              data={nfts}
              pagination
              highlightOnHover
              striped
            />
          </div>
        </div>
      )}
    </Layout>
  );
};

export default NFT;

import React from 'react';
import 'animate.css'; // Assuming you are using animate.css for the wow animation

const ProductDetails = ({ transactionHash, main_nft_id,metaurl }) => {
    const displayHash = transactionHash
    ? `${transactionHash.substring(40, 50)}...${transactionHash.substring(transactionHash.length - 4)}`
    : "0x1984...c38f";
    const displaymetaurl = metaurl
    ? `${metaurl.substring(40, 50)}...${metaurl.substring(metaurl.length - 4)}`
    : "0x1984...c38f";
// console.log(transactionHash);

  const fullLink = transactionHash
    ? `https://testnet.bscscan.com/tx/${transactionHash}`
    : '#';
  return (
    <div
      data-wow-delay="0s"
      className="wow fadeInRight product-item details animated mt-5"
      style={{ visibility: 'visible', animationDelay: '0s', animationName: 'fadeInRight' }}
    >
      <h6><i className="icon-description"></i>Details</h6>
      <i className="icon-keyboard_arrow_down"></i>
      <div className="content">
      <div className="details-item">
          <span>Contract Address</span>
          <span className="tf-color"> <a
            href='https://testnet.bscscan.com/address/0x318866a089876026df6bcd35e9adf9b95016b733'
            className="tf-color"
            target="_blank"
            rel="noopener noreferrer"
          >
           <span className="tf-color">0x31886.....6b733</span>
     
          </a> </span>
        </div>
        <div className="details-item">
          <span>transaction Address</span>
          <a
            href={transactionHash}
            className="tf-color"
            target="_blank"
            rel="noopener noreferrer"
          >
           <span className="tf-color">{displayHash}</span>
     
          </a>        </div>
        <div className="details-item">
          <span>Token ID</span>
          <span className="tf-color">{main_nft_id}</span>
        </div>
        <div className="details-item">
          <span>Token Standard</span>
          <span>ERC-721</span>
        </div>
        <div className="details-item">
          <span>Chain</span>
          <span>BSC</span>
        </div>
        {/* <div className="details-item">
          <span>Last Updated</span>
          <span>8 months ago</span>
        </div> */}
        <div className="details-item mb-0">
          <span>Meta Url</span>
          <span>  <a
            href={metaurl}
            className="tf-color"
            target="_blank"
            rel="noopener noreferrer"
          >{displaymetaurl}</a></span>
        </div>
      </div>
    </div>
  );
};

export default ProductDetails;

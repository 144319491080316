import React, { useEffect, useState } from 'react';
import DataTable from 'react-data-table-component';
import axios from 'axios';

const DepositHistory = () => {
  const [transactions, setTransactions] = useState([]);
  const [loading, setLoading] = useState(true);

  // Fetch deposit transactions data from the API
  useEffect(() => {
    const fetchDepositTransactions = async () => {
      const token = localStorage.getItem('token'); // Retrieve token from local storage
      if (!token) {
        console.error('No token found');
        return;
      }

      try {
        const response = await axios.get('https://www.blueblocksol.com:3000/users/user/deposit-transactions', {
          headers: {
            Authorization: `Bearer ${token}`, // Pass token in Authorization header
          },
        });

        // Transform data for display
        const transformedData = response.data.map((transaction) => ({
          id: transaction.transaction_id,
          type: transaction.transaction_type.charAt(0).toUpperCase() + transaction.transaction_type.slice(1), // Capitalize first letter
          amount: `$${parseFloat(transaction.amount).toFixed(2)}`,
          status: transaction.status, // Add status
          transactionHash: transaction.deposit_reference, // Rename deposit_reference to Transaction-Hash
          date: new Date(transaction.transaction_date).toLocaleString('en-US', {
            day: '2-digit',
            month: 'short',
            year: 'numeric',
            hour: '2-digit',
            minute: '2-digit',
            hour12: true,
          }),
        }));

        setTransactions(transformedData);
      } catch (error) {
        console.error('Error fetching deposit transactions:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchDepositTransactions();
  }, []);

  const columns = [
    {
      name: 'Transaction ID',
      selector: (row) => `#${row.id}`,
      sortable: true,
    },
    {
      name: 'Transaction Type',
      selector: (row) => row.type,
      sortable: true,
    },
    {
      name: 'Transaction-Hash', // New column for Transaction-Hash
      selector: (row) => (
        <a
          href={`https://testnet.bscscan.com/tx/${row.transactionHash}`}
          target="_blank"
          rel="noopener noreferrer"
          style={{ color: 'blue', textDecoration: 'underline' }} // Set color to blue and add underline
        >
          {row.transactionHash.slice(0, 10)}...
        </a>
      ),
      sortable: true,
    },
    {
      name: 'Amount',
      selector: (row) => row.amount,
      sortable: true,
    },
    {
      name: 'Status', // New column for Status
      selector: (row) => row.status,
      sortable: true,
    },
    {
      name: 'Date', // Move Date column to the end
      selector: (row) => row.date,
      sortable: true,
    },
  ];

  return (
    <div className="table--responsive--md mt-2">
      <DataTable
        columns={columns}
        data={transactions}
        customStyles={{
          header: {
            style: {
              backgroundColor: '#000', // Black background for header
              color: 'white', // White text color for header
            },
          },
          rows: {
            style: {
              backgroundColor: '#fff', // White background for rows
              color: '#000', // Black text color for rows
              '&:not(:last-of-type)': {
                borderBottomStyle: 'solid',
                borderBottomWidth: '1px',
                borderBottomColor: '#ddd', // Light gray for row borders
              },
            },
            highlightOnHoverStyle: {
              backgroundColor: '#f2f2f2', // Light gray background on hover
              color: '#000', // Black text on hover
            },
            hoverStyle: {
              backgroundColor: '#f2f2f2', // Light gray background on hover
              color: '#000', // Black text on hover
            },
          },
          headCells: {
            style: {
              backgroundColor: '#000', // Black background for header cells
              color: 'white', // White text color for header cells
              fontWeight: 'bold',
            },
          },
          cells: {
            style: {
              color: '#000', // Black text color for cells
            },
          },
        }}
        pagination
        progressPending={loading}
        noHeader
      />
    </div>
  );
};

export default DepositHistory;

import React, { useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { IconX } from '@tabler/icons-react';
import MetaMaskIcon from '../../assets/images/MetaMask.jpg';
import axios from 'axios';
import RegistrationModal from '../Dashboard/RegistrationModal';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';

const WalletModal = ({ isOpen, onClose, connectWallet }) => {
  const [showRegistrationModal, setShowRegistrationModal] = useState(false);
  const [walletAddress, setWalletAddress] = useState(''); // State to store wallet address
  const navigate = useNavigate();

  const checkWalletAddress = async (walletAddress) => {
    try {
      const response = await axios.post('https://www.blueblocksol.com:3000/users/check-wallet', { walletAddress });
      console.log('API Response:', response.data);

      if (response.data.exists) {
        const { token } = response.data;
// console.log(token);

      if (token) {
        localStorage.setItem('token', token);
      }
        connectWallet(walletAddress);
        onClose(); // Close WalletModal if wallet exists
      } else {
        Swal.fire({
          title: 'Not Registered',
          text: 'This wallet is not registered. Please register to continue.',
          icon: 'warning',
          confirmButtonText: 'OK',
        }).then(() => {
          setWalletAddress(walletAddress); // Save the wallet address
          setShowRegistrationModal(true); // Show the registration modal
          const { token } = response.data;

      if (token) {
        localStorage.setItem('token', token);
      }
        });
      }
    } catch (error) {
      console.error('Error checking wallet address:', error);
    }
  };

  const handleWalletConnect = async (walletType) => {
    if (window.ethereum) {
      try {
        const accounts = await window.ethereum.request({ method: 'eth_requestAccounts' });
        const connectedWalletAddress = accounts[0];
  
        // Check if the wallet address exists in the database
        await checkWalletAddress(connectedWalletAddress);
        
        // Optionally show a success message after connecting
        Swal.fire('Success', 'Wallet connected successfully!', 'success');
        
      } catch (error) {
        // Handle errors related to the wallet connection
        console.error('Error connecting wallet:', error);
        Swal.fire('Error', 'Failed to connect wallet. Please try again.', 'error');
      }
    } else {
      Swal.fire('Error', 'MetaMask is not installed. Please install it to connect.', 'error');
    }
  };

  if (!isOpen) return null;

  return (
    <>
      <div
        className="d-flex justify-content-center align-items-center position-fixed top-0 start-0 w-100 h-100 bg-dark bg-opacity-50"
        aria-modal="true"
        role="dialog"
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div
            className="modal-content p-0 rounded-3"
            style={{
              backgroundColor: 'transparent',
              padding: 0,
            }}
          >
            <div
              className="inner-content p-4 rounded-3"
              style={{
                backgroundColor: '#333',
                color: '#d3d3d3',
                padding: '20px',
                borderRadius: '10px',
              }}
            >
              <div className="d-flex justify-content-end">
                <IconX
                  onClick={onClose}
                  style={{
                    cursor: 'pointer',
                    fontSize: '24px',
                    color: '#d3d3d3',
                  }}
                  aria-label="Close"
                />
              </div>

              <h2 className="text-center mb-4" style={{ color: '#d3d3d3' }}>
                Connect to CryptoMart
              </h2>

              <ul className="list-unstyled">
                <li className="mb-3">
                  <div
                    className="d-flex align-items-center justify-content-center w-100 shadow-sm p-3 rounded clickable-option"
                    onClick={() => {
                      handleWalletConnect('MetaMask');
                      onClose(); // Close WalletModal when option is chosen
                    }}
                    style={{ gap: '10px', cursor: 'pointer' }}
                  >
                    <img
                      src={MetaMaskIcon}
                      alt="MetaMask"
                      style={{ width: '30px', height: '30px' }}
                    />
                    <span className="fw-semibold" style={{ color: '#d3d3d3' }}>MetaMask</span>
                  </div>
                </li>
                <li className="mb-3">
                  <div
                    className="d-flex align-items-center justify-content-center w-100 shadow-sm p-3 rounded clickable-option"
                    onClick={() => {
                      handleWalletConnect('Coinbase Wallet');
                      onClose(); // Close WalletModal when option is chosen
                    }}
                    style={{ gap: '10px', cursor: 'pointer' }}
                  >
                    <img
                      src="https://static.opensea.io/logos/walletlink-alternative.png"
                      alt="Coinbase Wallet"
                      style={{ width: '30px', height: '30px' }}
                    />
                    <span className="fw-semibold" style={{ color: '#d3d3d3' }}>Coinbase Wallet</span>
                  </div>
                </li>
                <li className="mb-3">
                  <div
                    className="d-flex align-items-center justify-content-center w-100 shadow-sm p-3 rounded clickable-option"
                    onClick={() => {
                      handleWalletConnect('WalletConnect');
                      onClose(); // Close WalletModal when option is chosen
                    }}
                    style={{ gap: '10px', cursor: 'pointer' }}
                  >
                    <img
                      src="https://static.opensea.io/logos/walletconnect-alternative.png"
                      alt="WalletConnect"
                      style={{ width: '30px', height: '30px' }}
                    />
                    <span className="fw-semibold" style={{ color: '#d3d3d3' }}>WalletConnect</span>
                  </div>
                </li>
              </ul>

              <div className="d-flex align-items-center my-4">
                <hr className="flex-grow-1" style={{ borderColor: '#d3d3d3' }} />
                <span className="mx-3 text-uppercase" style={{ color: '#d3d3d3' }}>
                  Or
                </span>
                <hr className="flex-grow-1" style={{ borderColor: '#d3d3d3' }} />
              </div>

              <div className="text-center">
                <button
                  className="btn btn-primary w-100 py-2 rounded"
                  onClick={() => navigate('/register')} // Navigate to /register
                >
                  Continue with email
                </button>
              </div>

              {/* Registration Modal */}
              <RegistrationModal
                isOpen={showRegistrationModal}
                onClose={() => setShowRegistrationModal(false)}
                walletAddress={walletAddress} // Pass the wallet address to the registration modal
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default WalletModal;

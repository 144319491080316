import React, { useState, useEffect } from 'react';
import { useUser } from '../../../context/UserContext';
import { useNavigate, useLocation, Link } from 'react-router-dom';

const Sidebar = ({ setActiveComponent, isNullClassActive, onMouseLeaveSidebar }) => {
  const { user, setUser } = useUser();
  const navigate = useNavigate();
  const location = useLocation();
  const [activeDropdown, setActiveDropdown] = useState(null);

  const toggleDropdown = (tab) => {
    setActiveDropdown(activeDropdown === tab ? null : tab);
  };

  const handleLogout = () => {
    setUser(null);
    localStorage.removeItem('token');
    navigate('/');
    console.log('User logged out successfully.');
  };

  useEffect(() => {
    const hash = location.hash.substring(1);
    if (hash) {
      setActiveComponent(hash);
      setActiveDropdown(hash);
    }
  }, [location.hash, setActiveComponent]);

  return (
    <div
      className={`section-menu-left ${isNullClassActive ? 'null' : ''}`}
      style={{ overflowY: 'auto', maxHeight: '100vh' }}
      onMouseLeave={onMouseLeaveSidebar} // Add mouse leave event to toggle sidebar
    >
      <div className="box-logo">
        <Link to="/user/Home" rel="home" className="main-logo">
          <img
            id="mobile-logo_header"
            src={`${process.env.PUBLIC_URL}/CRYPTOMART.png`}
            data-retina="assets/images/logo/logo@2x.png"
            style={{ width: 125 }}
          />
        </Link>
      </div>

      <div className="create menu-tab" style={{ marginBottom: '0px' }}>
        <a
          href="#create"
          className="tf-button style-1 type-1 tablinks"
          onClick={() => setActiveComponent('create')}
          style={{ fontSize: '14px', padding: '0px 0' }}
        >
          <span>Create</span>
          <i className="icon-create"></i>
        </a>
      </div>

      <div className="over-content">
        <div className="content">
          <h6 style={{ fontSize: '14px', marginBottom: '0' }}>Marketplace</h6>
          <ul className="menu-tab" style={{ padding: 0, marginBottom: '0px' }}>
            <li
              className={`tablinks ${activeDropdown === 'market' ? 'active' : ''}`}
              style={{ marginBottom: '0px', padding: '5px 40px' }}
            >
              <a href='/user/Home' style={{ fontSize: '14px', padding: '0px 0px' }}>Home</a>
            </li>
          </ul>
        </div>

        <div className="content mt-3">
          <h6 style={{ fontSize: '14px', marginBottom: '0px' }}>Account</h6>
          <ul className="menu-tab" style={{ padding: '0px' }}>
            {[
              { key: 'Market', label: 'Market' },
              { key: 'wallet', label: 'Wallet' },
              { key: 'Game-history', label: 'Game History' },
              { key: 'NFT-List', label: 'NFT-List' },
              { key: 'Bid-List', label: 'Bid-List' },
              { key: 'transaction', label: 'Transaction' },
              { key: 'referral', label: 'My Referral' },
              { key: 'deposit-history', label: 'Deposit History' },
              { key: 'request-metacoins', label: 'Request Metacoins' },
              { key: 'withdraw-request', label: 'Withdraw Request' },
              { key: 'update-profile', label: 'Update Profile' },
              { key: 'settings', label: 'Settings' }
            ].map(({ key, label }) => (
              <li
                key={key}
                className={`tablinks ${activeDropdown === key ? 'active' : ''}`}
                onClick={() => (key === 'settings' ? toggleDropdown('settings') : setActiveComponent(key))}
                style={{ marginBottom: '0px', padding: '5px 40px' }}
              >
                <a
                  href="#"
                  onClick={(e) => e.preventDefault()}
                  style={{ fontSize: '14px', padding: '0px 0' }}
                >
                  {label}
                </a>
              </li>
            ))}
            <li style={{ marginBottom: '0px', padding: '0px 40px' }}>
              <a
                href="#"
                onClick={(e) => { e.preventDefault(); handleLogout(); }}
                style={{ fontSize: '14px', padding: '0px 0' }}
              >
                Logout
              </a>
            </li>
          </ul>
        </div>
      </div>

      <style jsx>{`
        .section-menu-left {
          scrollbar-width: thin;
          scrollbar-color: #888 transparent;
        }

        .section-menu-left::-webkit-scrollbar {
          width: 8px;
        }

        .section-menu-left::-webkit-scrollbar-thumb {
          background-color: #888;
          border-radius: 4px;
        }

        .section-menu-left::-webkit-scrollbar-thumb:hover {
          background-color: #555;
          width: 12px;
        }
      `}</style>
    </div>
  );
};

export default Sidebar;

// Dashboard.jsx
import React from 'react';
import HomeLayout from '../components/Layout/Home_layout';
import { Bar, Line } from 'react-chartjs-2';
import 'chart.js/auto'; // Import to support automatic chart registration


const Content = () => {
  // Chart data
  const data = {
    labels: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul'], // X-axis labels
    datasets: [
      {
        label: 'Price',
        backgroundColor: '#161616', // Bar color
        data: [53, 162, 93, 131, 36, 93, 40], // Data for bars
        order: 2,
        borderRadius: 20, // Rounded corners for bars
        type: 'bar', // Define this dataset as a line

      },
      {
        label: 'Sale',
        data: [31, 114, 65, 93, 21, 73, 25], // Data for line
        borderColor: '#DDF247', // Line color
        borderWidth: 2, 
        fill: false, 
        type: 'line', // Define this dataset as a line
        order: 1,
        tension: 0.4, // Smoothing the line
        pointBackgroundColor: '#DDF247', // Point color
        pointBorderColor: '#000000', // Point border color
        pointRadius: 5, // Size of the points on the line
        zIndex: 2,
      },
    ],
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      x: {
        grid: {
          display: false, // Hide vertical grid lines
        },
        ticks: {
          color: '#ffffff', // X-axis label color
        },
      },
      y: {
        beginAtZero: true,
        ticks: {
          color: '#ffffff', // Y-axis label color
          precision: 0,
          stepSize: 50, // Custom step size for y-axis
        },
        grid: {
          color: 'rgba(255, 255, 255, 0.1)', // Grid line color
        },
      },
    },
    plugins: {
      legend: {
        display: false, // Hide legend
      },
      tooltip: {
        yAlign: 'bottom',
        backgroundColor: 'rgba(222, 232, 232, 0.20)',
        callbacks: {
          label: function (tooltipItem) {
            return tooltipItem.raw; // Show raw value in the tooltip
          },
        },
      },
    },
    elements: {
      bar: {
        borderRadius: 20, // Rounded corners for bars
      },
    },
  };
  return (
    <HomeLayout>
       <div class="tf-section-2 product-detail">
                <div class="themesflat-container">
                    <div class="row">
                        <div class="col-md-6">
                            <div data-wow-delay="0s" class="wow fadeInLeft tf-card-box style-5">
                                <div class="card-media mb-0">
                                    <a href="#">
                                        <img src="assets/images/box-item/product-01.jpg" alt=""/>
                                    </a>
                                </div>
                                <h6 class="price gem"><i class="icon-gem"></i></h6>
                                <div class="wishlist-button">10<i class="icon-heart"></i></div>
                                <div class="featured-countdown">
                                    <span class="js-countdown" data-timer="7500" data-labels="d,h,m,s"></span>
                                </div>
                            </div>
                            <div data-wow-delay="0s" class="wow fadeInLeft tf-card-box style-5">
                                <div class="card-media mb-0">
                                    <a href="#">
                                        <img src="assets/images/box-item/product-02.jpg" alt=""/>
                                    </a>
                                </div>
                                <h6 class="price gem"><i class="icon-gem"></i></h6>
                                <div class="wishlist-button">10<i class="icon-heart"></i></div>
                                <div class="featured-countdown">
                                    <span class="js-countdown" data-timer="7500" data-labels="d,h,m,s"></span>
                                </div>
                            </div>
                            <div data-wow-delay="0s" class="wow fadeInLeft tf-card-box style-5">
                                <div class="card-media mb-0">
                                    <a href="#">
                                        <img src="assets/images/box-item/product-03.jpg" alt=""/>
                                    </a>
                                </div>
                                <h6 class="price gem"><i class="icon-gem"></i></h6>
                                <div class="wishlist-button">10<i class="icon-heart"></i></div>
                                <div class="featured-countdown">
                                    <span class="js-countdown" data-timer="7500" data-labels="d,h,m,s"></span>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div data-wow-delay="0s" class="wow fadeInRight infor-product">
                                <div class="text">8SIAN Main Collection <span class="icon-tick"><span class="path1"></span><span class="path2"></span></span></div>
                                <div class="menu_card">
                                    <div class="dropdown">
                                        <div class="icon">
                                            <a href="javascript:void(0);" class="btn-link" data-bs-toggle="dropdown" aria-expanded="false">
                                                <i class="icon-link-1"></i>
                                            </a>
                                            <div class="dropdown-menu">
                                                <a class="dropdown-item" href="#"><i class="icon-link"></i>Copy link</a>
                                                <a class="dropdown-item" href="#"><i class="icon-facebook"></i>Share on facebook</a>
                                                <a class="dropdown-item mb-0" href="#"><i class="icon-twitter"></i>Share on twitter</a>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="dropdown">
                                        <div class="icon">
                                            <a href="javascript:void(0);" class="btn-link" data-bs-toggle="dropdown" aria-expanded="false">
                                                <i class="icon-content"></i>
                                            </a>
                                            <div class="dropdown-menu">
                                                <a class="dropdown-item" href="#"><i class="icon-refresh"></i>Refresh metadata</a>
                                                <a class="dropdown-item mb-0" href="#"><i class="icon-report"></i>Report</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <h2>Themesflat #0270</h2>
                                <div class="author flex items-center mb-30">
                                    <div class="avatar">
                                        <img src="assets/images/avatar/avatar-box-05.jpg" alt="Image"/>
                                    </div>
                                    <div class="info">
                                        <span>Owned by:</span>
                                        <h6><a>Marvin McKinney</a> </h6>
                                    </div>
                                </div>
                                <div class="meta mb-20">
                                    <div class="meta-item view">
                                        <i class="icon-show"></i>208 view
                                    </div>
                                    <div class="meta-item rating">
                                        <i class="icon-link-2"></i>Top #2 trending
                                    </div>
                                    <div class="meta-item favorites">
                                        <i class="icon-heart"></i>10 favorites
                                    </div>
                                </div>
                            </div>
                            <div data-wow-delay="0s" class="wow fadeInRight product-item time-sales">
                                <h6><i class="icon-clock"></i>Sale ends May 22 at 9:39</h6>
                                <div class="content">
                                    <div class="text">Current price</div>
                                    <div class="flex justify-between">
                                        <p>0,032 ETH <span>$58,11</span></p>
                                        <a href="#" data-toggle="modal" data-target="#popup_bid" class="tf-button style-1 h50 w216">Place a bid<i class="icon-arrow-up-right2"></i></a>
                                    </div>
                                </div>
                            </div>
                            <div data-wow-delay="0s" class="wow fadeInRight product-item description">
                                <h6><i class="icon-description"></i>Description</h6>
                                <i class="icon-keyboard_arrow_down"></i>
                                <div class="content">
                                    <p>8,888 NFTs of beautiful, Asian women painstakingly-crafted where even the most intricate details are steeped in historical significance. We envision 8SIAN being a global, inclusive community that brings together members who share admiration for Asian cultures. We want to represent, educate and build a lasting presence in the NFT space.</p>
                                </div>
                            </div>
                            <div data-wow-delay="0s" class="wow fadeInRight product-item history">
                                <h6><i class="icon-description"></i>Price History</h6>
                                <i class="icon-keyboard_arrow_down"></i>
                                <div class="content">
                                    <div class="chart">
                                    <Line data={data} options={options} />
                                    </div>
                                </div>
                            </div>
                            <div data-wow-delay="0s" class="wow fadeInRight product-item details">
                                <h6><i class="icon-description"></i>Details</h6>
                                <i class="icon-keyboard_arrow_down"></i>
                                <div class="content">
                                    <div class="details-item">
                                        <span>Contract Address</span>
                                        <span class="tf-color">0x1984...c38f</span>
                                    </div>
                                    <div class="details-item">
                                        <span>Token ID</span>
                                        <span class="tf-color">0270</span>
                                    </div>
                                    <div class="details-item">
                                        <span>Token Standard</span>
                                        <span class="">ERC-721</span>
                                    </div>
                                    <div class="details-item">
                                        <span>Chain</span>
                                        <span class="">Ethereum</span>
                                    </div>
                                    <div class="details-item">
                                        <span>Last Updated</span>
                                        <span class="">8 months ago</span>
                                    </div>
                                    <div class="details-item mb-0">
                                        <span>Creator Earnings</span>
                                        <span class="">8%</span>
                                    </div>
                                </div>
                            </div>
                            <div data-wow-delay="0s" class="wow fadeInRight product-item traits">
                                <h6><i class="icon-description"></i>Traits</h6>
                                <i class="icon-keyboard_arrow_down"></i>
                                <div class="content">
                                    <div class="trait-item">
                                        <p>Apparel</p>
                                        <div class="title">Bathrobe Red 1%</div>
                                        <p>Floor: 0,056 ETH</p>
                                    </div>
                                    <div class="trait-item">
                                        <p>Background</p>
                                        <div class="title">Orange 5%</div>
                                        <p>Floor: 0,056 ETH</p>
                                    </div>
                                    <div class="trait-item">
                                        <p>Earrings</p>
                                        <div class="title">None 60%</div>
                                        <p>Floor: 0,037 ETH</p>
                                    </div>
                                    <div class="trait-item">
                                        <p>Apparel</p>
                                        <div class="title">Bathrobe Red 1%</div>
                                        <p>Floor: 0,056 ETH</p>
                                    </div>
                                    <div class="trait-item">
                                        <p>Background</p>
                                        <div class="title">Orange 5%</div>
                                        <p>Floor: 0,056 ETH</p>
                                    </div>
                                    <div class="trait-item">
                                        <p>Earrings</p>
                                        <div class="title">None 60%</div>
                                        <p>Floor: 0,037 ETH</p>
                                    </div>
                                    <div class="trait-item">
                                        <p>Apparel</p>
                                        <div class="title">Bathrobe Red 1%</div>
                                        <p>Floor: 0,056 ETH</p>
                                    </div>
                                    <div class="trait-item">
                                        <p>Background</p>
                                        <div class="title">Orange 5%</div>
                                        <p>Floor: 0,056 ETH</p>
                                    </div>
                                    <div class="trait-item">
                                        <p>Earrings</p>
                                        <div class="title">None 60%</div>
                                        <p>Floor: 0,037 ETH</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div data-wow-delay="0s" class="wow fadeInUp col-12">
                            <div class="product-item offers">
                                <h6><i class="icon-description"></i>Offers</h6>
                                <i class="icon-keyboard_arrow_down"></i>
                                <div class="content">
                                    <div class="table-heading">
                                        <div class="column">Price</div>
                                        <div class="column">USD Price</div>
                                        <div class="column">Quantity</div>
                                        <div class="column">Floor Diference</div>
                                        <div class="column">Expiration</div>
                                        <div class="column">Form</div>
                                    </div>
                                    <div class="table-item">
                                        <div class="column"><h6 class="price gem"><i class="icon-gem"></i>0,0034</h6></div>
                                        <div class="column">$6,60</div>
                                        <div class="column">3</div>
                                        <div class="column">90% below</div>
                                        <div class="column">In 26 day</div>
                                        <div class="column"><span class="tf-color">273E40</span></div>
                                    </div>
                                    <div class="table-item">
                                        <div class="column"><h6 class="price gem"><i class="icon-gem"></i>0,0034</h6></div>
                                        <div class="column">$6,60</div>
                                        <div class="column">3</div>
                                        <div class="column">90% below</div>
                                        <div class="column">In 26 day</div>
                                        <div class="column"><span class="tf-color">273E40</span></div>
                                    </div>
                                    <div class="table-item">
                                        <div class="column"><h6 class="price gem"><i class="icon-gem"></i>0,0034</h6></div>
                                        <div class="column">$6,60</div>
                                        <div class="column">3</div>
                                        <div class="column">90% below</div>
                                        <div class="column">In 26 day</div>
                                        <div class="column"><span class="tf-color">273E40</span></div>
                                    </div>
                                    <div class="table-item">
                                        <div class="column"><h6 class="price gem"><i class="icon-gem"></i>0,0034</h6></div>
                                        <div class="column">$6,60</div>
                                        <div class="column">3</div>
                                        <div class="column">90% below</div>
                                        <div class="column">In 26 day</div>
                                        <div class="column"><span class="tf-color">273E40</span></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div data-wow-delay="0s" class="wow fadeInUp col-12">
                            <div class="product-item item-activity mb-0">
                                <h6><i class="icon-two-arrow rotateZ90"></i>Item activity</h6>
                                <i class="icon-keyboard_arrow_down"></i>
                                <div class="content">
                                    <div class="table-heading">
                                        <div class="column">Event</div>
                                        <div class="column">Price</div>
                                        <div class="column">Form</div>
                                        <div class="column">To</div>
                                        <div class="column">Date</div>
                                    </div>
                                    <div class="table-item">
                                        <div class="column flex items-center"><i class="icon-two-arrow"></i>Transfer</div>
                                        <div class="column">-/-</div>
                                        <div class="column"><span class="tf-color">985DE3</span></div>
                                        <div class="column"><span class="tf-color">Nosyu</span></div>
                                        <div class="column">19h ago</div>
                                    </div>
                                    <div class="table-item">
                                        <div class="column flex items-center"><i class="icon-sale"></i>Sale</div>
                                        <div class="column"><h6 class="price gem"><i class="icon-gem"></i>0,0319</h6></div>
                                        <div class="column"><span class="tf-color">985DE3</span></div>
                                        <div class="column"><span class="tf-color">Nosyu</span></div>
                                        <div class="column">19h ago</div>
                                    </div>
                                    <div class="table-item">
                                        <div class="column flex items-center"><i class="icon-two-arrow"></i>Transfer</div>
                                        <div class="column">-/-</div>
                                        <div class="column"><span class="tf-color">985DE3</span></div>
                                        <div class="column"><span class="tf-color">Nosyu</span></div>
                                        <div class="column">19h ago</div>
                                    </div>
                                    <div class="table-item">
                                        <div class="column flex items-center"><i class="icon-sale"></i>Sale</div>
                                        <div class="column"><h6 class="price gem"><i class="icon-gem"></i>0,0319</h6></div>
                                        <div class="column"><span class="tf-color">985DE3</span></div>
                                        <div class="column"><span class="tf-color">Nosyu</span></div>
                                        <div class="column">19h ago</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
</HomeLayout>
  );
};

export default Content;

import React, { createContext, useContext, useState, useEffect } from 'react';
import axios from 'axios';
import { decodeJWT } from '../utils/jwtUtils'; // Adjust the path to your utils file
import { Navigate } from 'react-router-dom';

const UserContext = createContext();

// Custom hook to use the UserContext
export const useUser = () => {
  return useContext(UserContext);
};

// Provider component
export const UserProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [balance, setBalance] = useState(0); // Balance state
  const [depositBalance, setDepositBalance] = useState(0); // Deposit balance state
  const [withdrawalBalance, setWithdrawalBalance] = useState(0); // Withdrawal balance
  const [nftsCreated, setNftsCreated] = useState(0); // NFTs created

  useEffect(() => {
    const fetchUserData = async () => {
      const token = localStorage.getItem('token');
      if (!token) {
        console.warn('No JWT token found.');
        return;
      }

      // Decode JWT to get user information
      const decodedUser = decodeJWT(token);
      if (decodedUser) {
        setUser(decodedUser);
        // console.log(decodedUser);
        
      } else {
        logout();
        return;
      }

      try {
        // Fetch transaction summary using the token
        const response = await axios.get(
          'https://www.blueblocksol.com:3000/users/user/transactions-summary',
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        const {
          total_deposit_balance,
          total_withdrawal_balance,
          current_wallet_balance,
          total_nfts_created,
        } = response.data;

        // Set the balances in the context
        setBalance(current_wallet_balance);
        setDepositBalance(total_deposit_balance);
        setWithdrawalBalance(total_withdrawal_balance);
        setNftsCreated(total_nfts_created);
      } catch (error) {
        console.error('Error fetching transaction summary:', error);
        if (error.response && (error.response.status === 401 || error.response.status === 500)) {
          logout(); // Call logout to clear token and state
        }
      }
    };

    // Call the API when the context is mounted
    fetchUserData();

    // Optionally, refresh the data every 2 seconds
    const intervalId = setInterval(fetchUserData, 2000);

    // Cleanup interval on component unmount
    return () => clearInterval(intervalId);
  }, []);

  const logout = () => {
    setUser(null);
    localStorage.removeItem('token');
    window.location.href = '/user/login'; // Redirect to the login page
  };

  return (
    <UserContext.Provider
      value={{
        user,
        balance,
        depositBalance,
        withdrawalBalance,
        setUser,
        nftsCreated,
        logout,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Swal from 'sweetalert2'; // Import SweetAlert2
import Web3 from 'web3'; // Import Web3.js

const RequestMetacoins = () => {
  // State to handle form data
  const [formData, setFormData] = useState({
    walletAddress: '',
    amount: '',
    transactionType: 'requestMetacoins', // Default to "Request Metacoins"
  });

  const [loading, setLoading] = useState(true); // State to handle loading
  const companyWalletAddress = '0x0f9A0968C3A5f8c4fe825652fF9df2232c77C293'; // Set this to your company wallet address
  const tokenContractAddress = '0xf1e018959fd8B1A1Ad6EE921Bcb6CAaF17D7233C'; // Set this to your token contract address
  const tokenAbi = [     // Token ABI for interacting with the contract
    {
        "constant": true,
        "inputs": [{ "name": "_owner", "type": "address" }],
        "name": "balanceOf",
        "outputs": [{ "name": "balance", "type": "uint256" }],
        "payable": false,
        "stateMutability": "view",
        "type": "function"
    },
    {
        "constant": false,
        "inputs": [{ "name": "_to", "type": "address" }, { "name": "_value", "type": "uint256" }],
        "name": "transfer",
        "outputs": [{ "name": "", "type": "bool" }],
        "payable": false,
        "stateMutability": "nonpayable",
        "type": "function"
    }
];

  // Fetch user details on component mount
  useEffect(() => {
    const fetchUserDetails = async () => {
      try {
        const token = localStorage.getItem('token'); // Retrieve token from local storage

        if (!token) {
          Swal.fire({
            icon: 'error',
            title: 'Authorization Error',
            text: 'Authorization token is missing.',
          });
          return;
        }

        // API call to get user details
        const response = await axios.get('https://www.blueblocksol.com:3000/users/user/details', {
          headers: {
            Authorization: `Bearer ${token}`, // Include the token in the Authorization header
          },
        });

        if (response.status === 200) {
          setFormData((prevData) => ({
            ...prevData,
            walletAddress: response.data.wallet_address, // Set wallet address from API response
          }));
          setLoading(false);
        } else {
          Swal.fire({
            icon: 'error',
            title: 'Error',
            text: 'Failed to fetch user details.',
          });
        }
      } catch (error) {
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: 'Error fetching user details: ' + (error.response?.data?.message || error.message),
        });
        console.error('Error:', error.message);
      }
    };

    fetchUserDetails();
  }, []);

  // Handle input change
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
  
    const token = localStorage.getItem('token'); // Retrieve token from local storage
  
    if (!token) {
      Swal.fire({
        icon: 'error',
        title: 'Authorization Error',
        text: 'Authorization token is missing.',
      });
      return;
    }
  
    try {
      if (formData.transactionType === 'requestMetacoins') {
        // Handle "Request Metacoins" functionality
        const response = await axios.post(
          'https://www.blueblocksol.com:3000/users/crypto-request/token',
          { amount: formData.amount },
          {
            headers: {
              Authorization: `Bearer ${token}`, // Include the token in the Authorization header
            },
          }
        );
        
        // Handle success or error response
        if (response?.status === 200 || response?.status === 201) {
          Swal.fire({
            icon: 'success',
            title: 'Request Successful',
            text: 'Crypto request created successfully!',
          });
        } else {
          Swal.fire({
            icon: 'error',
            title: 'Request Failed',
            text: 'Failed to process your request. Please try again.',
          });
        }
      } else if (formData.transactionType === 'depositMTB') {
        // Handle "Deposit MTB" functionality via MetaMask
        await depositMTB();
      }
    } catch (error) {
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'Error submitting request: ' + (error.response?.data?.message || error.message),
      });
      console.error('Error:', error.message);
    }
  };

 // Function to handle MetaMask interaction for depositing MTB
const depositMTB = async () => {
  if (typeof window.ethereum !== 'undefined') {
    const web3 = new Web3(window.ethereum);
    
    try {
      // Request MetaMask account access
      await window.ethereum.request({ method: 'eth_requestAccounts' });
      const accounts = await web3.eth.getAccounts();
      const userAddress = accounts[0];

      // Check if MetaMask is connected to the BSC Testnet
      const chainId = await web3.eth.getChainId();
      const bscTestnetChainId = 97; // BSC Testnet chain ID is 97

      if (chainId !== bscTestnetChainId) {
        // Prompt user to switch to BSC Testnet
        await window.ethereum.request({
          method: 'wallet_switchEthereumChain',
          params: [{ chainId: web3.utils.toHex(bscTestnetChainId) }],
        });
      }

      // Initialize the token contract (replace with your contract address and ABI)
      const tokenContract = new web3.eth.Contract(tokenAbi, tokenContractAddress);
      const amountInWei = web3.utils.toWei(formData.amount.toString(), 'ether');

      // Estimate gas and get gas price
      const gasPrice = await web3.eth.getGasPrice();
      const gasEstimate = await tokenContract.methods.transfer(companyWalletAddress, amountInWei).estimateGas({ from: userAddress });

      // Send the transaction via MetaMask
      const receipt = await tokenContract.methods.transfer(companyWalletAddress, amountInWei)
        .send({
          from: userAddress,
          gas: gasEstimate,
          gasPrice: gasPrice,
        });

      // If the transaction is successful, call the backend API to store the transaction details
      const token = localStorage.getItem('token'); // Retrieve the JWT token from local storage

      const apiResponse = await axios.post(
        'https://www.blueblocksol.com:3000/api/crypto/deposit',
        {
          walletAddress: userAddress,
          tokenAmount: formData.amount, // Sending the amount in original format
          receipt: receipt, // Sending the transaction receipt
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (apiResponse.status === 200) {
        Swal.fire({
          icon: 'success',
          title: 'Deposit Successful',
          text: `Successfully deposited ${formData.amount} MTB.`,
        });
      } else {
        Swal.fire({
          icon: 'error',
          title: 'Deposit Failed',
          text: 'Failed to save transaction details.',
        });
      }

    } catch (error) {
      console.error('Error during MetaMask transaction:', error);
      Swal.fire({
        icon: 'error',
        title: 'Transaction Failed',
        text: 'Error during the MetaMask transaction. Please try again.',
      });
    }
  } else {
    Swal.fire({
      icon: 'error',
      title: 'MetaMask Not Found',
      text: 'Please install MetaMask to make a deposit.',
    });
  }
};
  

  return (
    <div className="container my-5">
      <div className="row justify-content-center">
        <div className="col-xl-6">
          <div
            className="card p-4 mt-5"
            style={{
              borderRadius: '8px',
              boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)',
              backgroundColor: 'rgba(255, 255, 255, 0.5)', // More transparent background
              backdropFilter: 'blur(10px)', // Adds a blur effect to the background
            }}
          >
            <form onSubmit={handleSubmit}>
              <h3 className="mb-4" style={{ textAlign: 'left' }}>Request Metacoins or Deposit MTB</h3>
              {loading ? (
                <p>Loading...</p>
              ) : (
                <div className="row gy-3">
                  <div className="col-12">
                    <div className="form-group">
                      <label htmlFor="transactionType">Transaction Type</label>
                      <select
                        id="transactionType"
                        className="form-control"
                        name="transactionType"
                        value={formData.transactionType}
                        onChange={handleInputChange}
                        required
                      >
                        <option value="requestMetacoins">Request Metacoins</option>
                        <option value="depositMTB">Deposit MTB</option>
                      </select>
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="form-group">
                      <label htmlFor="walletAddress">Wallet Address</label>
                      <input
                        id="walletAddress"
                        type="text"
                        className="form-control"
                        placeholder="Wallet Address"
                        name="walletAddress"
                        value={formData.walletAddress}
                        onChange={handleInputChange}
                        readOnly // Make the field read-only
                        required
                      />
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="form-group">
                      <label htmlFor="amount">Amount</label>
                      <input
                        id="amount"
                        type="number"
                        className="form-control"
                        placeholder="Amount"
                        name="amount"
                        value={formData.amount}
                        onChange={handleInputChange}
                        max="1000" // Set maximum amount to 1000
                        required
                      />
                    </div>
                  </div>
                  <div className="col-12 d-flex justify-content-center">
                    <button type="submit" className="btn btn-primary mt-3">
                      Submit Request
                    </button>
                  </div>
                </div>
              )}
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RequestMetacoins;

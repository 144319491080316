import React, { useState } from 'react';
import { useUser } from '../../../../context/UserContext'; // Import the custom hook to use the UserContext
import { Link, useLocation, useNavigate } from 'react-router-dom'; // Import the useNavigate hook

const MobileSidebar = ({ isOpen, toggleSidebar }) => {
  const { setUser } = useUser(); // Access setUser function from context
  const navigate = useNavigate(); // Initialize useNavigate for redirecting
  const [isLoggedIn, setIsLoggedIn] = useState(false); // State to handle login/logout text
  const location = useLocation(); // Hook to get the current location

   // Handle logout
   const handleLogout = () => {
    setUser(null);
    localStorage.removeItem("token");
    navigate("/user/login");
    console.log("User logged out successfully.");
    setIsLoggedIn(false); // Update state to reflect logout
  };

  return (
    <div className={`mobile-nav-wrap ${isOpen ? 'active' : ''}`}>
      <div className="overlay-mobile-nav"></div>
      <div className="inner-mobile-nav">
        <Link to="/user/Home" rel="home" className="main-logo">
          <img
            id="mobile-logo_header"
            src={`${process.env.PUBLIC_URL}/CRYPTOMART.png`}
            data-retina="assets/images/logo/logo@2x.png"
            style={{width:125}}
          />
        </Link>
        <div className="mobile-nav-close" onClick={toggleSidebar}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="white"
            width="20px"
            height="20px"
            viewBox="0 0 122.878 122.88"
          >
            <g>
              <path d="M1.426,8.313c-1.901-1.901-1.901-4.984,0-6.886c1.901-1.902,4.984-1.902,6.886,0l53.127,53.127l53.127-53.127 c1.901-1.902,4.984-1.902,6.887,0c1.901,1.901,1.901,4.985,0,6.886L68.324,61.439l53.128,53.128c1.901,1.901,1.901,4.984,0,6.886 c-1.902,1.902-4.985,1.902-6.887,0L61.438,68.326L8.312,121.453c-1.901,1.902-4.984,1.902-6.886,0 c-1.901-1.901-1.901-4.984,0-6.886l53.127-53.128L1.426,8.313z"></path>
            </g>
          </svg>
        </div>
        <nav id="mobile-main-nav" className="mobile-main-nav">
          <ul id="menu-mobile-menu" className="menu">
            <li className="menu-item">
              <Link className="item-menu-mobile" to='/user/Home'>Home</Link>
              {/* <ul className="sub-menu-mobile">
                <li className="menu-item current-item"><Link to="index.html">Home 1</Link></li>
                <li className="menu-item"><Link to="home-2.html">Home 2</Link></li>
                <li className="menu-item"><Link to="home-3.html">Home 3</Link></li>
                <li className="menu-item"><Link to="home-4.html">Home 4</Link></li>
                <li className="menu-item"><Link to="home-5.html">Home 5</Link></li>
                <li className="menu-item"><Link to="home-6.html">Home 6</Link></li>
                <li className="menu-item"><Link to="home-7.html">Home 7</Link></li>
                <li className="menu-item"><Link to="slider-3d.html">Slider 3d</Link></li>
                <li className="menu-item"><Link to="slider-scroll.html">Slider Scroll</Link></li>
                <li className="menu-item"><Link to="slider-animation.html">Slider Animation</Link></li>
              </ul> */}
            </li>
            {/* <li className="menu-item">
              <Link className="item-menu-mobile" to="about-us.html">About us</Link>
            </li> */}
            <li className="menu-item">
              <Link className="item-menu-mobile" to='/user/ExploreNFT'>Explore</Link>
            </li>
            {/* <li className="menu-item menu-item-has-children-mobile">
              <a className="item-menu-mobile">Pages</Link>
              <ul className="sub-menu-mobile">
                <li className="menu-item"><Link to="market.html">Market</Link></li>
                <li className="menu-item"><Link to="market-create.html">Create</Link></li>
                <li className="menu-item"><Link to="market-active-bid.html">Active Bid</Link></li>
                <li className="menu-item"><Link to="market-explore.html">Explore</Link></li>
                <li className="menu-item"><Link to="market-collection.html">My collection</Link></li>
                <li className="menu-item"><Link to="market-favorite.html">My favorite</Link></li>
                <li className="menu-item"><Link to="market-wallet.html">Wallet</Link></li>
                <li className="menu-item"><Link to="market-history.html">History</Link></li>
                <li className="menu-item"><Link to="market.html">Settings</Link></li>
                <li className="menu-item"><Link to="author-1.html">Authors Style 1</Link></li>
                <li className="menu-item"><Link to="author-2.html">Authors Style 2</Link></li>
                <li className="menu-item"><Link to="terms-condition.html">Terms &amp; Condition</Link></li>
                <li className="menu-item"><Link to="no-result.html">No Result</Link></li>
                <li className="menu-item"><Link to="faq.html">FAQs</Link></li>
                <li className="menu-item"><Link to="coming-soon.html">Coming Soon</Link></li>
                <li className="menu-item"><Link to="maintenance.html">Maintenance</Link></li>
                <li className="menu-item"><Link to="404.html">404</Link></li>
                <li className="menu-item"><Link to="login.html">Login</Link></li>
                <li className="menu-item"><Link to="sign-up.html">Sign up</Link></li>
              </ul>
            </li> */}
            {/* <li className="menu-item menu-item-has-children-mobile">
              <a className="item-menu-mobile">Blog</Link>
              <ul className="sub-menu-mobile">
                <li className="menu-item"><Link to="blog-grid.html">Blog Grid</Link></li>
                <li className="menu-item"><Link to="blog-grid-1.html">Blog Grid 1</Link></li>
                <li className="menu-item"><Link to="blog-list.html">Blog List</Link></li>
                <li className="menu-item"><Link to="blog-list-1.html">Blog List 1</Link></li>
                <li className="menu-item"><Link to="blog-detail.html">Blog Details</Link></li>
              </ul>
            </li> */}
            <li className="menu-item">
              <Link className="item-menu-mobile" to="/user/userDashboard">Dashboard</Link>
            </li>

            {/* Login/Logout Menu */}
            <li className="menu-item">
                        {isLoggedIn ? (
                          <Link
                            to="#"
                            onClick={(e) => {
                              e.preventDefault();
                              handleLogout();
                            }}
                          >
                            Logout
                          </Link>
                        ) : (
                          <Link to="/user/login">Login</Link>
                        )}
                      </li>
          </ul>
        </nav>
        <div className="widget-search mt-30">
          <form action="#" method="get" role="search" className="search-form relative">
            <input
              type="search"
              className="search-field style-1"
              placeholder="Search..."
              name="s"
              title="Search for"
              required=""
            />
            <button className="search search-submit" type="submit" title="Search">
              <i className="icon-search"></i>
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default MobileSidebar;

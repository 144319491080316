import React, { useEffect, useState } from 'react';
import Swal from 'sweetalert2';
import Loader from '../Loader';

const NFTHistoryTable = ({ nftId }) => {
  const [history, setHistory] = useState([]);
  const [pagination, setPagination] = useState({ limit: 10, offset: 0, total: 0 });
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    fetchHistoryData(pagination.limit, pagination.offset);
  }, [nftId, pagination.limit, pagination.offset]);

  const fetchHistoryData = async (limit, offset) => {
    const token = localStorage.getItem('token'); // Get token from local storage
    try {
      const response = await fetch(`https://www.blueblocksol.com:3000/api/${nftId}/history?limit=${limit}&offset=${offset}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
      });

      if (!response.ok) {
        throw new Error('Failed to fetch NFT history data');
      }

      const data = await response.json();
      setHistory(data.history);
      setPagination((prev) => ({ ...prev, total: data.pagination.total }));
    } catch (err) {
      setError(err.message);
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: err.message,
      });
    } finally {
      setLoading(false);
    }
  };

  const handleNextPage = () => {
    if (pagination.offset + pagination.limit < pagination.total) {
      setPagination((prev) => ({ ...prev, offset: prev.offset + prev.limit }));
    }
  };

  const handlePreviousPage = () => {
    if (pagination.offset > 0) {
      setPagination((prev) => ({ ...prev, offset: prev.offset - prev.limit }));
    }
  };

  const getCurrentPage = () => Math.floor(pagination.offset / pagination.limit) + 1;
  const totalPages = Math.ceil(pagination.total / pagination.limit);

  if (loading)     return <Loader />;

  if (error) return <div>Error: {error}</div>;

  return (
    <div data-wow-delay="0s" className="wow fadeInUp col-12 mt-3">
      <div className="product-item item-activity mb-0">
        <h6><i className="icon-two-arrow rotateZ90"></i>Item activity</h6>
        <i className="icon-keyboard_arrow_down"></i>
        <div className="content">
          <div className="table-heading">
            <div className="column">Event</div>
            <div className="column">Price</div>
            <div className="column">From</div>
            <div className="column">To</div>
            <div className="column">Date</div>
          </div>
          {history.length === 0 ? (
            <div className="table-item">
              <div className="column text-center tf-color" colSpan="5">History not found</div>
            </div>
          ) : (
            history.map((item, index) => (
              <div className="table-item" key={index}>
                <div className="column flex items-center">
                  {item.event_type === 'Transfer' ? <i className="icon-two-arrow"></i> : <i className="icon-sale"></i>}
                  {item.event_type}
                </div>
                <div className="column">
                  {item.transfer_price || item.sale_price ||  item.bid_price || '-/-'}
                </div>
                <div className="column">
                  <span className="tf-color">
                    {item.previous_owner_wallet || item.seller_wallet ||  item.bidder_wallet ||'-'}
                  </span>
                </div>
                <div className="column">
                  <span className="tf-color">
                    {item.new_owner_wallet || item.buyer_wallet || '-'}
                  </span>
                </div>
                <div className="column">
                  {new Date(item.event_timestamp).toLocaleString()}
                </div>
              </div>
            ))
          )}
        </div>
      </div>

      {/* Styled Pagination */}
      <div className="col-12 mt-3">
        <div className="widget-pagination">
          <ul className="justify-center">
            <li>
              <a href="#" onClick={handlePreviousPage} disabled={pagination.offset === 0}>
                <i className="icon-keyboard_arrow_left"></i>
              </a>
            </li>
            {Array.from({ length: totalPages }, (_, i) => (
              <li key={i} className={getCurrentPage() === i + 1 ? 'active' : ''}>
                <a href="#" onClick={() => setPagination((prev) => ({ ...prev, offset: i * pagination.limit }))}>
                  {i + 1}
                </a>
              </li>
            ))}
            <li>
              <a href="#" onClick={handleNextPage} disabled={pagination.offset + pagination.limit >= pagination.total}>
                <i className="icon-keyboard_arrow_right"></i>
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default NFTHistoryTable;

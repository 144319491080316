// Dashboard.jsx
import React from 'react';
import DashboardLayout from '../components/Layout/Dashboard_layout';


const Dashboard = () => {
  return (
    <DashboardLayout />
  );
};

export default Dashboard;

import React from "react";
import { Link } from "react-router-dom";

const DesktopSidebar = ({ isOpen, toggleSidebar }) => {
  return (
    <div className={`canvas-nav-wrap ${isOpen ? "active" : ""}`}>
      <div className="overlay-canvas-nav" onClick={toggleSidebar}></div>
      <div className="inner-canvas-nav">
        <div className="side-bar">
          <Link to="/user/Home" rel="home" className="main-logo">
            <img
              id="logo_header"
              src={`${process.env.PUBLIC_URL}/CRYPTOMART.png`}
              data-retina="assets/images/logo/logo@2x.png"
            />
          </Link>
          <div className="canvas-nav-close">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="white"
              width="20px"
              height="20px"
              viewBox="0 0 122.878 122.88"
              onClick={toggleSidebar}
            >
              <g>
                <path d="M1.426,8.313c-1.901-1.901-1.901-4.984,0-6.886c1.901-1.902,4.984-1.902,6.886,0l53.127,53.127l53.127-53.127 c1.901-1.902,4.984-1.902,6.887,0c1.901,1.901,1.901,4.985,0,6.886L68.324,61.439l53.128,53.128c1.901,1.901,1.901,4.984,0,6.886 c-1.902,1.902-4.985,1.902-6.887,0L61.438,68.326L8.312,121.453c-1.901,1.902-4.984,1.902-6.886,0 c-1.901-1.901-1.901-4.984,0-6.886l53.127-53.128L1.426,8.313z"></path>
              </g>
            </svg>
          </div>
          <div className="widget-search mt-30">
            <form
              action="#"
              method="get"
              role="search"
              className="search-form relative"
            >
              <input
                type="search"
                id="search"
                className="search-field style-1"
                placeholder="Search..."
                value=""
                name="s"
                title="Search for"
                required=""
              />
              <button
                className="search search-submit"
                type="submit"
                title="Search"
              >
                <i className="icon-search"></i>
              </button>
            </form>
          </div>
          <div className="widget widget-categories">
            <h5 className="title-widget">Categories</h5>
            <ul>
              <li>
                <div className="cate-item">
                  <a href="#">NFTs</a>
                </div>
                <div className="number">(1.483)</div>
              </li>
              <li>
                <div className="cate-item">
                  <a href="#">Digital Art</a>
                </div>
                <div className="number">(97)</div>
              </li>
              <li>
                <div className="cate-item">
                  <a href="#">Crypto</a>
                </div>
                <div className="number">(45)</div>
              </li>
              <li>
                <div className="cate-item">
                  <a href="#">Technology</a>
                </div>
                <div className="number">(728)</div>
              </li>
            </ul>
          </div>
          <div className="widget widget-menu style-4">
            <h5 className="title-widget">Company</h5>
            <ul>
              <li>
                <a href="#">Help center</a>
              </li>
              <li>
                <a href="#">Platform status</a>
              </li>
            </ul>
          </div>
          <div className="widget">
            <h5 className="title-widget">Join the community</h5>
            <div className="widget-social">
              <ul className="flex">
                <li>
                  <a href="#" className="icon-facebook"></a>
                </li>
                <li>
                  <a href="#" className="icon-twitter"></a>
                </li>
                <li>
                  <a href="#" className="icon-vt"></a>
                </li>
                <li>
                  <a href="#" className="icon-tiktok"></a>
                </li>
                <li>
                  <a href="#" className="icon-youtube"></a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DesktopSidebar;

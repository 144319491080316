import React from 'react';

const Cards = ({ image, title, authorName, avatar, bid }) => {
  return (
    <div className="col-item">
      <div className="tf-card-box style-1">
        <div className="card-media">
          <a href="#">
            <img src={image} alt={title} />
          </a>
          <span className="wishlist-button icon-heart"></span>
          <div className="button-place-bid">
            <a href="#" className="tf-button"><span>Place Bid</span></a>
          </div>
        </div>
        <h5 className="name"><a href="#">{title}</a></h5>
        <div className="author flex items-center">
          <div className="avatar">
            <img src={avatar} alt={authorName} />
          </div>
          <div className="info">
            <span>Created by:</span>
            <h6><a href="#">{authorName}</a></h6>
          </div>
        </div>
     
        <div className="divider"></div>
        <div className="meta-info flex items-center justify-between">
          <span className="text-bid">Current Bid</span>
          <h6 className="price gem"><i className="icon-gem"></i>{bid}</h6>
        </div>
      </div>
    </div>
  );
};

export default Cards;

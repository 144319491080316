import React from 'react';
import { useUser } from '../../../context/UserContext'; // Import the useUser hook from your context
import '../../../DashboardCards.css'; // Import the scoped Tailwind CSS file

const DashboardCards = () => {
  const { balance, depositBalance, withdrawalBalance, nftsCreated } = useUser(); // Access balances from context

  const cards = [
    { id: 1, price: `$${balance}`, info: 'TOTAL BALANCE', icon: 'las la-wallet' },
    { id: 2, price: `$${depositBalance}`, info: 'TOTAL DEPOSIT', icon: 'las la-wallet' },
    { id: 3, price: `$${withdrawalBalance}`, info: 'TOTAL WITHDRAW', icon: 'las la-money-check' },
    { id: 4, price: nftsCreated, info: 'MY NFTs', icon: 'las la-cube' } // NFTs created
  ];

  return (
    <div className="row g-4">
      {cards.map((card) => (
        <div key={card.id} className="col-lg-6 col-xl-3 col-md-6 col-sm-10 mt-3">
          <div className={`glass-effect trending-gradient text-white p-4`}>
            <div className="dashboard__card-content">
              <h2 className="text-black dark mb-3" style={{ fontSize: '2rem' }}>{card.price}</h2>
              {/* Changed the color of card.info to black */}
              <p className="mb-4" style={{ fontSize: '1.25rem', color: 'black' }}>{card.info}</p>
              <a href="#0" className="btn btn-warning text-dark px-4 py-2 rounded-pill">
                View All
              </a>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default DashboardCards;

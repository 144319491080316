import { Line } from "react-chartjs-2";

const PriceHistory = () => {
      // Chart data
  const data = {
    labels: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul'], // X-axis labels
    datasets: [
      {
        label: 'Price',
        backgroundColor: '#161616', // Bar color
        data: [53, 162, 93, 131, 36, 93, 40], // Data for bars
        order: 2,
        borderRadius: 20, // Rounded corners for bars
        type: 'bar', // Define this dataset as a line

      },
      {
        label: 'Sale',
        data: [31, 114, 65, 93, 21, 73, 25], // Data for line
        borderColor: '#DDF247', // Line color
        borderWidth: 2, 
        fill: false, 
        type: 'line', // Define this dataset as a line
        order: 1,
        tension: 0.4, // Smoothing the line
        pointBackgroundColor: '#DDF247', // Point color
        pointBorderColor: '#000000', // Point border color
        pointRadius: 5, // Size of the points on the line
        zIndex: 2,
      },
    ],
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      x: {
        grid: {
          display: false, // Hide vertical grid lines
        },
        ticks: {
          color: '#ffffff', // X-axis label color
        },
      },
      y: {
        beginAtZero: true,
        ticks: {
          color: '#ffffff', // Y-axis label color
          precision: 0,
          stepSize: 50, // Custom step size for y-axis
        },
        grid: {
          color: 'rgba(255, 255, 255, 0.1)', // Grid line color
        },
      },
    },
    plugins: {
      legend: {
        display: false, // Hide legend
      },
      tooltip: {
        yAlign: 'bottom',
        backgroundColor: 'rgba(222, 232, 232, 0.20)',
        callbacks: {
          label: function (tooltipItem) {
            return tooltipItem.raw; // Show raw value in the tooltip
          },
        },
      },
    },
    elements: {
      bar: {
        borderRadius: 20, // Rounded corners for bars
      },
    },
}
    return(
<div data-wow-delay="0s" className="wow fadeInRight product-item history">
<h6><i class="icon-description"></i>Price History</h6>
<i class="icon-keyboard_arrow_down"></i>
<div class="content">
    <div class="chart">
    <Line data={data} options={options} />
    </div>
</div>
</div>
    );
  };
  export default PriceHistory;
  
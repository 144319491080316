import React, { useEffect, useRef, useState } from 'react';
import Countdown from '../../user_utils/Countdown';
import { useParams } from 'react-router-dom';
import HomeLayout from '../Layout/Home_layout';
import { useUser } from '../../../context/UserContext';
import Swal from 'sweetalert2'; // Import SweetAlert2
import NFTHistoryTable from './NFTHistoryTable';
import Loader from '../Loader';
import Traits from '../Home/NFT/Traits';
import PriceHistory from '../Home/NFT/PriceHistory';
import ProductDetails from '../Home/NFT/ProductDetails';

const NFTDetails = () => {
  const [showModal, setShowModal] = useState(false);

  const toggleModal = () => setShowModal(!showModal);

  const [nft, setNft] = useState({
    price: '0.032',
    saleEnd: 'May 22 at 9:39',
    image: `${process.env.PUBLIC_URL}/user/assets/images/box-item/product-detail-01.jpg`,
    favorites: 10,
    views: 208,
    collection: '8SIAN Main Collection',
    name: 'Themesflat #0270',
    owner: {
      name: 'Marvin McKinney',
      avatar: '/user/assets/images/avatar/avatar-box-05.jpg',
    },
    description: '8,888 NFTs of beautiful, Asian women painstakingly-crafted...',
    contractAddress: '0x1984...c38f',
    tokenId: '0270',
    tokenStandard: 'ERC-721',
    chain: 'Ethereum',
    lastUpdated: '8 months ago',
    creatorEarnings: '8%',
  });
  const [nftData, setNftData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { id } = useParams(); // Extract the 'id' from the URL
  const viewPostedRef = useRef(false); // Ref to track if the view has been posted
  const { balance } = useUser(); // Access deposit balance from context
  const [bidAmount, setBidAmount] = useState(''); // State to handle bid amount input

  useEffect(() => {
    // Function to fetch NFT details
    const fetchNftData = async () => {
      const token = localStorage.getItem('token');

      try {
        const response = await fetch(`https://www.blueblocksol.com:3000/api/nfts/${id}`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
          },
        });

        if (!response.ok) {
          throw new Error('Failed to fetch NFT data');
        }

        const data = await response.json();
console.log(data);

        setNftData(data);
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchNftData();
  }, [id]);

  // Function to send POST request to track view
  const postView = async () => {
    const token = localStorage.getItem('token');
    
    if (viewPostedRef.current) return; // Prevent multiple API calls

    try {
      const response = await fetch(`https://www.blueblocksol.com:3000/api/nfts/${id}/view`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
      });

      if (!response.ok) {
        throw new Error('Failed to post view data');
      }

      console.log('View posted successfully');
      viewPostedRef.current = true; // Mark as posted
    } catch (err) {
      console.error('Error posting view:', err.message);
    }
  };

  // Function to handle bidding
  const handleBid = async () => {
    const token = localStorage.getItem('token');

    if (!bidAmount || parseFloat(bidAmount) > balance) {
      Swal.fire({
        icon: 'error',
        title: 'Insufficient Balance',
        text: 'Your bid amount exceeds your current balance or is invalid.',
      });
      return;
    }

    try {
      const response = await fetch('https://www.blueblocksol.com:3000/api/bids', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
        body: JSON.stringify({
          auctionId: nftData.auction_id,
          bidAmount,
        }),
      });

      if (!response.ok) {
        throw new Error('Failed to place bid');
      }

      Swal.fire({
        icon: 'success',
        title: 'Bid Placed',
        text: 'Your bid has been placed successfully!',
      });
    } catch (err) {
      console.error('Error placing bid:', err.message);
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'There was an error placing your bid. Please try again.',
      });
    }
  };

  // Function to handle direct purchase
  const handlePurchase = async () => {
    const token = localStorage.getItem('token');

    // Check if the user has sufficient balance before making the purchase
    if (parseFloat(nftData.price) > parseFloat(balance)) {
        Swal.fire({
            icon: 'error',
            title: 'Insufficient Balance',
            text: 'Your balance is insufficient to purchase this NFT.',
        });
        return;
    }

    try {
        const response = await fetch('https://www.blueblocksol.com:3000/api/purchase/direct', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
            },
            body: JSON.stringify({
                nftId: id,
                price: nftData.price,
            }),
        });

        if (!response.ok) {
            throw new Error('Failed to purchase NFT');
        }

        Swal.fire({
            icon: 'success',
            title: 'Purchase Successful',
            text: 'You have successfully purchased the NFT!',
        });
        toggleModal()
    } catch (err) {
        console.error('Error purchasing NFT:', err.message);
        Swal.fire({
            icon: 'error',
            title: 'Error',
            text: 'There was an error purchasing the NFT. Please try again.',
        });
    }
};

  useEffect(() => {
    const handlePageLoad = () => {
      postView(); // Call the postView function after the full page is loaded
    };

    window.addEventListener('load', handlePageLoad);

    return () => {
      window.removeEventListener('load', handlePageLoad); // Clean up the event listener
    };
  }, [id]);

  if (loading)     return <Loader />;

  if (error) return <div>Error: {error}</div>;
  // const showModal = () => {
  //   const modal = document.getElementById('purchaseModal');
  //   modal.classList.add('show');
  //   modal.style.display = 'block';
  // };
  
  const hideModal = () => {
    const modal = document.getElementById('purchaseModal');
    modal.classList.remove('show');
    modal.style.display = 'none';
  };
  
  
  return (
    <HomeLayout>
      <div className="tf-section-2 product-detail">
        <div className="themesflat-container">
          <div className="row">
            {/* Left Section */}
            <div className="col-md-6">
              <div className="tf-card-box style-5 mb-0">
                <div className="card-media mb-0">
                  <a href="#">
                    <img src={`https://www.blueblocksol.com:3000${nftData.image_url}`} alt={nftData.name} />
                  </a>
                </div>
                <h6 className="price gem"><i className="icon-gem"></i></h6>
                <div className="wishlist-button">{nftData.favorites}<i className="icon-heart"></i></div>
              </div>
              <ProductDetails transactionHash={nftData.transactionHash} main_nft_id={nftData.main_nft_id} metaurl={nftData.metadataUrl} />

            </div>
           

            {/* Right Section */}
            <div className="col-md-6">
              <div className="infor-product">
                <div className="text">{nftData.collection}</div>
                <h2>{nftData.name}</h2>
                <div className="author flex items-center mb-30">
                  <div className="avatar">
                    <img src={nft.owner.avatar} alt={nft.owner.name} />
                  </div>
                  <div className="info">
                    <span>Owned by:</span>
                    <h6><a href="#">{nftData.first_name} {nftData.last_name}</a></h6>
                  </div>
                </div>
                <div className="meta mb-20">
                  <div className="meta-item view">
                    <i className="icon-show"></i>{nftData.views} views
                  </div>
                  <div className="meta-item favorites">
                    <i className="icon-heart"></i>{nftData.favorites} favorites
                  </div>
                  <div className="meta-item favorites">
                    Balance: ${balance}
                  </div>
                </div>
              </div>

              {/* Sale Information */}
              <div className="product-item time-sales">
                <h6>  {nftData.sale_type !== 'sale' && (
                  <>
                    <i className="icon-clock"></i> Sale ends :
                  </>
                )}

                {/* Conditionally show Countdown only if sale_type is not 'sale' */}
                {nftData.sale_type !== 'sale' && (
                  <Countdown
                    timer={nftData.auction_duration_seconds}
                    labels={['d', 'h', 'm', 's']}
                    visibleLabels={['d', 'h', 'm', 's']}
                  />
                )}
                </h6>
                <div className="content">
                  <div className="text">Current price </div>
                  <div className="flex justify-between">
                    {/* Conditionally render fixed price or editable price */}
                    <p className="editable-price">
                      {nftData.sale_type === 'sale' ? (
                        `${nftData.price} MT`
                      ) : (
                        <p
                          contentEditable="true"
                          suppressContentEditableWarning={true}
                          onInput={(e) => setBidAmount(e.currentTarget.textContent)}
                        >
                          {nftData.current_highest_bid}
                        </p>
                      )}
                    </p>
                    {/* Conditionally show Purchase or Place a Bid button */}
                    {nftData.sale_type === 'sale' ? (
                      <a href="#" className="tf-button style-1 h50 w216" onClick={toggleModal}>
                        Purchase <i className="icon-arrow-up-right2"></i>
                      </a>
                    ) : (
                      <a href="#" className="tf-button style-1 h50 w216" onClick={handleBid}>
                        Place a bid <i className="icon-arrow-up-right2"></i>
                      </a>
                    )}
                  </div>
                </div>
              </div>
              {/* Description */}
              <div className="product-item description">
                <h6><i className="icon-description"></i>Description</h6>
                <div className="content">
                  <p>{nftData.description}</p>
                </div>
              </div>
              <PriceHistory/>
              <Traits traitsData={nftData.traits}/>

            </div>

            <NFTHistoryTable nftId={id}/>
          </div>
        </div>
      </div>
      {/* <!-- Bootstrap Modal --> */}
      {showModal && (
  <div
    className="modal fade show"
    id="purchaseModal"
    tabIndex="-1"
    role="dialog"
    aria-labelledby="purchaseModalLabel"
    aria-hidden="true"
    style={{ display: 'block', background: 'rgba(0,0,0,0.5)' }}
  >
    <div className="modal-dialog modal-lg modal-dialog-centered" role="document">
      <div style={{ background: '#0F0F0F' }} className="modal-content">
        {/* Black Header */}
        <div className="modal-header text-white" style={{ background: '#0F0F0F' }}>
          <h5 style={{ background: '#0F0F0F' }} className="modal-title" id="purchaseModalLabel">
            Confirm Purchase
          </h5>
          <button
            type="button"
            className="btn-close btn-close-white"
            onClick={toggleModal}
            aria-label="Close"
          >
            <i className='ti ti-square-rounded-x'></i>
          </button>
        </div>

        {/* Modal Body */}
        <div className="modal-body">
          <div className="row">
            {/* Image Section */}
            <div className="col-md-6 col-sm-12 mb-3 text-center">
              <img
                src={`https://www.blueblocksol.com:3000${nftData.image_url}`}
                alt={nftData.name}
                className="img-fluid rounded"
                style={{ maxHeight: '250px', objectFit: 'cover' }}
              />
            </div>

            {/* Details Section */}
            <div className="col-md-6 col-sm-12 d-flex flex-column align-items-start text-center text-md-start">
              <p className="mb-2">
                <strong>NFT Name: </strong> {nftData.name}
              </p>
              <p className="mb-2">
                <strong>Price: </strong> {nftData.price} MT
              </p>
              <p className="mb-2">
                <strong>Your Balance: </strong> {balance} MT
              </p>
              <p style={{ color: '#E2FE26' }} className="mt-2">
                Are you sure you want to purchase the NFT "{nftData.name}" for {nftData.price} MT?
              </p>
            </div>
          </div>
        </div>

        {/* Footer */}
        <div className="modal-footer bg-dark d-flex" style={{ background: '#0F0F0F' }}>
          <button
            type="button"
            className="btn btn-secondary"
            onClick={toggleModal}
          >
            Close
          </button>
          <button
            type="button"
            className="btn btn-success"
            onClick={handlePurchase}
          >
            Confirm Purchase
          </button>
        </div>
      </div>
    </div>
  </div>
)}





    </HomeLayout>
  );
};

export default NFTDetails;

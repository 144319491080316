import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import axios from "axios"; // Import axios for making HTTP requests
import { WOW } from "wowjs";

import "animate.css"; // Import animate.css for animations

const DiscoverCard = ({ cards }) => {
  useEffect(() => {
    const wowInstance = new WOW({
      boxClass: "wow", // Class for animated elements
      animateClass: "animated", // Animation class to use
      offset: 0, // Distance to start the animation (default is 0)
      mobile: true, // Trigger animations on mobile devices (default is true)
      live: true, // Act on asynchronously loaded content (default is true)
    });
    wowInstance.init();
  }, []); // Empty dependency array ensures this runs only once after the component mounts

  // Function to handle adding to wishlist
  const handleAddToWishlist = async (nftID) => {
    // Retrieve the token from localStorage
    const token = localStorage.getItem("token");
    console.log("NFT ID:", nftID);

    if (!token) {
      console.error("No token found in localStorage");
      return;
    }

    try {
      const response = await axios.post(
        "https://www.blueblocksol.com:3000/api/wishlist/add", // API endpoint
        { nftId: nftID }, // Send the nftID in the request body
        {
          headers: {
            Authorization: `Bearer ${token}`, // Send the token in headers
            "Content-Type": "application/json",
          },
        }
      );

      // Handle the response after adding to the wishlist
      if (response.status === 200) {
        console.log("Successfully added to wishlist");
        // Optionally, you can show a success message or update the UI
      }
    } catch (error) {
      console.error("Error adding to wishlist:", error);
      // Optionally, handle errors (e.g., show an error message)
    }
  };

  return (
    <div className="tf-section-3 discover-item">
      <div className="themesflat-container">
        <div className="row">
          {cards.map((card, index) => (
            <div
              key={index}
              className="discover-card wow fadeInUp col-xl-3 col-lg-4 col-md-6 col-sm-12 animated"
              style={{
                animationDelay: `${index * 0.1}s`,
                animationName: "fadeInUp",
              }}
            >
              <Link to={`/user/nft/${card.nftID}`}>
              <div className="tf-card-box style-1">
                <div className="card-media">
                
                <Link to={`/user/nft/${card.nftID}`}>
                    <img src={card.image} alt={card.title} />
                  </Link>
                  <span
                    className="wishlist-button icon-heart"
                    onClick={() => handleAddToWishlist(card.nftID)} // Add click handler
                    style={{ cursor: "pointer" }} // Add cursor pointer style
                  ></span>
                  <div className="button-place-bid">
                    <Link to={`/user/nft/${card.nftID}`} className="tf-button">
                      <span>{card.bid ? "Place Bid" : "Purchase"}</span>
                    </Link>
                  </div>
                  
                </div>
                <h5 className="name">
                  <a href="#">{card.title}</a>
                </h5>
                <div className="author flex items-center">
                  <div className="avatar">
                    <img src={card.avatar} alt={card.authorName} />
                  </div>
                  <div className="info">
                    <span>Posted by:</span>
                    <h6>
                      <a href="#">{card.authorName}</a>
                    </h6>
                  </div>
                </div>
                <div className="profile-share">
                  <a
                   
                    className="avatar"
                    data-tooltip="Mark JOrdan"
                    tabindex="0"
                  >
                    <img
                      src={card.avatar}
                      className="avatar"
                      alt="Nft_Profile"
                    />
                  </a>
                  <a
                   
                    className="avatar"
                    data-tooltip="Mark JOrdan"
                    tabindex="0"
                  >
                    <img
                      src={card.avatar}
                      className="avatar"
                      alt="Nft_Profile"
                    />
                  </a>
                  <a
                 
                    className="avatar"
                    data-tooltip="Mark JOrdan"
                    tabindex="0"
                  >
                    <img
                      src={card.avatar}
                      className="avatar"
                      alt="Nft_Profile"
                    />
                  </a>

                  <a class="more-author-text" href="#" tabindex="0">
                    20+ Place Bit.
                  </a>
                </div>
                <div className="divider"></div>
                <Link to={`/user/nft/${card.nftID}`}>
                  <div className="meta-info flex items-center justify-between">
                    <span className="text-bid">
                      {card.bid ? "Current Bid" : "Current Price"}{" "}
                      {/* Dynamically set the label */}
                    </span>
                    <h6 className="price gem">
                      <i className="icon-gem"></i>
                      {card.bid ? card.bid : card.price}{" "}
                      {/* Display the correct value */}
                    </h6>
                  </div>
                </Link>
              
              </div>
              </Link>

            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

// Export Component
export default DiscoverCard;

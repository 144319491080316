import React, { useState } from "react";
import HomeLayout from "../components/Layout/Home_layout"; // Import HomeLayout component

import GameComponent from "../components/GameComponent";

const PlayGame = () => {
 



  return (
    <HomeLayout>
      <div className="themesflat-container">
        <div className="row">
          <div className="col-md-12 mt-10">
          <GameComponent />

          </div>
        </div>
      </div>


    </HomeLayout>
  );
};

export default PlayGame;

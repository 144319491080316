import React from 'react';


const Loader = () => {
  return (
    <div className="loader-container">
      {/* Spinner */}
      <div className="spinner"></div>
    </div>
  );
};

export default Loader;

import React from 'react';
import { IconHome, IconWallet, IconSettings, IconLogout } from '@tabler/icons-react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useUser } from '../../../context/UserContext'; // Import useUser context

const MobileMenuBar = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { user, setUser } = useUser(); // Use useUser context

  // Function to handle navigation
  const handleNavigate = (path) => {
    navigate(path);
  };

  // Function to handle logout
  const handleLogout = () => {
    setUser(null);
    localStorage.removeItem('token');
    navigate('/');
    console.log('User logged out successfully.');
  };

  // Function to determine if the current page matches the given path
  const isActive = (path) => location.pathname === path;

  return (
    <div className="mobile-menu-bar">
      <button
        className={`menu-item p-0 ${isActive('/user/Home') ? 'active' : ''}`}
        onClick={() => handleNavigate('/user/Home')}
      >
        <IconHome size={100} color={isActive('/user/Home') ? '#007bff' : '#333'} />
        <span>Home</span>
      </button>
      <button
        className={`menu-item p-0 ${isActive('/user/userDashboard') ? 'active' : ''}`}
        onClick={() => handleNavigate('/user/userDashboard')}
      >
        <IconWallet size={100} color={isActive('/user/userDashboard') ? '#007bff' : '#333'} />
        <span>Wallet</span>
      </button>
      {/* <button
        className={`menu-item p-0 ${isActive('/user/settings') ? 'active' : ''}`}
        onClick={() => handleNavigate('/user/settings')}
      >
        <IconSettings size={100} color={isActive('/user/settings') ? '#007bff' : '#333'} />
        <span>Settings</span>
      </button> */}
      <button className="menu-item p-0" onClick={handleLogout}>
        <IconLogout size={100} color="#333" />
        <span>Logout</span>
      </button>
    </div>
  );
};

export default MobileMenuBar;

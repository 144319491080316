import React, { useState } from 'react';
import { useUser } from '../../../context/UserContext'; // Import the custom hook to use the UserContext
import { Link, useNavigate } from 'react-router-dom'; // Import the useNavigate hook

const Dashboard_header = () => {
  const { user, setUser } = useUser(); // Access user details and setUser function from context
  const [isUserMenuVisible, setUserMenuVisible] = useState(false);
  const [isNotificationVisible, setNotificationVisible] = useState(false);
  const navigate = useNavigate(); // Initialize useNavigate for redirecting

  // Toggle the visibility of the user menu
  const toggleUserMenu = () => {
    setUserMenuVisible(!isUserMenuVisible);
  };

  // Toggle the visibility of the notifications
  const toggleNotifications = () => {
    setNotificationVisible(!isNotificationVisible);
  };

  // Get user's full name from user context
  const userName = user ? `${user.firstname} ${user.lastname}` : 'User'; // Fallback to 'User' if user info is not available

  // Handle logout
  const handleLogout = () => {
    // Clear the user state
    setUser(null);

    // Remove the token from local storage
    localStorage.removeItem('token');

    // Redirect to the home page
    navigate('/');

    console.log('User logged out successfully.');
  };

  return (
    <div id="market-header">
      <div className="market-header flex items-center justify-between">
      <div id="site-logo">
                    <div id="site-logo-inner">
                      <Link to="/user/Home" className="main-logo">
                        <img
                          id="logo_header"
                          src={`${process.env.PUBLIC_URL}/CRYPTOMART.png`}
                          alt="logo"
                          style={{ width: 70 }}
                        />
                      </Link>
                    </div>
                  </div>
        {/* Search Widget */}
        <div className="ml-5 widget-search">
          <form action="#" method="get" role="search" className="search-form relative">
            <input
              type="search"
              id="search"
              className="search-field style-1"
              placeholder="Search artwork, collection..."
              name="s"
              title="Search for"
              required
            />
            <button className="search search-submit" type="submit" title="Search">
              Search
            </button>
          </form>
        </div>
   
        <div className="admin_active" id="header_admin">
          <div className="popup-notification relative">
            <div className="notification" onClick={toggleNotifications}>
              <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fillRule="evenodd" clipRule="evenodd" d="M12 18.8476C17.6392 18.8476 20.2481 18.1242 20.5 15.2205C20.5 12.3188 18.6812 12.5054 18.6812 8.94511C18.6812 6.16414 16.0452 3 12 3C7.95477 3 5.31885 6.16414 5.31885 8.94511C5.31885 12.5054 3.5 12.3188 3.5 15.2205C3.75295 18.1352 6.36177 18.8476 12 18.8476Z" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                <path d="M14.3888 21.8574C13.0247 23.3721 10.8967 23.3901 9.51947 21.8574" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                <circle cx="17" cy="5" r="4" fill="#DDF247" stroke="#1D1D1D" strokeWidth="1.5"></circle>
              </svg>
            </div>
            <div className={`avatar_popup ${isNotificationVisible ? 'visible' : ''}`}>
              <h5 className="mb-30">Notification</h5>
              <div className="widget-recently">
                <div className="card-small">
                  <div className="author">
                    <img src="assets/images/blog/sidebar-06.jpg" alt="" />
                    <div className="info">
                      <h6><a href="#">Propw</a></h6>
                      <p><a href="#">@themes</a></p>
                    </div>
                  </div>
                  <span className="date">Mon, 08 May </span>
                </div>
                <div className="card-small">
                  <div className="author">
                    <img src="assets/images/blog/sidebar-06.jpg" alt="" />
                    <div className="info">
                      <h6><a href="#">Propw</a></h6>
                      <p><a href="#">@themes</a></p>
                    </div>
                  </div>
                  <span className="date">Mon, 08 May </span>
                </div>
              </div>
            </div>
          </div>
          <div className="popup-user relative">
            <div className="user" onClick={toggleUserMenu}>
              <img src="assets/images/avatar/avatar-small-09.png" alt="" />
              {/* Replace 'Themesflat' with user's full name */}
              <span>{userName}<i className="icon-keyboard_arrow_down"></i></span>
            </div>
            <div className={`avatar_popup2 ${isUserMenuVisible ? 'visible' : ''}`}>
              <div className="">
                <div className="links">
                  <a className="block mb-30" href="#">
                    {/* Profile SVG Icon */}
                    <span>My Profile</span>
                  </a>
                  <a className="block mb-30">
                    {/* Wallet SVG Icon */}
                    <span>Wallet</span>
                  </a>
                  <a className="block" href="#" onClick={(e) => { e.preventDefault(); handleLogout(); }} id="logout">
                    {/* Logout SVG Icon */}
                    <span>Log out</span>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dashboard_header;

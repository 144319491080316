import React, { useState } from 'react';
import axios from 'axios';
import Swal from 'sweetalert2';

const RegistrationModal = ({ isOpen, onClose, walletAddress }) => {
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    otp: '',
    password: '',
    confirmPassword: '',
    dob: '',
  });

  const [otpSent, setOtpSent] = useState(false);
  const [otpVerified, setOtpVerified] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSendOtp = async () => {
    if (formData.email) {
      try {
        await axios.post('https://www.blueblocksol.com:3000/api/send-otp', { email: formData.email });
        Swal.fire('Success', 'OTP sent to your email. Please check your inbox.', 'success');
        setOtpSent(true);
      } catch (err) {
        Swal.fire('Error', 'Failed to send OTP. Please try again.', 'error');
      }
    }
  };

  const handleOtpVerification = async () => {
    if (formData.otp) {
      try {
        const response = await axios.post('https://www.blueblocksol.com:3000/api/verify-otp', {
          email: formData.email,
          otp: formData.otp,
        });
        if (response.data.success) {
          Swal.fire('Success', 'OTP verified successfully.', 'success');
          setOtpVerified(true);
        } else {
          Swal.fire('Error', 'Invalid OTP. Please try again.', 'error');
        }
      } catch (error) {
        Swal.fire('Error', 'Failed to verify OTP.', 'error');
      }
    } else {
      Swal.fire('Error', 'Please enter the OTP.', 'error');
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError('');

    if (!otpVerified) {
      setError('Please verify your email first.');
      setLoading(false);
      return;
    }

    if (formData.password !== formData.confirmPassword) {
      setError('Passwords do not match.');
      setLoading(false);
      return;
    }

    try {
      await axios.post('https://www.blueblocksol.com:3000/api/auth/register', {
        firstName: formData.firstName,
        lastName: formData.lastName,
        email: formData.email,
        password: formData.password,
        dob: formData.dob,
        walletAddress: walletAddress,
      });

      Swal.fire('Success', 'Registration successful!', 'success');
      onClose(); // Close the modal after successful registration
    } catch (err) {
      setError(err.response?.data?.message || 'Registration failed');
    } finally {
      setLoading(false);
    }
  };

  if (!isOpen) return null;

  return (
    <div
      className="d-flex justify-content-center align-items-center position-fixed top-0 start-0 w-100 h-100 bg-dark bg-opacity-50"
      aria-modal="true"
      role="dialog"
    >
      <div className="modal-dialog modal-dialog-centered" role="document">
        <div className="modal-content p-4 rounded-3" style={{ backgroundColor: 'black', borderRadius: '10px', maxHeight: '80vh', overflowY: 'auto' }}>
          <div className="d-flex justify-content-end">
            <span onClick={onClose} style={{ cursor: 'pointer', color: '#d3d3d3', fontSize: '24px' }}>X</span>
          </div>

          <h2 className="text-center mb-4" style={{ color: '#d3d3d3' }}>Register</h2>

          <form onSubmit={handleSubmit}>
            <fieldset className="first-name" style={{ marginBottom: '10px' }}>
              <label>First Name *</label>
              <input
                type="text"
                name="firstName"
                placeholder="Your first name"
                value={formData.firstName}
                onChange={handleChange}
                required
                style={{ color: 'white', backgroundColor: '#555' }}
              />
            </fieldset>

            <fieldset className="last-name" style={{ marginBottom: '10px' }}>
              <label>Last Name *</label>
              <input
                type="text"
                name="lastName"
                placeholder="Your last name"
                value={formData.lastName}
                onChange={handleChange}
                required
                style={{ color: 'white', backgroundColor: '#555' }}
              />
            </fieldset>

            <fieldset className="email" style={{ marginBottom: '10px' }}>
              <label>Email *</label>
              <input
                type="email"
                name="email"
                placeholder="mail@website.com"
                value={formData.email}
                onChange={handleChange}
                required
                style={{ color: 'white', backgroundColor: '#555' }}
              />
            </fieldset>

            {otpSent && !otpVerified && (
              <>
                <fieldset className="otp" style={{ marginBottom: '10px' }}>
                  <label>OTP *</label>
                  <input
                    type="text"
                    name="otp"
                    placeholder="Enter OTP"
                    value={formData.otp}
                    onChange={handleChange}
                    required
                    style={{ color: 'white', backgroundColor: '#555' }}
                  />
                </fieldset>
                <button type="button" onClick={handleOtpVerification}>
                  Verify OTP
                </button>
              </>
            )}

            {!otpSent && !otpVerified && (
              <button type="button" onClick={handleSendOtp}>
                Send OTP
              </button>
            )}

            <fieldset className="dob" style={{ marginBottom: '10px' }}>
              <label>Date of Birth *</label>
              <input
                type="date"
                name="dob"
                value={formData.dob}
                onChange={handleChange}
                required
                style={{ color: 'white', backgroundColor: '#555' }}
              />
            </fieldset>

            <fieldset className="password" style={{ marginBottom: '10px', position: 'relative' }}>
              <label>Password *</label>
              <input
                type="password"
                name="password"
                placeholder="Your password"
                value={formData.password}
                onChange={handleChange}
                required
                style={{ color: 'white', backgroundColor: '#555' }}
              />
            </fieldset>

            <fieldset className="confirm-password" style={{ marginBottom: '10px', position: 'relative' }}>
              <label>Confirm Password *</label>
              <input
                type="password"
                name="confirmPassword"
                placeholder="Confirm your password"
                value={formData.confirmPassword}
                onChange={handleChange}
                required
                style={{ color: 'white', backgroundColor: '#555' }}
              />
            </fieldset>

            {error && <p style={{ color: 'red' }}>{error}</p>}

            <div className="btn-submit mb-20 mt-4">
              <button className="tf-button style-1 h50 w-100" type="submit" disabled={loading}>
                {loading ? 'Registering...' : 'Create Account'}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default RegistrationModal;

import React, { useState } from 'react';
import { Bar, Line, Pie } from 'react-chartjs-2';
import 'chart.js/auto'; // Import to support automatic chart registration

const ProfitLossGraphs = () => {
  // Dummy data for the charts
  const dummyProfitLossOverTime = [
    { date: '2024-01-01', roulette: 100, blackjack: 150, slots: -50 },
    { date: '2024-01-02', roulette: -50, blackjack: 100, slots: 200 },
    { date: '2024-01-03', roulette: 200, blackjack: -75, slots: 50 },
    { date: '2024-01-04', roulette: -75, blackjack: 300, slots: -100 },
    { date: '2024-01-05', roulette: 300, blackjack: -150, slots: 150 },
  ];

  const dummyProfitByCategory = [
    { category: 'Arcade', profit: 400 },
    { category: 'Card Games', profit: -200 },
    { category: 'Puzzle Games', profit: 150 },
  ];

  const dummyBidsPerDay = [
    { date: '2024-01-01', bids: 10 },
    { date: '2024-01-02', bids: 15 },
    { date: '2024-01-03', bids: 7 },
    { date: '2024-01-04', bids: 20 },
    { date: '2024-01-05', bids: 12 },
  ];

  const dummyNftsSoldByCategory = [
    { category: 'Digital Art', sold: 30 },
    { category: 'Music', sold: 25 },
    { category: 'Collectibles', sold: 40 },
  ];

  // Data for the charts
  const lineChartData = {
    labels: dummyProfitLossOverTime.map((entry) => entry.date),
    datasets: [
      {
        label: 'Roulette Profit/Loss',
        data: dummyProfitLossOverTime.map((entry) => entry.roulette),
        borderColor: 'green',
        backgroundColor: 'rgba(0, 128, 0, 0.3)',
        fill: false,
        tension: 0.1,
      },
      {
        label: 'Blackjack Profit/Loss',
        data: dummyProfitLossOverTime.map((entry) => entry.blackjack),
        borderColor: 'blue',
        backgroundColor: 'rgba(0, 0, 255, 0.3)',
        fill: false,
        tension: 0.1,
      },
      {
        label: 'Slots Profit/Loss',
        data: dummyProfitLossOverTime.map((entry) => entry.slots),
        borderColor: 'red',
        backgroundColor: 'rgba(255, 0, 0, 0.3)',
        fill: false,
        tension: 0.1,
      },
    ],
  };

  const barChartDataCategory = {
    labels: dummyProfitByCategory.map((entry) => entry.category),
    datasets: [
      {
        label: 'Total Profit by Game Category',
        data: dummyProfitByCategory.map((entry) => entry.profit),
        backgroundColor: ['#FF6384', '#36A2EB', '#FFCE56'],
        borderColor: '#333',
        borderWidth: 1,
      },
    ],
  };

  const lineChartDataBids = {
    labels: dummyBidsPerDay.map((entry) => entry.date),
    datasets: [
      {
        label: 'Number of Bids Per Day',
        data: dummyBidsPerDay.map((entry) => entry.bids),
        borderColor: '#FF5733',
        backgroundColor: 'rgba(255, 87, 51, 0.3)',
        fill: true,
        tension: 0.1,
      },
    ],
  };

  const pieChartDataNFTsSold = {
    labels: dummyNftsSoldByCategory.map((entry) => entry.category),
    datasets: [
      {
        label: 'NFTs Sold by Category',
        data: dummyNftsSoldByCategory.map((entry) => entry.sold),
        backgroundColor: ['#FF6384', '#36A2EB', '#FFCE56'],
        borderColor: '#333',
        borderWidth: 1,
      },
    ],
  };

  const chartOptions = {
    responsive: true,
    maintainAspectRatio: false,
  };

  return (
    <div
      style={{
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'space-between',
        gap: '20px',
        marginTop: '40px',
      }}
    >
      {/* Profit/Loss Over Time Graph for Games */}
      <div
        data-wow-delay="0s"
        className="wow fadeInRight product-item history animated"
        style={{
          visibility: 'visible',
          animationDelay: '0s',
          animationName: 'fadeInRight',
          width: 'calc(50% - 20px)',
          minWidth: '300px',
          marginBottom: '20px',
          flex: '1 1 calc(50% - 20px)',
        }}
      >
        <h6><i className="icon-description"></i>Profit/Loss Over Time (Roulette, Blackjack, Slots)</h6>
        <i className="icon-keyboard_arrow_down"></i>
        <div className="content">
          <div className="chart" style={{ height: '300px' }}>
            <Line data={lineChartData} options={chartOptions} />
          </div>
        </div>
      </div>

      {/* Total Profit by Game Category */}
      <div
        data-wow-delay="0s"
        className="wow fadeInRight product-item history animated"
        style={{
          visibility: 'visible',
          animationDelay: '0s',
          animationName: 'fadeInRight',
          width: 'calc(50% - 20px)',
          minWidth: '300px',
          marginBottom: '20px',
          flex: '1 1 calc(50% - 20px)',
        }}
      >
        <h6><i className="icon-description"></i>Total Profit by Game Category (Arcade, Card Games, Puzzle)</h6>
        <i className="icon-keyboard_arrow_down"></i>
        <div className="content">
          <div className="chart" style={{ height: '300px' }}>
            <Bar data={barChartDataCategory} options={chartOptions} />
          </div>
        </div>
      </div>

      {/* Bids Per Day Graph */}
      <div
        data-wow-delay="0s"
        className="wow fadeInRight product-item history animated"
        style={{
          visibility: 'visible',
          animationDelay: '0s',
          animationName: 'fadeInRight',
          width: 'calc(50% - 20px)',
          minWidth: '300px',
          marginBottom: '20px',
          flex: '1 1 calc(50% - 20px)',
        }}
      >
        <h6><i className="icon-description"></i>Bids Per Day</h6>
        <i className="icon-keyboard_arrow_down"></i>
        <div className="content">
          <div className="chart" style={{ height: '300px' }}>
            <Line data={lineChartDataBids} options={chartOptions} />
          </div>
        </div>
      </div>

      {/* NFTs Sold by Category */}
      <div
        data-wow-delay="0s"
        className="wow fadeInRight product-item history animated"
        style={{
          visibility: 'visible',
          animationDelay: '0s',
          animationName: 'fadeInRight',
          width: 'calc(50% - 20px)',
          minWidth: '300px',
          marginBottom: '20px',
          flex: '1 1 calc(50% - 20px)',
        }}
      >
        <h6><i className="icon-description"></i>NFTs Sold by Category</h6>
        <i className="icon-keyboard_arrow_down"></i>
        <div className="content">
          <div className="chart" style={{ height: '300px' }}>
            <Pie data={pieChartDataNFTsSold} options={chartOptions} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProfitLossGraphs;

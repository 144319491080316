import React, { useEffect, useState } from 'react';
import { Bar } from 'react-chartjs-2';
import 'chart.js/auto'; // Import to support automatic chart registration

const NFTTradeVolumeGraph = () => {
  const [dates, setDates] = useState([]);
  const [nftTradeVolumeData, setNftTradeVolumeData] = useState({
    Ethereum: [],
    BSC: [],
    Solana: [],
    Polygon: [],
    Avalanche: [],
  });

  // Simulate fetching NFT trade volume data for the past 12 months
  useEffect(() => {
    const generateDummyData = () => {
      const generatedDates = [];
      const ethereumVolume = [];
      const bscVolume = [];
      const solanaVolume = [];
      const polygonVolume = [];
      const avalancheVolume = [];

      const currentDate = new Date();
      for (let i = 52; i >= 0; i--) { // Simulate weekly data for the past year
        const date = new Date(currentDate);
        date.setDate(currentDate.getDate() - i * 7);
        generatedDates.push(date.toLocaleDateString());

        // Simulated trade volumes in millions
        ethereumVolume.push(Math.floor(Math.random() * 500) + 100); // Simulate trade volume in millions
        bscVolume.push(Math.floor(Math.random() * 300) + 50);
        solanaVolume.push(Math.floor(Math.random() * 200) + 30);
        polygonVolume.push(Math.floor(Math.random() * 100) + 20);
        avalancheVolume.push(Math.floor(Math.random() * 80) + 10);
      }

      setDates(generatedDates);
      setNftTradeVolumeData({
        Ethereum: ethereumVolume,
        BSC: bscVolume,
        Solana: solanaVolume,
        Polygon: polygonVolume,
        Avalanche: avalancheVolume,
      });
    };

    generateDummyData();
  }, []);

  // Data for the stacked bar chart
  const nftTradeVolumeGraphData = {
    labels: dates, // X-axis labels (dates)
    datasets: [
      {
        label: 'Ethereum',
        data: nftTradeVolumeData.Ethereum,
        backgroundColor: '#6272A4', // Ethereum color
      },
      {
        label: 'Binance Smart Chain (BSC)',
        data: nftTradeVolumeData.BSC,
        backgroundColor: '#F2A900', // BSC color
      },
      {
        label: 'Solana',
        data: nftTradeVolumeData.Solana,
        backgroundColor: '#00FFA3', // Solana color
      },
      {
        label: 'Polygon',
        data: nftTradeVolumeData.Polygon,
        backgroundColor: '#8247E5', // Polygon color
      },
      {
        label: 'Avalanche',
        data: nftTradeVolumeData.Avalanche,
        backgroundColor: '#E84142', // Avalanche color
      },
    ],
  };

  const chartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      title: {
        display: true,
        text: 'NFT Trade Volume by Chain (Past Year)',
      },
      legend: {
        position: 'top',
      },
      tooltip: {
        callbacks: {
          label: function (context) {
            // Format tooltip to display in millions
            let value = context.raw || 0;
            return `${context.dataset.label}: $${value.toFixed(2)}m`;
          },
        },
      },
    },
    scales: {
      x: {
        stacked: true,
        title: {
          display: true,
          text: 'Date',
        },
      },
      y: {
        stacked: true,
        title: {
          display: true,
          text: 'Volume in Millions ($)',
        },
        ticks: {
          callback: function (value) {
            // Format the Y-axis ticks to show in millions
            return `$${value}m`;
          },
        },
      },
    },
  };

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        marginTop: '40px',
        width: '100%',
      }}
    >
      {/* NFT Trade Volume Graph */}
      <div
        className="chart-container"
        style={{
          width: '100%',
          maxWidth: '1100px',
          height: '600px', // Increased height for the chart
          marginBottom: '20px',
        }}
      >
        <Bar data={nftTradeVolumeGraphData} options={chartOptions} />
      </div>
    </div>
  );
};

export default NFTTradeVolumeGraph;

import React from 'react';
import { Link } from 'react-router-dom';

const CardItem = ({ image, title, authorName, avatar, currentBid }) => {
  return (
    <div className="tf-card-box style-1">
      <div className="card-media">
        <a href="#">
          <img src={image} alt={title} />
        </a>
        <span className="wishlist-button icon-heart"></span>
        <div className="button-place-bid">
          <Link to="/user/Content"className="tf-button">
            <span>Place Bid</span>
          </Link>
        </div>
      </div>
      <h5 className="name">
        <Link to="/user/Content">{title}</Link>
      </h5>
      <div className="author flex items-center">
        <div className="avatar">
          <img src={avatar} alt={authorName} />
        </div>
        <div className="info">
          <span>Created by:</span>
          <h6>
          <Link to="/user/Content">{authorName}</Link>
          </h6>
        </div>
      </div>
      <div className="divider"></div>
      <div className="meta-info flex items-center justify-between">
        <span className="text-bid">Current Bid</span>
        <h6 className="price gem">
          <i className="icon-gem"></i>
          {currentBid}
        </h6>
      </div>
    </div>
  );
};

export default CardItem;

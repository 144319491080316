import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom"; // Import useLocation
import HomeLayout from "../components/Layout/Home_layout"; // Import HomeLayout component
import TopWorks from "../components/Home/TopWorks";
import CreateSellNFT from "../components/Home/CreateSellNFT";
import NFTTokenGraph from "../components/Home/NFTTokenGraph";
import ActionSection from "../components/Home/ActionSection";
import HowToplay from "../components/Home/HowToplay";
import DiscoverCard from "../components/cards/DiscoverCard";
import TopGames from "../components/Home/TopGames";
import axios from "axios";
import NFTAudioPlayer from "../components/NFTAudioPlayer";
import Loader from "../components/Loader";
import ExploreGame from "../components/ExploreGame";

const User_Home = () => {
  const location = useLocation(); // Get the current location
  const [activeTab, setActiveTab] = useState("nft"); // State to manage active tab (default to 'nft')
  const [sortOption, setSortOption] = useState("recentlyAdded");

  const [loading, setLoading] = useState(true);
  const [NewcardsData, setCardsData] = useState([]);

  useEffect(() => {
    // Check URL hash and set the active tab accordingly
    const hash = location.hash.substring(1); // Remove the "#" character
    if (hash === "gaming") {
      setActiveTab("gaming");
    } else {
      setActiveTab("nft"); // Default to 'nft' tab
    }
  }, [location.hash]); // Run this effect whenever the URL hash changes

  useEffect(() => {
    // Fetch the NFT data from the API
    const fetchData = async () => {
      try {
        const token = localStorage.getItem('token');

        const response = await axios.get('https://www.blueblocksol.com:3000/api/nfts-range?start=1&end=24', {
          // headers: {
          //   Authorization: `Bearer ${token}`,
          // },
        });
        console.log(response.data.nfts);
        
        const transformedData = response.data.nfts.map((nft) => ({
          image: `https://www.blueblocksol.com:3000${nft.file_url}`,
          title: nft.name,
          authorName: `${nft.first_name} ${nft.last_name}`,
          avatar: "assets/images/avatar/avatar-box-03.jpg",
          price: nft.price,
          bid: nft.highest_bid,
          category: 'NFT Category',
          nftID:nft.nft_id
        }));

        setCardsData(transformedData);
      } catch (error) {
        console.error('Error fetching NFT data:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const handleTabChange = (tabName) => {
    setActiveTab(tabName);
    // Update the URL hash when a tab is clicked
    window.location.hash = tabName;
  };

  const sortedCardsData = [...NewcardsData].sort((a, b) => {
    switch (sortOption) {
      case "priceLowHigh":
        return parseFloat(a.bid) - parseFloat(b.bid);
      case "priceHighLow":
        return parseFloat(b.bid) - parseFloat(a.bid);
      case "recentlyAdded":
      default:
        return 0; // No sorting for default
    }
  });

  if (loading) {
    return <Loader />;
  }

  return (
    <HomeLayout>
      <div className="themesflat-container mobile-visible">
        <div className="row">
          <div className="col-md-12">
            <div id="site-header-inner">
              <div className="wrap-box flex">
                {/* Tab Navigation */}
                <ul className="widget-menu-tab">
                  <li
                    className={`item-title ${
                      activeTab === "nft" ? "active" : ""
                    }`}
                    onClick={() => handleTabChange("nft")}
                  >
                    <span className="inner">NFT</span>
                  </li>
                  <li
                    className={`item-title ${
                      activeTab === "gaming" ? "active" : ""
                    }`}
                    onClick={() => handleTabChange("gaming")}
                  >
                    <span className="inner">Gaming</span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Content Section */}
      <div className="tab-content">
        {/* NFT Home Page */}
        {activeTab === "nft" && (
          <>
            <TopWorks />
            {/* <CreateSellNFT /> */}
            <NFTTokenGraph />
            <ActionSection />
            {/* Sorting Dropdown */}
            <div className="tf-section-3 discover-item ">
              <div className="themesflat-container">
                {/* CardList displaying sorted cards */}
                <DiscoverCard cards={NewcardsData} />
              </div>
            </div>
          </>
        )}

        {/* Gaming Home Page */}
        {activeTab === "gaming" && (
          <>
            <TopGames />
            <ExploreGame />
            <ExploreGame />
            <ExploreGame />

            <HowToplay />
            {/* <NFTAudioPlayer
  audioUrl={`${process.env.PUBLIC_URL}/1.mp3`}
  image="assets/images/avatar/avatar-box-02.jpg"
  title="Unique Audio NFT"
  authorName="ArtistName"
  avatar="assets/images/avatar/avatar-box-02.jpg"
  bid="2.5 ETH"
/> */}

          </>
        )}
      </div>
    </HomeLayout>
  );
};

export default User_Home;

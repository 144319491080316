import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, EffectCoverflow } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/effect-coverflow';
import Countdown from '../../user_utils/Countdown';

const TopGames = () => {
    const slides = [
        {
          imgSrc: '/user/assets/images/game/1 (1).jpg',
          title: 'Jeckkpert',
          price: '0.34',
        },
        {
          imgSrc: '/user/assets/images/game/1 (2).jpg',
          title: 'Casino',
          price: '0.34',
        },
        {
          imgSrc: '/user/assets/images/game/1 (3).jpg',
          title: 'American Roulette',
          price: '0.34',
        },
        {
          imgSrc: '/user/assets/images/game/1 (4).jpg',
          title: 'Casino',
          price: '0.34',
        },
        {
          imgSrc: '/user/assets/images/game/1 (5).jpg',
          title: 'Casino',
          price: '0.34',
        },
        {
          imgSrc: '/user/assets/images/game/1 (6).jpg',
          title: 'Cards',
          price: '0.34',
        },
      
      ];

  return (
    
    <div className="flat-pages-title">
      <div className="widget-bg-line">
        <div className="wraper">
          <div className="bg-grid-line y bottom">
            <div className="bg-line"></div>
            
          </div>
        </div>
      </div>

      <div className="themesflat-container w1490">
        <div className="row">
          <div className="col-12 pages-title">
            <div className="content">
              <h1 className="wow fadeInUp">Top Games</h1>
              <p className="wow fadeInUp">
              Where Crypto Meets Entertainment Discover the Ultimate Crypto Gaming Experience with MetaBet Cash.


              </p>
              {/* <div className="flat-button flex justify-center">
                <a href="#" className="tf-button style-1 h50 w190 mr-16">
                  Discover more <i className="icon-arrow-up-right2"></i>
                </a>
                <a href="#" className="tf-button style-1 h50 w190 active">
                  All collections <i className="icon-arrow-up-right2"></i>
                </a>
              </div> */}
            </div>

            <div className="icon-background">
              <img className="absolute item1" src="assets/images/item-background/item1.png" alt="" />
              <img className="absolute item2" src="assets/images/item-background/item2.png" alt="" />
              <img className="absolute item3" src="assets/images/item-background/item3.png" alt="" />
              <img className="absolute item4" src="assets/images/item-background/item1.png" alt="" />
              <img className="absolute item5" src="assets/images/item-background/item1.png" alt="" />
              <img className="absolute item6" src="assets/images/item-background/item4.png" alt="" />
              <img className="absolute item7" src="assets/images/item-background/item5.png" alt="" />
              <img className="absolute item8" src="assets/images/item-background/item5.png" alt="" />
            </div>

            {/* Swiper */}
            <div className="relative">
              <Swiper
                loop={true}
                spaceBetween={30}
                slidesPerView={1}
                centeredSlides={true}
                freeMode={true}
                watchSlidesProgress={true}
                effect="coverflow"
                grabCursor={true}
                modules={[Navigation, Pagination, EffectCoverflow]}
                coverflowEffect={{
                  rotate: 15,
                  stretch: 90,
                  depth: 0,
                  modifier: 1,
                  scale: 0.9,
                  slideShadows: false,
                }}
                navigation={{
                  nextEl: '.next-3d',
                  prevEl: '.prev-3d',
                }}
                pagination={{
                  el: '.swiper-pagination',
                  clickable: true,
                  renderBullet: function (index, className) {
                    return '<span className="' + className + '">' + (index + 1) + '</span>';
                  },
                }}
                breakpoints={{
                  500: {
                    slidesPerView: 2,
                  },
                  991: {
                    slidesPerView: 3,
                  },
                  1024: {
                    slidesPerView: 3,
                  },
                  1200: {
                    slidesPerView: 4,
                  },
                  1400: {
                    slidesPerView: 5,
                  },
                }}
                className="swiper-3d-7"
              >
                {slides.map((slide, index) => (
                  <SwiperSlide key={index}>
                    <div className="tf-card-box">
                      <div className="card-media">
                        <a href="#">
                          <img src={slide.imgSrc} alt={slide.title} />
                        </a>
                        {/* <span className="wishlist-button icon-heart"></span> */}
                        {/* <div className="featured-countdown">
                        <Countdown
                            timer={3600} // 1 hour in seconds
                            labels={['h', 'm', 's']}
                            visibleLabels={['d', 'h', 'm']} // Only show days, hours, and minutes
                        />
                        </div> */}
                        <div className="button-place-bid">
                          <a href="/user/PlayGame" className="tf-button">
                            <span>Play</span>
                          </a>
                        </div>
                      </div>
                      <div className="meta-info text-center">
                        <h5 className="name">
                          <a>{slide.title}</a>
                        </h5>
                        {/* <h6 className="price gem">
                          <i className="icon-gem"></i>
                          {slide.price}
                        </h6> */}
                      </div>
                    </div>
                  </SwiperSlide>
                ))}
              </Swiper>
              <div className="swiper-pagination pagination-number"></div>
              <div className="swiper-button-next next-3d over"></div>
              <div className="swiper-button-prev prev-3d over"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TopGames;

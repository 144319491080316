import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/autoplay'; // Import necessary Swiper styles for Autoplay
import { Autoplay } from 'swiper/modules'; // Correctly import Autoplay module
import ParticleComponent from './ParticleComponent';
import { Link } from 'react-router-dom';

const ActionSection = () => {
  return (
    <div className="tf-section action">
      <div className="themesflat-container">
        <div className="row">
          <div className="col-md-12">
            <div className="action__body">
              <div className='tf-tsparticles'>
                <ParticleComponent />
              </div>

              <h2>Discover, create and sell your own NFT</h2>
              <div className="flat-button flex">
                <Link to='/user/ExploreNFT' className="tf-button style-2 h50 w190 mr-10">
                  Explore now
                  <i className="icon-arrow-up-right2"></i>
                </Link>
                <Link to='/user/userDashboard#create' className="tf-button style-2 h50 w230">
                  Create your first NFT
                  <i className="icon-arrow-up-right2"></i>
                </Link>
              </div>

              <div className='swiperHidemobile'>
                <div className="bg-home7">
                  {/* First Swiper */}
                  <Swiper
                    modules={[Autoplay]}
                    loop={true}
                    slidesPerView="auto"
                    spaceBetween={14}
                    direction="vertical"
                    speed={10000}
                    autoplay={{ delay: 0, disableOnInteraction: false }}
                    className="autoslider3reverse"
                  >
                    <SwiperSlide>
                      <img
                        src="assets/images/item-background/bg-action-1.png"
                        alt="Background Action 1"
                      />
                    </SwiperSlide>
                    <SwiperSlide>
                      <img
                        src="assets/images/item-background/bg-action-1.png"
                        alt="Background Action 1"
                      />
                    </SwiperSlide>
                  </Swiper>

                  {/* Second Swiper */}
                  <Swiper
                    modules={[Autoplay]}
                    loop={true}
                    slidesPerView="auto"
                    spaceBetween={14}
                    direction="vertical"
                    speed={10000}
                    autoplay={{
                      delay: 0,
                      disableOnInteraction: false,
                      reverseDirection: true,
                    }}
                    className="autoslider4reverse"
                  >
                    <SwiperSlide>
                      <img
                        src="assets/images/item-background/bg-action-1.png"
                        alt="Background Action 1"
                      />
                    </SwiperSlide>
                    <SwiperSlide>
                      <img
                        src="assets/images/item-background/bg-action-1.png"
                        alt="Background Action 1"
                      />
                    </SwiperSlide>
                  </Swiper>

                  {/* Third Swiper */}
                  <Swiper
                    modules={[Autoplay]}
                    loop={true}
                    slidesPerView="auto"
                    spaceBetween={14}
                    direction="vertical"
                    speed={10000}
                    autoplay={{ delay: 0, disableOnInteraction: false }}
                    className="autoslider3reverse"
                  >
                    <SwiperSlide>
                      <img
                        src="assets/images/item-background/bg-action-1.png"
                        alt="Background Action 1"
                      />
                    </SwiperSlide>
                    <SwiperSlide>
                      <img
                        src="assets/images/item-background/bg-action-1.png"
                        alt="Background Action 1"
                      />
                    </SwiperSlide>
                  </Swiper>
                </div>
              </div>
              </div>
            </div>
          </div>
        </div>
      </div>
  );
};

export default ActionSection;

import React, { useState } from 'react';
import axios from 'axios';
import Swal from 'sweetalert2'; // Import SweetAlert2

const CreateNft = () => {
  const [formData, setFormData] = useState({
    name: '',
    description: '',
    price: '',
    file: null,
    saleType: 'sell',
    startTime: '',
    endTime: '',
    initialBid: '',
  });

  // Initialize traits array with key, value, and percentage
  const [traits, setTraits] = useState([{ key: '', value: '', percentage: '' }]);
  const [preview, setPreview] = useState(null);
  const [loading, setLoading] = useState(false);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setFormData((prevData) => ({
      ...prevData,
      file: file,
    }));

    const reader = new FileReader();
    reader.onloadend = () => {
      setPreview(reader.result);
    };
    if (file) {
      reader.readAsDataURL(file);
    }
  };

  const handleClearForm = () => {
    setFormData({
      name: '',
      description: '',
      price: '',
      file: null,
      saleType: 'sale',
      startTime: '',
      endTime: '',
      initialBid: '',
    });
    setTraits([{ key: '', value: '', percentage: '' }]); // Reset traits with percentage
    setPreview(null);
  };

  const handleTraitsChange = (index, field, value) => {
    const newTraits = [...traits];
    newTraits[index][field] = value;
    setTraits(newTraits);
  };

  const addTraitField = () => {
    setTraits([...traits, { key: '', value: '', percentage: '' }]); // Add new field with percentage
  };

  const removeTraitField = (index) => {
    const newTraits = traits.filter((_, i) => i !== index);
    setTraits(newTraits);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    // Create metadata object
    const metadata = {
      name: formData.name,
      description: formData.description,
      traits: traits
        .filter((trait) => trait.key && trait.value)
        .map((trait) => ({
          key: trait.key,
          value: trait.value,
          percentage: trait.percentage ? parseFloat(trait.percentage) : null,
        })),
    };

    // Append metadata as JSON string

    const nftData = new FormData();
    nftData.append('name', formData.name);
    nftData.append('description', formData.description);
    nftData.append('price', formData.price);
    nftData.append('saleType', formData.saleType);
    nftData.append('metadata', JSON.stringify(metadata));


    if (formData.saleType === 'auction') {
      nftData.append('startTime', formData.startTime);
      nftData.append('endTime', formData.endTime);
      nftData.append('initialBid', formData.initialBid);
    }

    // Convert traits to JSON and append to form data
    const traitsJson = JSON.stringify(traits.filter(trait => trait.key && trait.value));
    nftData.append('traits', traitsJson);
    nftData.append('from', '0x796aF21E0A159C94F221Bee442f998086dC977e0');

    nftData.append('nftImage', formData.file);

    try {
      const token = localStorage.getItem('token');

      const response = await axios.post('https://www.blueblocksol.com:3000/api/nft-temp', nftData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          'Authorization': `Bearer ${token}`,
        },
      });
      setLoading(false);

      Swal.fire({
        icon: 'success',
        title: 'NFT Created!',
        text: 'Your NFT has been successfully created.',
      });

      handleClearForm();
    } catch (err) {
      setLoading(false);

      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'There was an error creating the NFT. Please try again.',
      });
    }
  };

  const downloadJsonFile = () => {
    // Create a JSON object with name, description, and traits
    const jsonObject = {
      name: formData.name,
      description: formData.description,
      traits: traits.filter(trait => trait.key && trait.value).map(trait => ({
        key: trait.key,
        value: trait.value,
        percentage: trait.percentage ? parseFloat(trait.percentage) : null, // Convert percentage to number if available
      })),
    };

    const traitsJson = JSON.stringify(jsonObject, null, 2);
    const blob = new Blob([traitsJson], { type: 'application/json' });
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.download = 'nft_data.json'; // You can rename the file as needed
    link.click();

    // Optional: Revoke the object URL after download to free up memory
    URL.revokeObjectURL(url);

    // Optional: Provide user feedback
    Swal.fire({
      icon: 'success',
      title: 'JSON Downloaded',
      text: 'Your NFT data has been downloaded as a JSON file.',
    });
  };

  const inputStyle = { color: 'white', backgroundColor: '#333' };

  // Calculate trait-based price
  const calculateTraitPrice = (basePrice, percentage) => {
    return (basePrice * (percentage / 100)).toFixed(2);
  };

  return (
    <div className="widget-content-tab">
      <div className="widget-content-inner description active">
        <div className="wrap-upload">
          <form className="h-full">
            <label className="uploadfile h-full flex items-center justify-center">
              <div className="text-center">
                {preview ? (
                  <img src={preview} alt="Preview" className="uploaded-img-preview" />
                ) : (
                  <>
                    <img src="assets/images/box-icon/upload.png" alt="upload icon" />
                    <h5>Upload file</h5>
                    <p className="text">Drag or choose your file to upload</p>
                    <div className="text filename">PNG, JPG, GIF Max 5Mb.</div>
                  </>
                )}
                <input
                  type="file"
                  name="file"
                  onChange={handleFileChange}
                  accept="image/png, image/gif, image/webp, video/mp4, audio/mp3"
                  style={inputStyle}
                />
              </div>
            </label>
          </form>
        </div>

        <div className="wrap-content w-full">
          <form onSubmit={handleSubmit} encType="multipart/form-data" className="comment-form">
            <fieldset className="name">
              <label>Product name *</label>
              <input
                type="text"
                id="name"
                placeholder="Product name"
                name="name"
                value={formData.name}
                onChange={handleInputChange}
                required
                style={inputStyle}
              />
            </fieldset>

            <fieldset className="message">
              <label>Description *</label>
              <textarea
                id="message"
                name="description"
                rows="4"
                placeholder="Please describe your product*"
                value={formData.description}
                onChange={handleInputChange}
                required
                style={inputStyle}
              ></textarea>
            </fieldset>

            <div className="flex gap30">
              <fieldset className="price" style={{ display: formData.saleType === 'auction' ? 'none' : 'block' }}>
                <label>Price</label>
                <input
                  type="text"
                  id="price"
                  placeholder="Price"
                  name="price"
                  value={formData.price}
                  onChange={handleInputChange}
                  required={formData.saleType === 'sell'}
                  style={inputStyle}
                />
              </fieldset>
            </div>

            <fieldset>
              <label>Sale Type *</label>
              <select
                name="saleType"
                value={formData.saleType}
                onChange={handleInputChange}
                style={inputStyle}
              >
                <option value="sale">Sell</option>
                <option value="auction">Auction</option>
              </select>
            </fieldset>

            {formData.saleType === 'auction' && (
              <>
                <fieldset className="start-time">
                  <label>Start Time *</label>
                  <input
                    type="datetime-local"
                    id="startTime"
                    name="startTime"
                    value={formData.startTime}
                    onChange={handleInputChange}
                    required
                    style={inputStyle}
                  />
                </fieldset>

                <fieldset className="end-time">
                  <label>End Time *</label>
                  <input
                    type="datetime-local"
                    id="endTime"
                    name="endTime"
                    value={formData.endTime}
                    onChange={handleInputChange}
                    required
                    style={inputStyle}
                  />
                </fieldset>

                <fieldset className="initial-bid">
                  <label>Initial Bid *</label>
                  <input
                    type="text"
                    id="initialBid"
                    placeholder="Initial Bid"
                    name="initialBid"
                    value={formData.initialBid}
                    onChange={handleInputChange}
                    required
                    style={inputStyle}
                  />
                </fieldset>
              </>
            )}

            <div className="traits-section">
              <label>Traits</label>
              {traits.map((trait, index) => (
                <div key={index} className="flex gap30 mb-3">
                  <input
                    type="text"
                    placeholder="Trait key"
                    value={trait.key}
                    onChange={(e) => handleTraitsChange(index, 'key', e.target.value)}
                    style={inputStyle}
                  />
                  <input
                    type="text"
                    placeholder="Trait value"
                    value={trait.value}
                    onChange={(e) => handleTraitsChange(index, 'value', e.target.value)}
                    style={inputStyle}
                  />
                  <input
                    type="text"
                    placeholder="Percentage (e.g. 1%)"
                    value={trait.percentage}
                    onChange={(e) => handleTraitsChange(index, 'percentage', e.target.value)}
                    style={inputStyle}
                  />
                  <div>
                    {formData.price && trait.percentage && (
                      <span>Price: {calculateTraitPrice(formData.price, parseFloat(trait.percentage))} ETH</span>
                    )}
                  </div>
                  <button type="button" onClick={() => removeTraitField(index)}>-</button>
                </div>
              ))}
              <button type="button" onClick={addTraitField}>+</button>
            </div>

            <div className="btn-submit flex gap30 justify-center">
              <button className="tf-button style-1 h50" disabled={loading} type="submit">
                {loading ? 'Creating NFT...' : 'Create NFT'}
              </button>
              <button
                type="button"
                className="tf-button style-1 h50"
                onClick={handleClearForm}
                disabled={loading}
              >
                Clear
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default CreateNft;

// Login.jsx
import React, { useState } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import axios from 'axios';
import Swal from 'sweetalert2';
import { decodeJWT } from '../../utils/jwtUtils'; // Import the utility function to decode JWT

const Login = () => {
  const navigate = useNavigate();
  const location = useLocation(); // Get the location state
  const [formData, setFormData] = useState({
    email: '',
    password: '',
  });
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false); // State to control password visibility

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError('');

    try {
      const response = await axios.post('https://www.blueblocksol.com:3000/api/auth/login', {
        email: formData.email,
        password: formData.password,
      });

      const token = response.data.token;
      localStorage.setItem('token', token);

      const decodedToken = decodeJWT(token); // Decode the JWT to get the role

      if (!decodedToken) {
        throw new Error('Failed to decode token');
      }

      const userRole = decodedToken.Role; // Assuming the role is stored as 'Role' in the token
      console.log(decodedToken);

      if (userRole === 'admin') {
        Swal.fire({
          title: 'Admin Access Detected',
          text: 'Would you like to go to the admin dashboard or the user dashboard?',
          icon: 'question',
          showCancelButton: true,
          confirmButtonText: 'Admin Dashboard',
          cancelButtonText: 'User Dashboard',
        }).then((result) => {
          if (result.isConfirmed) {
            navigate('/admin/Home'); // Navigate to admin page
          } else {

            const redirectTo = location.state?.from?.pathname || '/user/Home'; // Get the path from state or default to user home
            navigate(redirectTo, { replace: true }); // Redirect to the intended page or home
              }
        });
      } else {
        Swal.fire({
          title: 'Login Successful!',
          text: 'You have been logged in successfully.',
          icon: 'success',
          timer: 2000,
          showConfirmButton: false,
        });

        const redirectTo = location.state?.from?.pathname || '/user/Home'; // Get the path from state or default to user home
        navigate(redirectTo, { replace: true }); // Redirect to the intended page or home
      }
    } catch (error) {
      setError('Invalid email or password');
    } finally {
      setLoading(false);
    }
  };

  const togglePasswordVisibility = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };

  // Custom style to be applied to all input fields
  const inputStyle = { color: 'white', backgroundColor: '#333' };

  return (
    <div
      className="tf-section-2 pt-60 widget-box-icon"
      style={{
        backgroundImage: `url(${process.env.PUBLIC_URL}/user/assets/images/bg.jpg)`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        minHeight: '100vh',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <div
        className="themesflat-container w920"
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          width: '100%',
          maxWidth: '600px',
          padding: '20px',
          borderRadius: '8px',
          boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
        }}
      >
        <div className="heading-section-1" style={{ textAlign: 'center'}}>
          <h2 className="tf-title mb-2">Login</h2>
          <p className="mb-3">Get started today by entering just a few details</p>
        </div>
        <div
          className="widget-login"
          style={{
            width: '100%',
            backgroundColor: 'rgba(27, 27, 27, 0.8)', 
            padding: '20px',
            borderRadius: '8px',
            boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
          }}
        >
          <form onSubmit={handleSubmit} id="commentform" className="comment-form">
            <fieldset className="email" style={{ marginBottom: '10px' }}>
              <label>Email *</label>
              <input
                type="email"
                id="email"
                placeholder="mail@website.com"
                name="email"
                value={formData.email}
                onChange={handleInputChange}
                aria-required="true"
                required
                style={inputStyle} // Apply custom style
              />
            </fieldset>
            <fieldset className="password" style={{ marginBottom: '10px', position: 'relative' }}>
              <label>Password *</label>
              <input
                className="password-input"
                type={showPassword ? 'text' : 'password'} // Toggle between 'text' and 'password' types
                id="password"
                placeholder="Min. 8 characters"
                name="password"
                value={formData.password}
                onChange={handleInputChange}
                aria-required="true"
                required
                style={inputStyle} // Apply custom style
              />
              <i 
                className={`icon-show password-addon ${showPassword ? 'icon-eye-open' : 'icon-eye-closed'}`} 
                id="password-addon"
                onClick={togglePasswordVisibility}
                style={{ position: 'flex', right: '10px', top: '35px', cursor: 'pointer', color: 'white' }} // Icon color
              ></i>
              <div className="forget-password">
                <a href="#" style={{ color: 'white' }}>Forget password</a>
              </div>
            </fieldset>

            {error && <p style={{ color: 'red' }}>{error}</p>}

            <div className="btn-submit mb-30">
              <button className="tf-button style-1 h50 w-100" type="submit" disabled={loading}>
                {loading ? 'Logging in...' : 'Login'}
                <i className="icon-arrow-up-right2"></i>
              </button>
            </div>
          </form>
          <div className="no-account">
            Don't have an account?{' '}
            <Link to="/register" className="tf-color" style={{ color: 'white' }}>
              Sign up
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;

import React, { useEffect, useState } from 'react';
import CardList from '../cards/CardList';
import axios from 'axios';
import Loader from '../Loader';
import { Link } from 'react-router-dom';

const Market = () => {
    const [loading, setLoading] = useState(true);
    const [NewcardsData, setCardsData] = useState([]);
  
    useEffect(() => {
      // Fetch the NFT data from the API
      const fetchData = async () => {
        try {
          // Get the token from local storage
          const token = localStorage.getItem('token');
  
          // Make the API request
          const response = await axios.get('https://www.blueblocksol.com:3000/api/nfts-range?start=1&end=12', {
            headers: {
              Authorization: `Bearer ${token}`, // Pass the token in the Authorization header
            },
          });
  
          // Transform the API data to match the required structure
          const transformedData = response.data.nfts.map((nft) => ({
            image: `https://www.blueblocksol.com:3000${nft.file_url}`, // Assuming file_url gives the image path, prefixing with the base URL
            title: nft.name, // Using name field for title
            authorName: `${nft.first_name} ${nft.last_name}`, // Concatenating first_name and last_name for the author name
            avatar: "assets/images/avatar/avatar-box-03.jpg",
            bid: nft.price, // Assuming price represents the current bid
            category: 'NFT Category', // Placeholder for category (if you have a field for category, replace it)
          }));
          // Update the cardsData state with the transformed data
          setCardsData(transformedData);
        } catch (error) {
          console.error('Error fetching NFT data:', error);
        } finally {
          setLoading(false);
        }
      };
  
      fetchData();
    }, []);
  
    if (loading) {
        return <Loader />;
    }
    const cardsData = [
        {
          image: "assets/images/box-item/card-item-53.jpg",
          title: "Dayco serpentine belt",
          authorName: "Marvin McKinney",
          avatar: "assets/images/avatar/avatar-box-03.jpg",
          bid: "0.34",
          category: "Gaming",
        },
        {
          image: "assets/images/box-item/card-item-53.jpg",
          title: "Artistic Painting",
          authorName: "Leslie Alexander",
          avatar: "assets/images/avatar/avatar-box-05.jpg",
          bid: "1.2",
          category: "Art",
        },
        {
          image: "assets/images/box-item/card-item-53.jpg",
          title: "Dayco serpentine belt",
          authorName: "Marvin McKinney",
          avatar: "assets/images/avatar/avatar-box-03.jpg",
          bid: "0.34",
          category: "Gaming",
        },
        {
          image: "assets/images/box-item/card-item-53.jpg",
          title: "Artistic Painting",
          authorName: "Leslie Alexander",
          avatar: "assets/images/avatar/avatar-box-05.jpg",
          bid: "1.2",
          category: "Art",
        },
        {
          image: "assets/images/box-item/card-item-53.jpg",
          title: "Dayco serpentine belt",
          authorName: "Marvin McKinney",
          avatar: "assets/images/avatar/avatar-box-03.jpg",
          bid: "0.34",
          category: "Gaming",
        },
        {
          image: "assets/images/box-item/card-item-53.jpg",
          title: "Artistic Painting",
          authorName: "Leslie Alexander",
          avatar: "assets/images/avatar/avatar-box-05.jpg",
          bid: "1.2",
          category: "Art",
        },
        {
          image: "assets/images/box-item/card-item-53.jpg",
          title: "Dayco serpentine belt",
          authorName: "Marvin McKinney",
          avatar: "assets/images/avatar/avatar-box-03.jpg",
          bid: "0.34",
          category: "Gaming",
        },
        {
          image: "assets/images/box-item/card-item-53.jpg",
          title: "Artistic Painting",
          authorName: "Leslie Alexander",
          avatar: "assets/images/avatar/avatar-box-05.jpg",
          bid: "1.2",
          category: "Art",
        },
        // Add more card objects here...
      ];
    return (
        <>
        <div className='wrapper-content'>
        <div className='inner-content mt-5'>
<CardList cards={cardsData}/></div>
<div className="side-bar">
                                <div className="widget widget-recently">
                                    <h5 className="title-widget">Recently added</h5>
                                    <div className="card-small-main">
                                        <img src="assets/images/blog/sidebar-05.jpg" alt="" />
                                        <div className="card-bottom">
                                            <h5><Link to="/user/Content">Photography</Link></h5>
                                            <span className="date">16hr ago</span>
                                        </div>
                                    </div>
                                    <div className="card-small">
                                        <div className="author">
                                            <img src="assets/images/blog/sidebar-06.jpg" alt="" />
                                            <div className="info">
                                                <h6><Link to="/user/Content">Propw</Link></h6>
                                                <p><Link to="/user/Content">@themes</Link></p>
                                            </div>
                                        </div>
                                        <span className="date">Mon, 08 May </span>
                                    </div>
                                    <div className="card-small">
                                        <div className="author">
                                            <img src="assets/images/blog/sidebar-07.jpg" alt="" />
                                            <div className="info">
                                                <h6><Link to="/user/Content">Propw</Link></h6>
                                                <p><Link to="/user/Content">@themes</Link></p>
                                            </div>
                                        </div>
                                        <span className="date">Mon, 08 May </span>
                                    </div>
                                    <div className="card-small">
                                        <div className="author">
                                            <img src="assets/images/blog/sidebar-08.jpg" alt="" />
                                            <div className="info">
                                                <h6><Link to="/user/Content">Propw</Link></h6>
                                                <p><Link to="/user/Content">@themes</Link></p>
                                            </div>
                                        </div>
                                        <span className="date">Mon, 08 May </span>
                                    </div>
                                </div>
                                <div className="widget widget-creators">
                                    <div className="flex items-center justify-between">
                                        <h5 className="title-widget">Top Creators</h5>
                                        <a className="see-all" href="#">See all</a>
                                    </div>
                                    <div className="widget-creators-item flex items-center mb-20">
                                        <div className="order">1. </div>
                                        <div className="author flex items-center flex-grow">
                                            <img src="assets/images/avatar/avatar-small-01.png" alt="" />
                                            <div className="info">
                                                <h6><Link to="/user/Content">Brooklyn Simmons</Link></h6>
                                                <span><Link to="/user/Content">@themes</Link></span>
                                            </div>
                                        </div>
                                        <button className="follow">Follow</button>
                                    </div>
                                    <div className="widget-creators-item flex items-center mb-20">
                                        <div className="order">2. </div>
                                        <div className="author flex items-center flex-grow">
                                            <img src="assets/images/avatar/avatar-small-02.png" alt="" />
                                            <div className="info">
                                                <h6><Link to="/user/Content">Brooklyn Simmons</Link></h6>
                                                <span><Link to="/user/Content">@themes</Link></span>
                                            </div>
                                        </div>
                                        <button className="follow">Follow</button>
                                    </div>
                                    <div className="widget-creators-item flex items-center mb-20">
                                        <div className="order">3. </div>
                                        <div className="author flex items-center flex-grow">
                                            <img src="assets/images/avatar/avatar-small-03.png" alt="" />
                                            <div className="info">
                                                <h6><Link to="/user/Content">Brooklyn Simmons</Link></h6>
                                                <span><Link to="/user/Content">@themes</Link></span>
                                            </div>
                                        </div>
                                        <button className="follow">Follow</button>
                                    </div>
                                    <div className="widget-creators-item flex items-center mb-20">
                                        <div className="order">4. </div>
                                        <div className="author flex items-center flex-grow">
                                            <img src="assets/images/avatar/avatar-small-04.png" alt="" />
                                            <div className="info">
                                                <h6><Link to="/user/Content">Brooklyn Simmons</Link></h6>
                                                <span><Link to="/user/Content">@themes</Link></span>
                                            </div>
                                        </div>
                                        <button className="follow">Follow</button>
                                    </div>
                                    <div className="widget-creators-item flex items-center">
                                        <div className="order">5. </div>
                                        <div className="author flex items-center flex-grow">
                                            <img src="assets/images/avatar/avatar-small-01.png" alt="" />
                                            <div className="info">
                                                <h6><Link to="/user/Content">Brooklyn Simmons</Link></h6>
                                                <span><Link to="/user/Content">@themes</Link></span>
                                            </div>
                                        </div>
                                        <button className="follow">Follow</button>
                                    </div>
                                </div>
                                <div className="widget widget-coins">
                                    <h5 className="title-widget">Trending coins</h5>
                                    <div className="widget-coins-item flex items-center mb-20">
                                        <img src="assets/images/box-icon/coin-01.png" alt="" />
                                        <p><Link to="/user/Content">Bitcoin</Link></p>
                                    </div>
                                    <div className="widget-coins-item flex items-center mb-20">
                                        <img src="assets/images/box-icon/coin-02.png" alt="" />
                                        <p><Link to="/user/Content">Ethereum</Link></p>
                                    </div>
                                    <div className="widget-coins-item flex items-center mb-20">
                                        <img src="assets/images/box-icon/coin-03.png" alt="" />
                                        <p><Link to="/user/Content">Cardano</Link></p>
                                    </div>
                                    <div className="widget-coins-item flex items-center mb-20">
                                        <img src="assets/images/box-icon/coin-04.png" alt="" />
                                        <p><Link to="/user/Content">Solana</Link></p>
                                    </div>
                                    <div className="widget-coins-item flex items-center">
                                        <img src="assets/images/box-icon/coin-05.png" alt="" />
                                        <p><Link to="/user/Content">Litecoin</Link></p>
                                    </div>
                                </div>
                                <div className="widget widget-history">
                                    <div className="flex items-center justify-between">
                                        <h5 className="title-widget">History</h5>
                                        <a className="see-all" href="#">See all</a>
                                    </div>
                                    <div className="widget-creators-item flex items-center mb-20">
                                        <div className="author flex items-center flex-grow">
                                            <img src="assets/images/avatar/avatar-small-01.png" alt="" />
                                            <div className="info">
                                                <h6><Link to="/user/Content">Lorem NFT sold</Link></h6>
                                                <span><Link to="/user/Content">Sold at 1.32 ETH</Link></span>
                                            </div>
                                        </div>
                                        <span className="time">Just now</span>
                                    </div>
                                    <div className="widget-creators-item flex items-center mb-20">
                                        <div className="author flex items-center flex-grow">
                                            <img src="assets/images/avatar/avatar-small-02.png" alt="" />
                                            <div className="info">
                                                <h6><Link to="/user/Content">New NFT uploaded</Link></h6>
                                                <span><Link to="/user/Content">By Marisol Pena</Link></span>
                                            </div>
                                        </div>
                                        <span className="time">1hr ago</span>
                                    </div>
                                    <div className="widget-creators-item flex items-center mb-20">
                                        <div className="author flex items-center flex-grow">
                                            <img src="assets/images/avatar/avatar-small-03.png" alt="" />
                                            <div className="info">
                                                <h6><Link to="/user/Content">You followed a creator</Link></h6>
                                                <span><Link to="/user/Content">Jane Cooper</Link></span>
                                            </div>
                                        </div>
                                        <span className="time">2hr ago</span>
                                    </div>
                                    <div className="widget-creators-item flex items-center mb-20">
                                        <div className="author flex items-center flex-grow">
                                            <img src="assets/images/avatar/avatar-small-04.png" alt="" />
                                            <div className="info">
                                                <h6><Link to="/user/Content">You placed a bid</Link></h6>
                                                <span><Link to="/user/Content">Whirl wind NFT</Link></span>
                                            </div>
                                        </div>
                                        <span className="time">4hr ago</span>
                                    </div>
                                    <div className="widget-creators-item flex items-center">
                                        <div className="author flex items-center flex-grow">
                                            <img src="assets/images/avatar/avatar-small-01.png" alt="" />
                                            <div className="info">
                                                <h6><Link to="/user/Content">You followed a creator</Link></h6>
                                                <span><Link to="/user/Content">Courtney Henry</Link></span>
                                            </div>
                                        </div>
                                        <span className="time">16hr ago</span>
                                    </div>
                                </div>
                            </div>
                            </div>

                            </>
    );
};
export default Market;

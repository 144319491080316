import React, { useState } from 'react';
import { Link } from 'react-router-dom';

const Sidebar = () => {
  const [openMenu, setOpenMenu] = useState(null);

  const handleToggleMenu = (menuName) => {
    setOpenMenu(openMenu === menuName ? null : menuName);
  };

  return (
    <aside id="layout-menu" className="layout-menu menu-vertical menu bg-menu-theme ">
      <div className="app-brand demo">
        <Link to="/user/Home" className="app-brand-link">
        <span className="app-brand-text demo menu-text fw-bold">
  <img src={`${process.env.PUBLIC_URL}/CRYPTOMART.png`} alt="MetaGaming Logo" style={{ height: '20px' }} />
</span>

        </Link>

        <button className="layout-menu-toggle menu-link text-large ms-auto btn btn-link">
          <i className="ti menu-toggle-icon d-none d-xl-block align-middle"></i>
          <i className="ti ti-x d-block d-xl-none ti-md align-middle"></i>
        </button>
      </div>

      <div className="menu-inner-shadow"></div>

      <ul className="menu-inner py-1">
        {/* Dashboards */}
        <li className={`menu-item ${openMenu === 'dashboards' ? 'active open' : ''}`}>
          <button
            className="menu-link menu-toggle btn btn-link"
            onClick={() => handleToggleMenu('dashboards')}
          >
            <i className="menu-icon tf-icons ti ti-smart-home"></i>
            <div data-i18n="Dashboards">Dashboards</div>
            <div className="badge bg-danger rounded-pill ms-auto">5</div>
          </button>
          <ul className={`menu-sub ${openMenu === 'dashboards' ? 'show' : ''}`}>
            <li className="menu-item active">
              <Link to="/admin/Home" className="menu-link">
                <div data-i18n="Home">Home</div>
              </Link>
            </li>
            <li className="menu-item">
              <Link to="/admin/Deposit" className="menu-link">
                <div data-i18n="CRM">Deposit</div>
              </Link>
            </li>
            <li className="menu-item">
              <Link to="/admin/Games" className="menu-link">
                <div data-i18n="Games">Games</div>
              </Link>
            </li>
            <li className="menu-item">
              <Link to="/admin/Users" className="menu-link">
                <div data-i18n="Users">Users</div>
              </Link>
            </li>
            <li className="menu-item">
              <Link to="/admin/CryptoRequests" className="menu-link">
                <div data-i18n="Logistics">Crypto Requests</div>
              </Link>
            </li>
            <li className="menu-item">
              <Link to="/admin/Transactions" className="menu-link">
                <div data-i18n="Logistics">All Transactions</div>
              </Link>
            </li>
            <li className="menu-item">
              <Link to="/admin/GameActivities" className="menu-link">
                <div data-i18n="Academy">Game Activities</div>
              </Link>
            </li>
            <li className="menu-item">
              <Link to="/admin/NFT" className="menu-link">
                <div data-i18n="Academy">NFT</div>
              </Link>
            </li>
          </ul>
        </li>

       
      </ul>
    </aside>
  );
};

export default Sidebar;

import React, { useState, useEffect } from 'react';
import Layout from '../components/Layout'; // Import the Layout component
import Loader from '../components/Loader'; // Import the Loader component
import DataTable from 'react-data-table-component'; // Import DataTable directly
import Swal from 'sweetalert2'; // Import Swal for alerts
import axios from 'axios'; // Import axios for API calls

const Deposit = () => {
  const [loading, setLoading] = useState(true);
  const [deposits, setDeposits] = useState([]); // State to store deposit data
  const [error, setError] = useState(null); // State to store any errors

  useEffect(() => {
    const fetchDeposits = async () => {
      try {
        // API call to fetch all deposit transactions
        const response = await axios.get('https://www.blueblocksol.com:3000/users/user/all-deposit-transactions');
        
        setDeposits(response.data); // Store the fetched deposits in state
      } catch (error) {
        setError(error.message);
        Swal.fire('Error', 'Failed to fetch deposit transactions from the server', 'error');
      } finally {
        setLoading(false); // Set loading to false whether the call is successful or an error occurs
      }
    };

    fetchDeposits(); // Fetch deposits on component mount
  }, []);

  const columns = [
    { name: 'Transaction ID', selector: (row) => row.transaction_id, sortable: true },
    { name: 'User ID', selector: (row) => row.user_id, sortable: true },
    { name: 'Amount', selector: (row) => `$${parseFloat(row.amount).toFixed(2)}`, sortable: true },
    { name: 'Status', selector: (row) => row.status, sortable: true },
    { 
      name: 'Date', 
      selector: (row) => new Date(row.transaction_date).toLocaleString('en-US', {
        dateStyle: 'medium',
        timeStyle: 'short',
      }), 
      sortable: true 
    },
  ];

  return (
    <Layout loading={loading}> {/* Pass the loading state to the Layout component */}
      {loading ? (
        <Loader /> // Display loader when loading is true
      ) : error ? (
        <div className="error-message">{error}</div> // Display error message if any
      ) : (
        <div className="card">
          <h5 className="card-header">Deposit Transactions</h5>
          <div className="card-datatable text-nowrap">
            <DataTable
              columns={columns}
              data={deposits}
              pagination
              highlightOnHover
              striped
            />
          </div>
        </div>
      )}
    </Layout>
  );
};

export default Deposit;

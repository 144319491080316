// PrivateRoute.jsx
import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';

const PrivateRoute = ({ element }) => {
  const token = localStorage.getItem('token'); // Check if a token exists
  const location = useLocation(); // Capture the current location

  return token ? element : <Navigate to="/user/login" state={{ from: location }} replace />; // Redirect to login with the original path
};

export default PrivateRoute;

import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Swal from 'sweetalert2'; // Import SweetAlert2
import WalletModal from './sidebar/WalletModal'; // Import your WalletModal component

const ConnectWalletButton = () => {
  const [walletAddress, setWalletAddress] = useState(null);
  const [isConnected, setIsConnected] = useState(false); // State to track connection status
  const [isModalOpen, setIsModalOpen] = useState(false); // State for modal visibility

  useEffect(() => {
    // Check localStorage for token on component mount
    const token = localStorage.getItem('token');
    if (token) {
      setIsConnected(true);
    }
  }, []);

  // Function to open the modal
  const openModal = () => {
    setIsModalOpen(true);
  };

  // Function to close the modal
  const closeModal = () => {
    setIsModalOpen(false);
  };

  // Modified function to handle wallet connection
  const handleConnectWallet = async () => {
    // Check if MetaMask is installed
    if (window.ethereum) {
      try {
        const accounts = await window.ethereum.request({ method: 'eth_requestAccounts' });
        const connectedWalletAddress = accounts[0];
        setWalletAddress(connectedWalletAddress);

        // Check if the wallet address exists in the system
        await checkWalletAddress(connectedWalletAddress);

        // If the wallet is connected successfully, display a success message
        if (isConnected) {
          Swal.fire('Connected!', `Application connected to your wallet address: ${connectedWalletAddress}`, 'success');
        } else {
          // If no connection, show the modal to register or connect a wallet
          openModal();
        }
      } catch (error) {
        console.error('User denied account access or error occurred:', error);
        Swal.fire('Error', 'User denied account access or an error occurred.', 'error');
      }
    } else {
      // If MetaMask is not installed
      Swal.fire('MetaMask Required', 'MetaMask is not installed. Please install it to use this feature.', 'warning');
    }
  };

  const checkWalletAddress = async (address) => {
    try {
      const response = await axios.post('https://www.blueblocksol.com:3000/api/auth/check-wallet', { walletAddress: address });
      const { token } = response.data;

      if (token) {
        localStorage.setItem('token', token);
        setIsConnected(true); // Update connection status
      } else {
        Swal.fire('Not Found', 'Wallet address not found in our system.', 'warning');
        setIsConnected(false);
      }
    } catch (error) {
      console.error('Error checking wallet address:', error);
      Swal.fire('Not Found', 'Wallet address not found in our system.', 'warning');
      setIsConnected(false);
    }
  };

  return (
    <div id="wallet-header">
      <span
        onClick={openModal} // Trigger wallet check and possible modal open here
        id="connectbtn"
        className="tf-button style-1"
      >
        <span>
          {isConnected ? 'connected' : 'Login'}
        </span>
        <i className="icon-wa"></i>
      </span>

      {/* Wallet Modal */}
      <WalletModal
        isOpen={isModalOpen}
        onClose={closeModal}
      />
    </div>
  );
};

export default ConnectWalletButton;

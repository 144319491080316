import React, { useState, useEffect } from 'react';
import Sidebar from '../sidebar/Dashboard_sidebar';
import CustomCursor from '../CustomCursor';
import Dashboard_header from '../header/Dashboard_header';
import Footer from '../Footer/Footer';
import CreateNft from '../Dashboard/CreateNft';
import DashboardCards from '../Profile/DashboardCards';
import TransactionTable from '../Profile/TransactionTable';
import MyReferral from '../Profile/MyReferral';
import GameHistory from '../Profile/GameHistory';
import DepositHistory from '../Profile/DepositHistory';
import RequestMetacoins from '../Profile/RequestMetacoins';
import UpdateProfile from '../Profile/UpdateProfile';
import WithdrawRequest from '../Profile/WithdrawRequest';
import Market from '../Dashboard/Market';
import ProfitLossGraph from '../Profile/ProfitLossGraph';
import DummyProfitLossGraph from '../Profile/DummyProfitLossGraph';
import NFTList from '../Profile/NFTList';
import BidList from '../Profile/BidList';

const Dashboard_layout = () => {
  const [activeComponent, setActiveComponent] = useState('wallet');
  const [isNullClassActive, setNullClassActive] = useState(false);

  useEffect(() => {
    // Check if the screen size is mobile or desktop
    const handleResize = () => {
      const isMobile = window.innerWidth <= 768;
      setNullClassActive(!isMobile); // Collapse sidebar for both mobile and desktop views initially
    };

    handleResize(); // Run on initial load
    window.addEventListener('resize', handleResize); // Add event listener for resize

    return () => window.removeEventListener('resize', handleResize); // Clean up on unmount
  }, []);

  const getHeader = () => {
    switch (activeComponent) {
      case 'create':
        return 'Create Your NFT';
      case 'wallet':
        return 'Wallet Overview';
      case 'transaction':
        return 'Transaction History';
      case 'referral':
        return 'My Referrals';
      case 'Game-history':
        return 'Game History';
      case 'NFT-List':
        return 'NFT List';
      case 'Bid-List':
        return 'Bid List';
      case 'deposit-history':
        return 'Deposit History';
      case 'request-metacoins':
        return 'Crypto Requests';
      case 'withdraw-request':
        return 'Withdraw Request';
      case 'update-profile':
        return 'Update Profile';
      default:
        return 'Dashboard';
    }
  };

  const renderComponent = () => {
    switch (activeComponent) {
      case 'create':
        return <CreateNft />;
      case 'wallet':
        return (
          <>
            <DashboardCards />
            <DummyProfitLossGraph />
          </>
        );
      case 'transaction':
        return <TransactionTable />;
      case 'referral':
        return <MyReferral />;
      case 'Game-history':
        return <GameHistory />;
      case 'deposit-history':
        return <DepositHistory />;
      case 'request-metacoins':
        return <RequestMetacoins />;
      case 'NFT-List':
        return <NFTList />;
      case 'Bid-List':
        return <BidList />;
      case 'withdraw-request':
        return <WithdrawRequest />;
      case 'update-profile':
        return <UpdateProfile />;
      default:
        return <Market />;
    }
  };

  const toggleCanvas = () => {
    setNullClassActive(!isNullClassActive); // Toggle the state between true and false
  };

  const handleMouseLeaveSidebar = () => {
    toggleCanvas(); // Trigger toggle on mouse leave
  };

  return (
    <div id="wrapper">
      <div id="page" className={`market-page ${isNullClassActive ? 'full' : ''}`}>
        <Dashboard_header />
        <div
          className={`btn-canvas ${isNullClassActive ? '' : 'active'}`}
          onClick={toggleCanvas}
          onMouseEnter={toggleCanvas}
        >
          <div className={`canvas`}>
            <span></span>
          </div>
        </div>
        <div className="flat-tabs">
          <Sidebar
            isNullClassActive={isNullClassActive}
            setActiveComponent={setActiveComponent}
            onMouseLeaveSidebar={handleMouseLeaveSidebar}
          />

          <div className="content-tabs">
            <h2 className="mb-5">{getHeader()}</h2>
            {renderComponent()}
          </div>
          <Footer />
          <CustomCursor />
        </div>
      </div>
    </div>
  );
};

export default Dashboard_layout;

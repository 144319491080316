import React, { useState, useEffect } from 'react';
import Swal from 'sweetalert2';
import axios from 'axios';
import { useUser } from '../../../context/UserContext'; // Import the useUser hook from your context

const WithdrawRequest = () => {
    const [formData, setFormData] = useState({
        walletAddress: '',
        amount: '',
    });

    const { balance } = useUser(); // Access balances from context

    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchUserDetails = async () => {
            try {
                const token = localStorage.getItem('token');
                if (!token) {
                    Swal.fire({ icon: 'error', title: 'Authorization Error', text: 'Authorization token is missing.' });
                    return;
                }

                const response = await axios.get('https://www.blueblocksol.com:3000/users/user/details', {
                    headers: { Authorization: `Bearer ${token}` },
                });

                if (response.status === 200) {
                    setFormData((prevData) => ({ ...prevData, walletAddress: response.data.wallet_address }));
                    setLoading(false);
                } else {
                    Swal.fire({ icon: 'error', title: 'Error', text: 'Failed to fetch user details.' });
                }
            } catch (error) {
                Swal.fire({ icon: 'error', title: 'Error', text: 'Error fetching user details: ' + (error.response?.data?.message || error.message) });
                console.error('Error:', error.message);
            }
        };

        fetchUserDetails();
    }, []);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        const token = localStorage.getItem('token');
        if (!token) {
            Swal.fire({ icon: 'error', title: 'Authorization Error', text: 'Authorization token is missing.' });
            return;
        }

        // Check if the requested amount exceeds the user's balance
        const requestedAmount = parseFloat(formData.amount);
        if (requestedAmount > balance) {
            Swal.fire({
                icon: 'error',
                title: 'Insufficient Balance',
                text: `You cannot withdraw more than your current balance. Your balance is ${balance} MTG.`,
            });
            return; // Stop the submission if the amount exceeds the balance
        }

        try {
            const response = await axios.post(
                'https://www.blueblocksol.com:3000/api/crypto/withdraw', // Update to your actual API URL
                {
                    walletAddress: formData.walletAddress,
                    tokenAmount: formData.amount,
                },
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            );

            if (response?.status === 200 || response?.status === 201) {
                Swal.fire({
                    icon: 'success',
                    title: 'Withdrawal Successful',
                    text: `Successfully withdrew ${formData.amount} MTG.`,
                });
            } else {
                Swal.fire({
                    icon: 'error',
                    title: 'Withdrawal Failed',
                    text: 'Failed to process your withdrawal request. Please try again.',
                });
            }
        } catch (error) {
            Swal.fire({
                icon: 'error',
                title: 'Error',
                text: 'Error submitting withdrawal request: ' + (error.response?.data?.message || error.message),
            });
            console.error('Error:', error.message);
        }
    };

    return (
        <div className="container my-5">
            <div className="row justify-content-center">
                <div className="col-xl-6">
                    <div className="card p-4 mt-5">
                        <form onSubmit={handleSubmit}>
                            <h3 className="mb-4" style={{ textAlign: 'left' }}>Withdraw Request</h3>
                            {loading ? (
                                <p>Loading...</p>
                            ) : (
                                <div className="row gy-3">
                                    <div className="col-12">
                                        <div className="form-group">
                                            <label htmlFor="walletAddress">Wallet Address</label>
                                            <input
                                                id="walletAddress"
                                                type="text"
                                                className="form-control"
                                                placeholder="Wallet Address"
                                                name="walletAddress"
                                                value={formData.walletAddress}
                                                onChange={handleInputChange}
                                                readOnly
                                                required
                                            />
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="form-group">
                                            <label htmlFor="amount">Amount</label>
                                            <input
                                                id="amount"
                                                type="number"
                                                className="form-control"
                                                placeholder="Amount"
                                                name="amount"
                                                value={formData.amount}
                                                onChange={handleInputChange}
                                                required
                                            />
                                        </div>
                                    </div>
                                    <div className="col-12 d-flex justify-content-center">
                                        <button type="submit" className="btn btn-primary mt-3">
                                            Submit Withdraw Request
                                        </button>
                                    </div>
                                </div>
                            )}
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default WithdrawRequest;

import React, { useEffect, useState } from 'react';
import DataTable from 'react-data-table-component';
import axios from 'axios'; // Ensure axios is installed
import { useUser } from '../../../context/UserContext'; // Assuming you have a UserContext to get the user info
import Swal from 'sweetalert2'; // Import Swal for alerts

const BidList = () => {
  const [bids, setBids] = useState([]);
  const [loading, setLoading] = useState(true);
  const { user } = useUser(); // Assuming the user object has the userId

  useEffect(() => {
    const fetchBids = async () => {
      try {
        const token = localStorage.getItem('token'); // Retrieve token for authorization
        const response = await axios.get('https://www.blueblocksol.com:3000/api/bids', {
          headers: { Authorization: `Bearer ${token}` },
        });
        setBids(response.data); // Store the fetched bids in the state
      } catch (error) {
        console.error('Error fetching bids:', error.message);
        Swal.fire({
          title: 'Error!',
          text: 'Failed to fetch bids from the server. Please try again.',
          icon: 'error',
          timer: 2000,
          showConfirmButton: false,
        });
      } finally {
        setLoading(false);
      }
    };

    fetchBids();
  }, [user]); // Re-fetch when user changes

  // DataTable columns configuration
  const columns = [
    {
      name: 'Bid ID',
      selector: (row) => row.bid_id,
      sortable: true,
    },
    {
      name: 'NFT Name',
      selector: (row) => row.nft_name,
      sortable: true,
    },
    {
      name: 'NFT Image',
      selector: (row) => (
        <a href={`https://www.blueblocksol.com:3000${row.nft_image}`} target="_blank" rel="noopener noreferrer">
          <img
            src={`https://www.blueblocksol.com:3000${row.nft_image}`}
            alt={row.nft_name}
            style={{ width: '50px', height: '50px' }}
          />
        </a>
      ),
      sortable: false,
    },
    {
      name: 'Bid Amount',
      selector: (row) => `$${parseFloat(row.bid_amount).toFixed(2) || '0.00'}`,
      sortable: true,
    },
    {
      name: 'Bid Time',
      selector: (row) => new Date(row.bid_time).toLocaleDateString(), // Changed to display only date
      sortable: true,
    },
  ];
  

  return (
    <div className="table--responsive--md mt-2">
      <DataTable
        columns={columns}
        data={bids}
        customStyles={{
          header: {
            style: {
              backgroundColor: '#000', // Black background for header
              color: 'white', // White text color for header
            },
          },
          rows: {
            style: {
              backgroundColor: '#fff', // White background for rows
              color: '#000', // Black text color for rows
              '&:not(:last-of-type)': {
                borderBottomStyle: 'solid',
                borderBottomWidth: '1px',
                borderBottomColor: '#ddd', // Light gray for row borders
              },
            },
            highlightOnHoverStyle: {
              backgroundColor: '#f2f2f2', // Light gray background on hover
              color: '#000', // Black text on hover
            },
            hoverStyle: {
              backgroundColor: '#f2f2f2', // Light gray background on hover
              color: '#000', // Black text on hover
            },
          },
          headCells: {
            style: {
              backgroundColor: '#000', // Black background for header cells
              color: 'white', // White text color for header cells
              fontWeight: 'bold',
            },
          },
          cells: {
            style: {
              color: '#000', // Black text color for cells
            },
          },
        }}
        pagination
        progressPending={loading}
        noHeader
      />
    </div>
  );
};

export default BidList;

import React from "react";

const Traits = ({ traitsData }) => {
  // Dummy data as fallback
  const defaultTraits = [
    { key: "Apparel", value: "Bathrobe Red", percentage: "1%", floor: "0,056 ETH" },
    { key: "Background", value: "Orange", percentage: "5%", floor: "0,056 ETH" },
    { key: "Earrings", value: "None", percentage: "60%", floor: "0,037 ETH" },
    { key: "Apparel", value: "Bathrobe Red", percentage: "1%", floor: "0,056 ETH" },
    { key: "Background", value: "Orange", percentage: "5%", floor: "0,056 ETH" },
    { key: "Earrings", value: "None", percentage: "60%", floor: "0,037 ETH" },
    { key: "Apparel", value: "Bathrobe Red", percentage: "1%", floor: "0,056 ETH" },
    { key: "Background", value: "Orange", percentage: "5%", floor: "0,056 ETH" },
    { key: "Earrings", value: "None", percentage: "60%", floor: "0,037 ETH" }
  ];

  // Use passed traitsData if available, otherwise use defaultTraits
  const traits = traitsData ? JSON.parse(traitsData) : defaultTraits;

  return (
    <div data-wow-delay="0s" className="wow fadeInRight product-item traits">
      <h6>
        <i className="icon-description"></i> Traits
      </h6>
      <i className="icon-keyboard_arrow_down"></i>
      <div className="content">
        {traits.map((trait, index) => (
          <div key={index} className="trait-item">
            <p>{trait.key}</p>
            <div className="title">
              {trait.value} {trait.percentage}
            </div>
            <p>Floor: {trait.floor || "N/A"}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Traits;

import React, { useState, useEffect } from "react";
import { useUser } from "../../../context/UserContext";
import { useNavigate, Link, useLocation } from "react-router-dom";
import MobileSidebar from "./Home/MobileSidebar";
import DesktopSidebar from "./Home/DesktopSidebar";
import ConnectWalletButton from "../ConnectWalletButton";
import WalletModal from "../sidebar/WalletModal"; // Import the WalletModal component

const Home_header = () => {
  const [isMobileSidebarOpen, setIsMobileSidebarOpen] = useState(false);
  const [isDesktopSidebarOpen, setIsDesktopSidebarOpen] = useState(false);
  const [isActiveTab, setIsActiveTab] = useState("NFT"); // State to manage active tab (NFT or Gaming)
  const [isModalOpen, setIsModalOpen] = useState(false); // State for controlling modal visibility
  const location = useLocation(); // Hook to get the current location
  const { setUser } = useUser();
  const navigate = useNavigate();

  // Effect to check URL hash and set active tab
  useEffect(() => {
    const hash = location.hash.substring(1); // Get the hash from the URL without the #
    if (hash === "gaming") {
      setIsActiveTab("Gaming"); // Set Gaming as the active tab
    } else {
      setIsActiveTab("NFT"); // Default to NFT as the active tab
    }
  }, [location.hash]); // Runs whenever the URL hash changes

  const toggleMobileSidebar = () => {
    setIsMobileSidebarOpen(!isMobileSidebarOpen);
  };

  const toggleDesktopSidebar = () => {
    setIsDesktopSidebarOpen(!isDesktopSidebarOpen);
  };

  // Function to open the modal
  const openModal = () => {
    setIsModalOpen(true);
  };

  // Function to close the modal
  const closeModal = () => {
    setIsModalOpen(false);
  };

  return (
    <>
      <header id="header_main" className="header_1 header-fixed">
        <div className="themesflat-container">
          <div className="row">
            <div className="col-md-12">
              <div id="site-header-inner">
                <div className="wrap-box flex">
                  <div id="site-logo">
                    <div id="site-logo-inner">
                      <Link to="/user/Home" className="main-logo">
                        <img
                          id="logo_header"
                          src={`${process.env.PUBLIC_URL}/CRYPTOMART.png`}
                          alt="logo"
                          style={{ width: 150 }}
                        />
                      </Link>
                    </div>
                  </div>

                  {/* Navigation Links */}
                  <nav id="main-nav" className="main-nav">
                    <ul id="menu-primary-menu" className="menu">
                      {/* Conditionally Render Home Menu */}
                      {location.pathname !== "/user/Home" && (
                        <li className="menu-item">
                          <Link to="/user/Home">Home</Link>
                        </li>
                      )}

                      {/* Explore Menu with Dropdown */}
                      <li className="menu-item">
                        <Link to="/user/ExploreNFT">Explore</Link>
                      </li>

                      {/* Dashboard Menu */}
                      <li className="menu-item">
                        <Link to="/user/userDashboard">Dashboard</Link>
                      </li>
                    </ul>
                  </nav>

                  {/* Wallet and Search Section */}
                  <div className="flat-wallet flex">
                    {/* NFT and Gaming Tabs */}
                    <Link className="desktop-only" to="/user/Home">
                      <li
                        className={`item-title ${
                          isActiveTab === "NFT" ? "color_button" : "color_button_wbg"
                        }`}
                      >
                        <span>NFT</span>
                      </li>
                    </Link>
                    <Link className="desktop-only" to="/user/Home#gaming">
                      <li
                        className={`item-title ${
                          isActiveTab === "Gaming" ? "color_button" : "color_button_wbg"
                        }`}
                      >
                        <span>Gaming</span>
                      </li>
                    </Link>

                    {/* Connect Wallet Button */}
                    <ConnectWalletButton openModal={openModal} />

                    <button
                      className="mobile-sidebar-toggle"
                      onClick={toggleMobileSidebar}
                    >
                      <i className="ti ti-menu-2"></i>
                    </button>
                    <div className="header-search relative">
                      <a href="#" className="show-search">
                        <i className="icon-search"></i>
                      </a>
                      <div className="top-search">
                        <form
                          action="#"
                          method="get"
                          role="search"
                          className="search-form relative"
                        >
                          <input
                            type="search"
                            id="search"
                            className="search-field style-1"
                            placeholder="Search..."
                            name="s"
                            required
                          />
                          <button
                            className="search search-submit"
                            type="submit"
                          >
                            <i className="icon-search"></i>
                          </button>
                        </form>
                      </div>
                    </div>
                    <div className="canvas" onClick={toggleDesktopSidebar}>
                      <span></span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Mobile Sidebar Component */}
        <MobileSidebar
          isOpen={isMobileSidebarOpen}
          toggleSidebar={toggleMobileSidebar}
        />

        {/* Desktop Sidebar Component */}
        <DesktopSidebar
          isOpen={isDesktopSidebarOpen}
          toggleSidebar={toggleDesktopSidebar}
        />
      </header>

      {/* Wallet Modal */}
      <WalletModal isOpen={isModalOpen} onClose={closeModal} />
    </>
  );
};

export default Home_header;

import React, { useState, useEffect } from 'react';

const Util = {
  setAttributes: (el, attrs) => {
    for (let key in attrs) {
      el.setAttribute(key, attrs[key]);
    }
  },
};

const Countdown = ({ labels = [], timer, countdownDate, visibleLabels = [] }) => {
  const [timeLeft, setTimeLeft] = useState({
    days: 0,
    hours: 0,
    mins: 0,
    secs: 0,
  });

  const getTimeRemaining = (endTime) => {
    const total = endTime - new Date().getTime();
    const seconds = Math.floor((total / 1000) % 60);
    const minutes = Math.floor((total / 1000 / 60) % 60);
    const hours = Math.floor((total / (1000 * 60 * 60)) % 24);
    const days = Math.floor(total / (1000 * 60 * 60 * 24));

    return { total, days, hours, mins: minutes, secs: seconds };
  };

  const getEndTime = () => {
    if (timer) {
      return new Date().getTime() + timer * 1000;
    } else if (countdownDate) {
      return new Date(countdownDate).getTime();
    }
    return null;
  };

  useEffect(() => {
    const endTime = getEndTime();
    if (!endTime) return;

    const interval = setInterval(() => {
      const timeRemaining = getTimeRemaining(endTime);

      if (timeRemaining.total <= 0) {
        clearInterval(interval);
        // Dispatch custom event if needed here
        // element.dispatchEvent(new CustomEvent('countDownFinished'));
      } else {
        setTimeLeft({
          days: timeRemaining.days,
          hours: timeRemaining.hours,
          mins: timeRemaining.mins,
          secs: timeRemaining.secs,
        });
      }
    }, 1000);

    return () => clearInterval(interval); // Cleanup the interval
  }, [timer, countdownDate]);

  const getTimeFormat = (time) => ('0' + time).slice(-2);

  return (
    <div className="countdown__timer">
      {/* Days */}
      {visibleLabels.includes('d') || timeLeft.days > 0 ? (
        <span className="countdown__item">
          <span className="countdown__value countdown__value--0">{timeLeft.days}</span>
          {labels[0] && <span className="countdown__label">{labels[0]}</span>}
        </span>
      ) : null}

      {/* Hours */}
      {visibleLabels.includes('h') || timeLeft.hours > 0 ? (
        <span className="countdown__item">
          <span className="countdown__value countdown__value--1">{getTimeFormat(timeLeft.hours)}</span>
          {labels[1] && <span className="countdown__label">{labels[1]}</span>}
        </span>
      ) : null}

      {/* Minutes */}
      {visibleLabels.includes('m') || timeLeft.mins > 0 ? (
        <span className="countdown__item">
          <span className="countdown__value countdown__value--2">{getTimeFormat(timeLeft.mins)}</span>
          {labels[2] && <span className="countdown__label">{labels[2]}</span>}
        </span>
      ) : null}

      {/* Seconds */}
      <span className="countdown__item">
        <span className="countdown__value countdown__value--3">{getTimeFormat(timeLeft.secs)}</span>
        {labels[3] && <span className="countdown__label">{labels[3]}</span>}
      </span>
    </div>
  );
};

export default Countdown;


// How to Use It in Other Pages


// import React from 'react';
// import Countdown from './Countdown'; // Adjust the path as needed

// const SomePage = () => {
//   return (
//     <div>
//       <h1>Countdown Example</h1>
//       <Countdown
//         timer={3600} // 1 hour in seconds
//         labels={['Days', 'Hours', 'Minutes', 'Seconds']}
//         visibleLabels={['d', 'h', 'm']} // Only show days, hours, and minutes
//       />
//     </div>
//   );
// };

// export default SomePage;

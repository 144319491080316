import { useEffect, useMemo, useState } from "react";
import Particles, { initParticlesEngine } from "@tsparticles/react";
import { loadSlim } from "@tsparticles/slim"; // Only load necessary features

const ParticleComponent = () => {
  const [isInitialized, setIsInitialized] = useState(false);

  useEffect(() => {
    const initParticles = async () => {
      await initParticlesEngine(async (engine) => {
        // Load only the necessary bundle (slim version)
        await loadSlim(engine);
      });
      setIsInitialized(true); // Set to true once initialization is complete
    };

    initParticles();
  }, []);

  const particlesLoaded = (container) => {
    console.log("Particles loaded:", container);
  };

  const particlesOptions = useMemo(() => ({

    fpsLimit: 120, // Frame rate limit
    interactivity: {
      events: {
        onClick: {
          enable: true,
          mode: "push", // Add particles on click
        },
        onHover: {
          enable: true,
          mode: "repulse", // Repel particles on hover
        },
      },
      modes: {
        push: {
          quantity: 4, // Number of particles added on click
        },
        repulse: {
          distance: 200,
          duration: 0.4, // Hover repulsion effect
        },
      },
    },
    particles: {
      color: {
        value: "#f1ff00", // Particle color yellowish
      },
      links: {
        color: "#f1ff00", // Line linking color yellowish
        distance: 150,
        enable: true,
        opacity: 0.5,
        width: 1,
      },
      move: {
        enable: true,
        speed: 6,
        outModes: {
          default: "bounce",
        },
      },
      number: {
        density: {
          enable: true,
        },
        value: 80, // Number of particles
      },
      opacity: {
        value: 0.5,
      },
      shape: {
        type: "circle", // Particle shape
      },
      size: {
        value: { min: 1, max: 5 }, // Particle size range
      },
    },
    detectRetina: true, // Retina display detection
  }), []);

  if (!isInitialized) {
    return <div>Loading particles...</div>; // Show loading text while initializing
  }

  return (
    <div className="particle-container" style={{ width: '100%', height: '100%' }}>
      <Particles
        id="tsparticles"
        particlesLoaded={particlesLoaded}
        options={particlesOptions}
        className="custom-particles"
      />
    </div>
  );
};

export default ParticleComponent;

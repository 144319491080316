import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, EffectCoverflow } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/effect-coverflow';
import Countdown from '../../user_utils/Countdown';
import { Link } from 'react-router-dom';

const TopWorks = () => {
    const slides = [
      {
        imgSrc: 'assets/images/box-item/banner-02.jpg',
        title: 'Dayco serpentine belt',
        price: '0.34',
      },
      {
        imgSrc: 'assets/images/box-item/banner-03.jpg',
        title: 'Dayco serpentine belt',
        price: '0.34',
      },  
      {
          imgSrc: 'assets/images/box-item/banner-01.jpg',
          title: 'Dayco serpentine belt',
          price: '0.34',
        },
        {
          imgSrc: 'assets/images/box-item/banner-04.jpg',
          title: 'Dayco serpentine belt',
          price: '0.34',
        },
        {
          imgSrc: 'assets/images/box-item/banner-05.jpg',
          title: 'Dayco serpentine belt',
          price: '0.34',
        },
        {
          imgSrc: 'assets/images/box-item/banner-06.jpg',
          title: 'Dayco serpentine belt',
          price: '0.34',
        },
        {
          imgSrc: 'assets/images/box-item/banner-07.jpg',
          title: 'Dayco serpentine belt',
          price: '0.34',
        },
      ];

  return (
    
    <div className="flat-pages-title" style={{ paddingBottom: '0px' }}>
  <div className="widget-bg-line">
    <div className="wraper">
      <div className="bg-grid-line y bottom">
        <div className="bg-line"></div>
      </div>
    </div>
  </div>

  <div className="themesflat-container w1490">
    <div className="row">
      <div className="col-12 pages-title">
        <div className="content">
          <h1 className="wow fadeInUp">World of top works</h1>
          <p className="wow fadeInUp">
            Welcome to the world of rare digital art. Explore the best art from hand-picked digital artists and find the hidden gem.
          </p>
          <div className="flat-button flex justify-center">
            <Link to="/user/Home#gaming" className="tf-button style-1 h50 w190 mr-16">
              Gaiming <i className="icon-arrow-up-right2"></i>
            </Link>
            <Link to="/user/ExploreNFT" className="tf-button style-1 h50 w190 active">
              All collections <i className="icon-arrow-up-right2"></i>
            </Link>
          </div>
        </div>

        <div className="icon-background">
          <img className="absolute item1" src="assets/images/item-background/item1.png" alt="" />
          <img className="absolute item2" src="assets/images/item-background/item2.png" alt="" />
          <img className="absolute item3" src="assets/images/item-background/item3.png" alt="" />
          <img className="absolute item4" src="assets/images/item-background/item1.png" alt="" />
          <img className="absolute item5" src="assets/images/item-background/item1.png" alt="" />
          <img className="absolute item6" src="assets/images/item-background/item4.png" alt="" />
          <img className="absolute item7" src="assets/images/item-background/item5.png" alt="" />
          <img className="absolute item8" src="assets/images/item-background/item5.png" alt="" />
        </div>

        {/* Swiper */}
        <div className="relative">
          <Swiper
            loop={true}
            spaceBetween={30}
            slidesPerView={1}
            centeredSlides={true}
            freeMode={true}
            watchSlidesProgress={true}
            effect="coverflow"
            grabCursor={true}
            modules={[Navigation, Pagination, EffectCoverflow]}
            coverflowEffect={{
              rotate: 15,
              stretch: 90,
              depth: 0,
              modifier: 1,
              scale: 0.9,
              slideShadows: false,
            }}
            navigation={{
              nextEl: '.next-3d',
              prevEl: '.prev-3d',
            }}
            pagination={{
              el: '.swiper-pagination',
              clickable: true,
            }}
            breakpoints={{
              500: {
                slidesPerView: 2,
              },
              991: {
                slidesPerView: 3,
              },
              1024: {
                slidesPerView: 3,
              },
              1200: {
                slidesPerView: 4,
              },
              1400: {
                slidesPerView: 5,
              },
            }}
            className="swiper-3d-7"
          >
            {slides.map((slide, index) => (
              <SwiperSlide key={index}>
                <div className="tf-card-box">
                  <div className="card-media">
                    <a href="#">
                      <img src={slide.imgSrc} alt={slide.title} />
                    </a>
                    <span className="wishlist-button icon-heart"></span>
                    <div className="featured-countdown">
                      <Countdown
                        timer={3600} // 1 hour in seconds
                        labels={['h', 'm', 's']}
                        visibleLabels={['d', 'h', 'm']} // Only show days, hours, and minutes
                      />
                    </div>
                    <div className="button-place-bid">
                      <Link to="/user/Content" className="tf-button">
                        <span>Place Bid</span>
                      </Link>
                    </div>
                  </div>
                  <div className="meta-info text-center">
                    <h5 className="name">
                      <Link to="/user/Content">{slide.title}</Link>
                    </h5>
                    <h6 className="price gem">
                      <i className="icon-gem"></i>
                      {slide.price}
                    </h6>
                  </div>
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
          <div className="swiper-pagination pagination-number"></div>
          <div className="swiper-button-next next-3d over"></div>
          <div className="swiper-button-prev prev-3d over"></div>
        </div>
      </div>
    </div>
  </div>
</div>

  );
};

export default TopWorks;

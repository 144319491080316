import React, { useEffect, useState } from 'react';
import DataTable from 'react-data-table-component';
import axios from 'axios'; // Make sure to have axios installed
import { useUser } from '../../../context/UserContext'; // Assuming you have a UserContext to get the user info

const GameHistory = () => {
  const [gameActivities, setGameActivities] = useState([]);
  const [loading, setLoading] = useState(true);
  const { user } = useUser(); // Assuming the user object has the userId

  useEffect(() => {
    const fetchGameActivities = async () => {
      try {
        // if (!user || !user.id) {
        //   console.error('User ID is missing');
        //   setLoading(false);
        //   return;
        // }

        // Make the API call to fetch user game activities
        const token = localStorage.getItem('token'); // Assuming the token is stored in localStorage
        const response = await axios.get(`https://www.blueblocksol.com:3000/api/game/game-activities/${user.id}`, {
          headers: { Authorization: `Bearer ${token}` },
        });
        setGameActivities(response.data);
      } catch (error) {
        console.error('Error fetching game activities:', error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchGameActivities();
  }, [user]); // Fetch data when the component mounts or when the user object changes

  const columns = [
    {
      name: 'Game ID',
      selector: (row) => row.game_id,
      sortable: true,
    },
    {
      name: 'Game Name',
      selector: (row) => row.game_name,
      sortable: true,
    },
    {
      name: 'Bet Amount',
      selector: (row) => `$${parseFloat(row.bet_amount).toFixed(2) || '0.00'}`, // Ensure it's a number
      sortable: true,
    },
    {
      name: 'Win Amount',
      selector: (row) => (row.win_amount ? `$${parseFloat(row.win_amount).toFixed(2)}` : 'N/A'), // Ensure it's a number
      sortable: true,
    },
    {
      name: 'Loss Amount',
      selector: (row) => (row.loss_amount ? `$${parseFloat(row.loss_amount).toFixed(2)}` : 'N/A'), // Ensure it's a number
      sortable: true,
    },
    {
      name: 'Result',
      selector: (row) => row.result,
      sortable: true,
    },
    {
      name: 'Play Date',
      selector: (row) => new Date(row.play_date).toLocaleString(),
      sortable: true,
    },
  ];

  return (
    <div className="table--responsive--md mt-2">
      <DataTable
        columns={columns}
        data={gameActivities}
        customStyles={{
          header: {
            style: {
              backgroundColor: '#000', // Black background for header
              color: 'white', // White text color for header
            },
          },
          rows: {
            style: {
              backgroundColor: '#fff', // White background for rows
              color: '#000', // Black text color for rows
              '&:not(:last-of-type)': {
                borderBottomStyle: 'solid',
                borderBottomWidth: '1px',
                borderBottomColor: '#ddd', // Light gray for row borders
              },
            },
            highlightOnHoverStyle: {
              backgroundColor: '#f2f2f2', // Light gray background on hover
              color: '#000', // Black text on hover
            },
            hoverStyle: {
              backgroundColor: '#f2f2f2', // Light gray background on hover
              color: '#000', // Black text on hover
            },
          },
          headCells: {
            style: {
              backgroundColor: '#000', // Black background for header cells
              color: 'white', // White text color for header cells
              fontWeight: 'bold',
            },
          },
          cells: {
            style: {
              color: '#000', // Black text color for cells
            },
          },
        }}
        pagination
        progressPending={loading}
        noHeader
      />
    </div>
  );
};

export default GameHistory;

import React, { useEffect } from 'react';

const CustomCursor = () => {
  useEffect(() => {
    const cursorInner = document.querySelector(".tf-mouse-inner");
    const cursorOuter = document.querySelector(".tf-mouse-outer");

    // Set initial state for mouse coordinates
    let mouseX = 0, mouseY = 0;
    let isHovering = false;

    // Function to update the cursor position
    const onMouseMove = (e) => {
      mouseX = e.clientX;
      mouseY = e.clientY;
      if (!isHovering) {
        cursorOuter.style.transform = `translate(${mouseX}px, ${mouseY}px)`;
      }
      cursorInner.style.transform = `translate(${mouseX}px, ${mouseY}px)`;
    };

    // Function to add hover class
    const onMouseEnter = () => {
      isHovering = true;
      cursorInner.classList.add("mouse-hover");
      cursorOuter.classList.add("mouse-hover");
    };

    // Function to remove hover class
    const onMouseLeave = () => {
      isHovering = false;
      cursorInner.classList.remove("mouse-hover");
      cursorOuter.classList.remove("mouse-hover");
    };

    // Add mousemove listener
    window.addEventListener("mousemove", onMouseMove);

    // Add event listeners for hover effects
    const hoverElements = document.querySelectorAll("a, .canvas, .progress-wrap, .wishlist-button");
    hoverElements.forEach((el) => {
      el.addEventListener("mouseenter", onMouseEnter);
      el.addEventListener("mouseleave", onMouseLeave);
    });

    // Make cursor visible
    cursorInner.style.visibility = "visible";
    cursorOuter.style.visibility = "visible";

    // Cleanup function when component unmounts
    return () => {
      window.removeEventListener("mousemove", onMouseMove);
      hoverElements.forEach((el) => {
        el.removeEventListener("mouseenter", onMouseEnter);
        el.removeEventListener("mouseleave", onMouseLeave);
      });
    };
  }, []); // Empty dependency array ensures this runs once when component mounts

  return (
    <>
      <div className="tf-mouse tf-mouse-outer"></div>
      <div className="tf-mouse tf-mouse-inner"></div>
    </>
  );
};

export default CustomCursor;

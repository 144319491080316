import React, { useState } from 'react';
import CardItem from '../components/cards/CardItem';
import HomeLayout from '../components/Layout/Home_layout';

// Sample data for gaming items
const gameData = [
  {
    image: 'assets/images/games/game-item-1.jpg',
    title: 'Call of Duty: Warzone',
    authorName: 'Activision',
    avatar: 'assets/images/avatar/avatar-box-01.jpg',
    currentBid: 'Free',
    category: 'Shooter',
  },
  {
    image: 'assets/images/games/game-item-2.jpg',
    title: 'League of Legends',
    authorName: 'Riot Games',
    avatar: 'assets/images/avatar/avatar-box-02.jpg',
    currentBid: 'Free',
    category: 'MOBA',
  },
  {
    image: 'assets/images/games/game-item-3.jpg',
    title: 'Minecraft',
    authorName: 'Mojang',
    avatar: 'assets/images/avatar/avatar-box-03.jpg',
    currentBid: '$26.95',
    category: 'Sandbox',
  },
  {
    image: 'assets/images/games/game-item-4.jpg',
    title: 'Cyberpunk 2077',
    authorName: 'CD Projekt Red',
    avatar: 'assets/images/avatar/avatar-box-04.jpg',
    currentBid: '$59.99',
    category: 'RPG',
  },
  {
    image: 'assets/images/games/game-item-5.jpg',
    title: 'Fortnite',
    authorName: 'Epic Games',
    avatar: 'assets/images/avatar/avatar-box-05.jpg',
    currentBid: 'Free',
    category: 'Battle Royale',
  },
  // More game data here...
];

const ExploreGaming = () => {
  const [visibleGames, setVisibleGames] = useState(12); // Initially show 12 games
  const [selectedCategory, setSelectedCategory] = useState('All');
  const [isDropdownOpen, setIsDropdownOpen] = useState(false); // Dropdown state

  // Filter games by selected category
  const filteredGames = gameData.filter((game) =>
    selectedCategory === 'All' ? true : game.category === selectedCategory
  );

  // Handle category change and close dropdown
  const handleCategoryChange = (category) => {
    setSelectedCategory(category);
    setVisibleGames(12); // Reset visible games when category changes
    setIsDropdownOpen(false); // Close the dropdown
  };

  // Load more games when "Load More" is clicked
  const loadMoreGames = () => {
    setVisibleGames((prevVisibleGames) => prevVisibleGames + 12);
  };

  return (
    <HomeLayout>
      <div className="gaming-page">
        {/* Title Section */}
        <div className="flat-title-page">
          <div className="themesflat-container">
            <div className="row">
              <div className="col-12">
                <h1 className="heading text-center">Explore Games</h1>
                <ul className="breadcrumbs flex justify-center">
                  <li className='icon-keyboard_arrow_right'>
                    <a href="/user/Home">Home</a>
                  </li>
                  <li>
                    <a href="#">Games</a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>

        {/* Filter Section */}
        <div className="tf-section-2 discover-item">
          <div className="themesflat-container">
            <div className="row">
              <div className="col-md-12 pb-30">
                <div className="tf-soft flex items-center justify-between">
                  <div className="soft-left">
                    {/* Category Filter */}
                    <div className={`dropdown ${isDropdownOpen ? 'show' : ''}`}>
                      <button
                        className="btn btn-secondary dropdown-toggle"
                        onClick={() => setIsDropdownOpen(!isDropdownOpen)}
                      >
                        <span className="inner">Category</span>
                      </button>
                      <div className={`dropdown-menu ${isDropdownOpen ? 'show' : ''}`}>
                        <a className="dropdown-item" onClick={() => handleCategoryChange('All')}>
                          All
                        </a>
                        <a className="dropdown-item" onClick={() => handleCategoryChange('Shooter')}>
                          Shooter
                        </a>
                        <a className="dropdown-item" onClick={() => handleCategoryChange('MOBA')}>
                          MOBA
                        </a>
                        <a className="dropdown-item" onClick={() => handleCategoryChange('Sandbox')}>
                          Sandbox
                        </a>
                        <a className="dropdown-item" onClick={() => handleCategoryChange('RPG')}>
                          RPG
                        </a>
                        <a className="dropdown-item" onClick={() => handleCategoryChange('Battle Royale')}>
                          Battle Royale
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/* Cards Section */}
              <div className="row">
                {filteredGames.slice(0, visibleGames).map((game, index) => (
                  <div key={index} className="col-xl-3 col-lg-4 col-md-6 col-sm-6">
                    <CardItem
                      image={game.image}
                      title={game.title}
                      authorName={game.authorName}
                      avatar={game.avatar}
                      currentBid={game.currentBid}
                    />
                  </div>
                ))}
              </div>

              {/* Load More Button */}
              {visibleGames < filteredGames.length && (
                <div className="col-md-12 load-more">
                  <button onClick={loadMoreGames} className="tf-button-loadmore">
                    Load More
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </HomeLayout>
  );
};

export default ExploreGaming;

import React, { useState } from 'react';
import axios from 'axios';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import Swal from 'sweetalert2';

const RegisterPage = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const searchParams = new URLSearchParams(location.search);
  const referralCodeFromURL = searchParams.get('referral') || 'MetagmSr';

  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    phoneNumber: '',
    email: '',
    password: '',
    confirmPassword: '',
    dob: '',
    referralCode: referralCodeFromURL,
    otp: '',
    walletAddress: '',
  });

  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const [otpSent, setOtpSent] = useState(false);
  const [otpVerified, setOtpVerified] = useState(false);
  const [showVerifyButton, setShowVerifyButton] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const togglePasswordVisibility = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };

  const toggleConfirmPasswordVisibility = () => {
    setShowConfirmPassword((prevShowConfirmPassword) => !prevShowConfirmPassword);
  };

  const calculateAge = (dob) => {
    const birthDate = new Date(dob);
    const today = new Date();
    let age = today.getFullYear() - birthDate.getFullYear();
    const m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
      age--;
    }
    return age;
  };

  const handleEmailBlur = () => {
    setShowVerifyButton(true);
  };

  const handleSendOtp = async () => {
    if (formData.email) {
      try {
        await axios.post('https://www.blueblocksol.com:3000/api/send-otp', { email: formData.email });
        Swal.fire('Success', 'OTP sent to your email. Please check your inbox.', 'success');
        setOtpSent(true);
      } catch (err) {
        Swal.fire('Error', 'Failed to send OTP. Please try again.', 'error');
      }
    }
  };

  const handleOtpVerification = async () => {
    if (formData.otp) {
      try {
        const response = await axios.post('https://www.blueblocksol.com:3000/api/verify-otp', {
          email: formData.email,
          otp: formData.otp,
        },);
        if (response.data.success) {
          Swal.fire('Success', 'OTP verified successfully.', 'success').then(() => {
            setOtpVerified(true);
          });
        } else {
          Swal.fire('Error', 'Invalid OTP. Please try again.', 'error');
        }
      } catch (error) {
        Swal.fire('Error', 'Failed to verify OTP.', 'error');
      }
    } else {
      Swal.fire('Error', 'Please enter the OTP.', 'error');
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError('');

    if (!otpVerified) {
      setError('Please verify your email first.');
      setLoading(false);
      return;
    }

    if (calculateAge(formData.dob) < 18) {
      setError('You must be at least 18 years old.');
      setLoading(false);
      return;
    }

    if (formData.password !== formData.confirmPassword) {
      setError('Passwords do not match.');
      setLoading(false);
      return;
    }

    try {
      const response = await axios.post('https://www.blueblocksol.com:3000/api/auth/register', formData);
console.log(response.data.token);

      const tokenResponse = await axios.post(
        'https://www.blueblocksol.com:3000/api/crypto/request-tokens', 
        {
          walletAddress: formData.walletAddress,
          tokenAmount: 10000,
          email: formData.email,
          // token: response.token
        },
        {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${response.data.token}`,
          }
        }
      );
      

      Swal.fire({
        title: 'Registration Successful!',
        text: `You have been registered successfully. 10,000 MTG has been transferred to your wallet. Transaction Hash: ${tokenResponse.data.transactionHash}`,
        icon: 'success',
        timer: 4000,
        showConfirmButton: false,
      });

      // localStorage.setItem('token', response.data.token);
      navigate('/user/login');
    } catch (err) {
      setError(err.response?.data?.message || 'Registration or token transfer failed');
    } finally {
      setLoading(false);
    }
  };

  // Custom style to be applied to all input fields
  const inputStyle = { color: 'white', backgroundColor: '#333' };

  return (
    <div
      className="tf-section-2 pt-60 widget-box-icon"
      style={{
        backgroundImage: `url(${process.env.PUBLIC_URL}/user/assets/images/bg.jpg)`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        minHeight: '100vh',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <div
        className="themesflat-container w920"
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          width: '100%',
          maxWidth: '600px',
          padding: '20px',
          borderRadius: '8px',
          boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
        }}
      >
        <div className="heading-section-1" style={{ textAlign: 'center' }}>
          <h2 className="tf-title mb-2">Create your account</h2>
          <p className="mb-2">Let’s get started with some details</p>
        </div>

        <div className="widget-login" style={{ width: '100%', backgroundColor: 'rgba(27, 27, 27, 0.8)' }}>
          <form onSubmit={handleSubmit} id="commentform" className="comment-form">
            <fieldset className="name" style={{ marginBottom: '10px' }}>
              <label>First Name *</label>
              <input
                type="text"
                id="firstName"
                placeholder="Your first name*"
                name="firstName"
                value={formData.firstName}
                onChange={handleChange}
                aria-required="true"
                required
                style={inputStyle} // Apply custom style
              />
            </fieldset>
            <fieldset className="name" style={{ marginBottom: '10px' }}>
              <label>Last Name *</label>
              <input
                type="text"
                id="lastName"
                placeholder="Your last name*"
                name="lastName"
                value={formData.lastName}
                onChange={handleChange}
                aria-required="true"
                required
                style={inputStyle} // Apply custom style
              />
            </fieldset>
            <fieldset className="email" style={{ marginBottom: '10px' }}>
              <label>Email *</label>
              <input
                type="email"
                id="email"
                placeholder="mail@website.com"
                name="email"
                value={formData.email}
                onChange={handleChange}
                onBlur={handleEmailBlur}
                aria-required="true"
                required
                style={inputStyle} // Apply custom style
              />
            </fieldset>

            {/* Move hidden elements here, immediately after email */}
            {showVerifyButton && !otpSent && !otpVerified && (
              <button type="button" onClick={handleSendOtp}>
                Verify Email
              </button>
            )}
            {otpSent && !otpVerified && (
              <>
                <fieldset className="otp" style={{ marginBottom: '10px' }}>
                  <label>OTP *</label>
                  <input
                    type="text"
                    id="otp"
                    placeholder="Enter OTP"
                    name="otp"
                    value={formData.otp}
                    onChange={handleChange}
                    aria-required="true"
                    required
                    style={inputStyle} // Apply custom style
                  />
                </fieldset>
                <button type="button" onClick={handleOtpVerification}>
                  Verify OTP
                </button>
              </>
            )}

            <fieldset className="phone" style={{ marginBottom: '10px' }}>
              <label>Phone Number *</label>
              <input
                type="text"
                id="phoneNumber"
                placeholder="Your phone number*"
                name="phoneNumber"
                value={formData.phoneNumber}
                onChange={handleChange}
                aria-required="true"
                required
                style={inputStyle} // Apply custom style
              />
            </fieldset>

            <fieldset className="dob" style={{ marginBottom: '10px' }}>
              <label>Date of Birth *</label>
              <input
                type="date"
                id="dob"
                name="dob"
                value={formData.dob}
                onChange={handleChange}
                aria-required="true"
                required
                style={inputStyle} // Apply custom style
              />
            </fieldset>
            <fieldset className="wallet-address" style={{ marginBottom: '10px' }}>
              <label>Wallet Address *</label>
              <input
                type="text"
                id="walletAddress"
                placeholder="Your wallet address*"
                name="walletAddress"
                value={formData.walletAddress}
                onChange={handleChange}
                aria-required="true"
                required
                style={inputStyle} // Apply custom style
              />
            </fieldset>
            <fieldset className="password" style={{ marginBottom: '10px', position: 'relative' }}>
              <label>Password *</label>
              <input
                className="password-input"
                type={showPassword ? 'text' : 'password'}
                id="password"
                placeholder="Min. 8 characters"
                name="password"
                value={formData.password}
                onChange={handleChange}
                aria-required="true"
                required
                style={inputStyle} // Apply custom style
              />
              <i
                className={`icon-show password-addon ${showPassword ? 'icon-eye-open' : 'icon-eye-closed'}`}
                id="password-addon"
                onClick={togglePasswordVisibility}
                style={{ position: 'Flex', right: '10px', top: '35px', cursor: 'pointer', color: 'white' }} // Icon color
              ></i>
            </fieldset>
            <fieldset className="password" style={{ marginBottom: '10px', position: 'relative' }}>
              <label>Confirm Password *</label>
              <input
                className="password-input"
                type={showConfirmPassword ? 'text' : 'password'}
                id="confirmPassword"
                placeholder="Confirm password"
                name="confirmPassword"
                value={formData.confirmPassword}
                onChange={handleChange}
                aria-required="true"
                required
                style={inputStyle} // Apply custom style
              />
              <i
                className={`icon-show password-addon ${showConfirmPassword ? 'icon-eye-open' : 'icon-eye-closed'}`}
                id="confirm-password-addon"
                onClick={toggleConfirmPasswordVisibility}
                style={{ position: 'Flex', right: '10px', top: '35px', cursor: 'pointer', color: 'white' }} // Icon color
              ></i>
            </fieldset>
            <div className="widget-category-checkbox">
              <label>
                I agree to all Terms, Privacy Policy, and fees
                <input
                  type="checkbox"
                  name="agreeTerms"
                  checked={formData.agreeTerms}
                  onChange={handleChange}
                />
                <span className="btn-checkbox"></span>
              </label>
            </div>

            {error && <p style={{ color: 'red' }}>{error}</p>}

            <div className="btn-submit mb-20 mt-4">
              <button className="tf-button style-1 h50 w-100" type="submit" disabled={loading}>
                {loading ? 'Registering...' : 'Create Account'}
                <i className="icon-arrow-up-right2"></i>
              </button>
            </div>
          </form>
          <div className="no-account">
            Already have an account?{' '}
            <Link to="/user/login" className="tf-color">
              Log in
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RegisterPage;
